<!-- ReportCreatedBy.vue -->

<template>
  <div class="border rounded mb-4">
    <div class="p-2 bg-light fw-semibold small rounded-top rounded-right">
      Opps by Team Member
    </div>

    <!-- View Picker -->
    <!-- <div class="dropdown me-2">
      <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="monthDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Select Month
      </button>
      <div class="dropdown-menu shadow border-0 dropdown-menu-scroll" aria-labelledby="monthDropdownMenuButton">
        <h6 class="dropdown-header">Select a View</h6>
        <a
          class="dropdown-item small"
          href="#"
          v-for="month in allMonths"
          :key="month.value"
          @click.prevent="selectMonth(month.value)"
        >
          <span>{{ month.label }}</span>
        </a>
      </div>
    </div> -->

    <div class="p-2">
      <div class="table-responsive">
        <VTable :data="tableRows" class="table table-sm mb-0">
          <template #head>
            <tr class="small">
              <VTh sortKey="name">Name</VTh>
              <VTh sortKey="total" defaultSort="desc" class="text-end">Total</VTh>
              <VTh sortKey="ownCount" class="text-end">Own Opps</VTh>
              <VTh sortKey="ownTotal" class="text-end">Total</VTh>
              <VTh sortKey="forwardedCount" class="text-end">Forwarded Opps</VTh>
              <VTh sortKey="forwardedTotal" class="text-end">Total</VTh>
              <VTh sortKey="forwardedPercentage" class="text-end">Forwarded / Own</VTh>
            </tr>
          </template>

          <template #body="{ rows }">
            <tr v-for="row in rows" :key="row.name" class="small custom-row">
              <td>{{ row.name }}</td>
              <td class="text-end">€{{ mixinFormatNumber(row.total) }}</td>
              <td class="text-end">{{ row.ownCount }}</td>
              <td class="text-end">€{{ mixinFormatNumber(row.ownTotal) }}</td>
              <td class="text-end">{{ row.forwardedCount }}</td>
              <td class="text-end">€{{ mixinFormatNumber(row.forwardedTotal) }}</td>
              <td class="text-end">{{ row.forwardedPercentage }}%</td>
            </tr>
          </template>
        </VTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReportCreatedBy',

  emits: [
    'showAlert',
    'showError',
  ],

  props: {
    records: {
      type: Array,
      required: true,
    },
  },

  data() { },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',
      'allMonths',
    ]),

    tableRows() {
      const memberStats = this.records.reduce((acc, record) => {
        const createdBy = record.fields['Created By Lookup'] ? record.fields['Created By Lookup'][0] : 'Unknown Member';
        const recordValue = record.fields['9 - Gross Revenue in €'] || 0;
        const forwardedBy = record.fields['Forwarded By Lookup'] ? record.fields['Forwarded By Lookup'][0] : 'Unassigned';

        if (!acc[createdBy]) {
          acc[createdBy] = { name: createdBy, total: 0, ownCount: 0, ownTotal: 0, forwardedCount: 0, forwardedTotal: 0, forwardedPercentage: 0 };
        }

        acc[createdBy].total += recordValue;

        if (forwardedBy === 'Unassigned') {
          acc[createdBy].ownCount += 1;
          acc[createdBy].ownTotal += recordValue;
        } else {
          acc[createdBy].forwardedCount += 1;
          acc[createdBy].forwardedTotal += recordValue;
        }

        return acc;
      }, {});

      const statsArray = Object.values(memberStats);

      statsArray.forEach(member => {
        const totalOpps = member.ownCount + member.forwardedCount;
        member.forwardedPercentage = totalOpps > 0 ? ((member.forwardedCount / totalOpps) * 100).toFixed(0) : 0;
      });

      statsArray.sort((a, b) => b.total - a.total);

      return statsArray;
    },
  },

  watch: { },

  methods: {},

  created() { },
};
</script>