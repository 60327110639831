<!-- Jobs.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'PM'])">
    <JobsHeader
      :selectedMonth="selectedMonth"
      @showAlert="showAlert"
      @showError="showError"
      @selectMonth="mixinSelectMonth"
    />

    <div v-if="!isFetching('fetchingJobs')">
      <div class="p-3 shadow-sm bg-white rounded">
        <ReusableJobsTable
          :records="records"

          @showAlert="showAlert"
          @showError="showError"
        />
      </div>
    </div>

    <div v-else class="text-center text-muted p-5">
      <div class="spinner-border text-secondary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div v-else>
    <p class="">You are not authorized to see this page.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import JobsHeader from '@/components/JobsHeader.vue';
import ReusableJobsTable from '@/components/ReusableJobsTable.vue';

export default {
  name: 'AppJobs',
  emits: [
    'showAlert',
    'showError',
  ],
  components: {
    JobsHeader,
    ReusableJobsTable,
  },

  data() {
    return {
      selectedMonth: {
        label: '',
        value: '',
      },

      records: [],

      tableName: 'Jobs',
      stateVariable: 'jobs',
      fetchingStateVariable: 'fetchingJobs',
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  watch: {},

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    getFilterString(currentMonth, currentYear) {
      const monthIndex = new Date(Date.parse(currentMonth +" 1, 2012")).getMonth() + 1;
      const firstDayOfMonth = `01-${currentMonth}-${currentYear}`;
      const lastDayOfMonth = new Date(currentYear, monthIndex, 0).getDate() + `-${currentMonth}-${currentYear}`;

      return `AND(
        OR(
          IS_AFTER({Opp Created On}, DATETIME_PARSE('${firstDayOfMonth}', 'DD-MMM-YYYY')),
          IS_SAME({Opp Created On}, DATETIME_PARSE('${firstDayOfMonth}', 'DD-MMM-YYYY'), 'day')
        ),
        OR(
          IS_BEFORE({Opp Created On}, DATETIME_PARSE('${lastDayOfMonth}', 'DD-MMM-YYYY')),
          IS_SAME({Opp Created On}, DATETIME_PARSE('${lastDayOfMonth}', 'DD-MMM-YYYY'), 'day')
        )
      )`;
    },

    getFilterStringForPeriod(startDate, endDate) {
      const start = `${startDate.getDate()}-${startDate.getMonth()+1}-${startDate.getFullYear()}`;
      const end = `${endDate.getDate()}-${endDate.getMonth()+1}-${endDate.getFullYear()}`;

      return `AND(
        OR(
          IS_AFTER({Opp Created On}, DATETIME_PARSE('${start}', 'DD-MM-YYYY')),
          {Opp Created On} = DATETIME_PARSE('${start}', 'DD-MM-YYYY')
        ),
        OR(
          IS_BEFORE({Opp Created On}, DATETIME_PARSE('${end}', 'DD-MM-YYYY')),
          {Opp Created On} = DATETIME_PARSE('${end}', 'DD-MM-YYYY')
        )
      )`;
    },

    async fetchRecordsForPeriod(startDate, endDate) {
      const filterString = this.getFilterStringForPeriod(startDate, endDate);

      try {
        const records = await this.$store.dispatch('fetchRecords', {
          tableName: this.tableName,
          filter: filterString,
          stateVariable: this.stateVariable,
          fetchingStateVariable: this.fetchingStateVariable,
        });

        return records;

      } catch (error) {
        console.error(`Error fetching records for period ${startDate} - ${endDate}:`, error);
      }
    },

    async fetchRecords(month, year) {
      const filterString = this.getFilterString(month, year);

      try {
        const records = await this.$store.dispatch('fetchRecords', {
          tableName: this.tableName,
          filter: filterString,
          stateVariable: this.stateVariable,
          fetchingStateVariable: this.fetchingStateVariable,
        });

        return records;

      } catch (error) {
        console.error(`Error fetching records for ${month} - ${year}:`, error);
      }
    },
  },

  async mounted() { },

  async created() {
    this.mixinCheckUserAuthentication();

    document.title = `Jobs`;

    if (!this.selectedMonth.value) {
      const currentDate = new Date();
      currentDate.setDate(1);
      currentDate.setHours(12, 0, 0);
      const currentMonth = currentDate.toLocaleString('en-US', { month: 'short' });
      const currentYear = currentDate.getFullYear();
      const monthName = currentDate.toLocaleString('en-US', { month: 'long' });
      this.selectedMonth = {
        label: `${monthName} (${currentYear})`,
        value: `${currentMonth} ${currentYear}`,
      };
    }

    const [month, year] = this.selectedMonth.value.split(' ');
    this.records = await this.fetchRecords(month, year);

    // if (this.selectedMonth.value === 'This Year' || this.selectedMonth.value === 'Last Year') {
    //   await this.mixinSelectMonth(this.selectedMonth.value);
    // } else {
    //   const [month, year] = this.selectedMonth.value.split(' ');
    //   this.records = await this.fetchRecords(month, year);
    // }

  },
};
</script>
