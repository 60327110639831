<!-- DeleteJobModal.vue -->

<template>

  <div class="modal" id="deleteJobModal" tabindex="-1" aria-labelledby="deleteJobModalLabel" aria-hidden="true" ref="deleteJobModal">
    <div class="modal-dialog">

      <div class="modal-content shadow shadow-lg">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">Delete Job {{ jobNiceId }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          Are you sure you want to delete this job?
        </div>

        <div class="modal-footer">
          <span v-if="isDeleting('deletingJob')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger" @click="deleteRecord" :disabled="isDeleting('deletingJob') || isSubmitting">Delete</button>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';

export default {
  name: 'DeleteJobModal',
  emits: [
    'showAlert',
    'showError',
    'fetchJobs',
    'fetchOpp',
  ],
  props: {
    jobId: {
      type: String,
      required: true,
    },
    jobNiceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      isSubmitting: false,
      modalInstance: null,
    };
  },

  computed: {
    ...mapGetters([
      'allLanguagePairsOptions',
      'allServicesOptions',
      'allUnitsOptions',
      'allSpecialitiesOptions',

      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',
    ]),
  },

  watch: { },

  methods: {
    openModal() {
      if (!this.modalInstance) {
        const modalElement = this.$refs.deleteJobModal;
        this.modalInstance = new bootstrap.Modal(modalElement);
      }
      this.modalInstance.show();
    },

    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },

    async deleteRecord() {
      this.isSubmitting = true;

      try {
        await this.$store.dispatch('deleteRecord', {
          tableName: 'Jobs',
          recordId: this.jobId,
          deletingStateVariable: 'deletingJob',
        });

        this.$emit('showAlert', 'success', 'Successfully deleted job');

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error deleting job: ' + error.message);
        console.error('Error deleting job: ', error);

      } finally {
        this.isSubmitting = false;
        this.closeModal();
        this.$emit('fetchJobs');
        this.$emit('fetchOpp');

      }
    },
  },

  async mounted() { },

  created() { },
};
</script>