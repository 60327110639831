// globalMixin.js

import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      selectedRow: {}
    };
  },

  computed: {
    ...mapGetters([
      'getProperty',
    ]),
  },

  methods: {
    mixinGetBadgeClass(status) {
      switch (status) {
        case "Order: In Progress":
          return "bg-order-in-progress";
        case "Order: Not Started Yet":
          return "bg-order-not-started-yet";
        case "Order: Completed":
          return "bg-order-completed";
        case "Order: Cancelled":
          return "bg-order-cancelled";
        case "Quote: Open":
          return "bg-quote-open";
        case "Quote: Won":
          return "bg-quote-won";
        case "Quote: Abandoned":
          return "bg-quote-abandoned";
        case "Quote: Lost":
          return "bg-quote-lost";
        default:
          return "";
      }
    },

    mixinGetCurrencyBadgeClass(currency) {
      switch (currency) {
        case "EUR":
          return "bg-currency-eur";
        case "USD":
          return "bg-currency-usd";
        case "GBP":
          return "bg-currency-gbp";
        case "Other":
          return "bg-currency-other";
        default:
          return "";
      }
    },

    mixinGetVendorTypeBadgeClass(type) {
      switch (type) {
        case "Agency":
          return "bg-vendor-agency";
        case "Individual":
          return "bg-vendor-individual";
        default:
          return "";
      }
    },

    mixinSelectRow(index, table) {
      if (this.selectedRow[table] === index) {
          // If the clicked row is already selected, deselect it
          this.selectedRow[table] = null;
        } else {
          // Otherwise, select the clicked row
          this.selectedRow[table] = index;
        }
    },

    mixinGetOppDetailsURL(oppId) {
      return `/opps/${oppId}`;
    },

    mixinFormatNumber(value, decimalPlaces = 2) {
      if (value === undefined) {
        return '';
      }

      return new Intl.NumberFormat('en-US', { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces }).format(value);
    },

    mixinFormatMargin(value) {
      let margin = parseFloat(value);

      if (margin <= 1) {
        margin *= 100;
      }

      return !isNaN(margin) ? margin.toFixed(2) + '%' : 'N/A';
    },

    mixinGetSelectedField(options) {
      const fieldValue = this.record.fields[options.fieldName];
      const selectedId = Array.isArray(fieldValue) ? fieldValue[0] : fieldValue;
      const selectedName = this[options.optionsArrayName].find(item => item.value === selectedId);
      return selectedName ? selectedName.label : '';
    },

    mixinSetSelectedField(options) {
      const selectedName = this[options.optionsArrayName].find(item => item.value === options.value.value);
      if (Array.isArray(this.record.fields[options.fieldName])) {
        this.record.fields[options.fieldName][0] = selectedName.value;
      } else {
        this.record.fields[options.fieldName] = selectedName.value;
      }
    },

    mixinGetSelectedArrayField(options) {
      return this.record.fields[options.fieldName].map(id => {
        const item = this[options.optionsArrayName].find(option => option.value === id);
        return item ? item : null;
      }).filter(item => item !== null);
    },

    mixinSetSelectedArrayField(options) {
      this.record.fields[options.fieldName] = options.value.map(item => item.value);
    },

    mixinGetPercentageClass(value) {
      if (value < 1) {
        value *= 100;
      }

      if (value < 70) {
        return 'text-danger';
      } else if (value >= 70 && value < 80) {
        return 'text-warning';
      } else {
        return 'text-success';
      }
    },

    mixinGetValueColor(value1, value2) {
      return value1 > value2 ? 'text-success' : 'text-danger';
    },

    mixinGetTeamBadgeClass(teamMember) {
      const teamIndex = this.getProperty('team').findIndex(teamObj => teamObj.fields.Name === teamMember);
      const colorIndex = teamIndex !== -1 ? teamIndex % 5 : 0; // Change 5 to the number of colors
      return `color-${colorIndex}`;
    },

    mixinGetCountryBadgeClass(country) {
      const index = this.getProperty('countries').findIndex(teamObj => teamObj.fields.Name === country);
      const colorIndex = index !== -1 ? index % 5 : 0; // Change 5 to the number of colors
      return `color-${colorIndex}`;
    },

    mixinFormatDate(dateString) {
      if (typeof dateString === 'string' && dateString !== '') {
        // Split the dateString into components
        const [year, month, day] = dateString.split('-').map(part => parseInt(part, 10));
        // Create a new Date object using the local time zone
        let date = new Date(year, month - 1, day);
        return date.getDate() + ' ' + date.toLocaleString('en-US', { month: 'short' }) + ' ' + date.getFullYear();
      }
      return '';
    },

    mixinIsUserInGroup(group) {
      // This is needed for any view that requires authentication and uses the mixin
      // because mixinCheckUserAuthentication is used in the created() hook of each component
      // and this hook does not wait for async operations.
      if (!this.$clerk.user) {
        return false;
      }

      const userId = this.$clerk.user.id;
      return this.$store.state.userGroups[userId]?.group === group;
    },

    mixinGetUserPhoto() {
      const clerkId = this.$clerk.user.id;
      return this.$store.state.userGroups[clerkId]?.photo;
    },

    mixinUserInRoles(roles, isPublicMode = false) {
      // Immediately return true if in public mode, as role checks are irrelevant
      if (isPublicMode) {
        return true;
      }

      // Original role check logic
      return roles.some(role => this.mixinIsUserInGroup(role));
    },

    mixinCheckUserAuthentication() {
      // debug
      // console.log(this.$clerk.user);

      if (!this.$clerk.user) {
        this.$router.push({ name: 'Login' });
      }
    },

    // This is a very complex mixin that should be refactored perhaps.
    // It's used in Quotes, Orders and Jobs to switch the view
    async mixinSelectMonth(monthYear) {
      // debug
      console.log('mixinSelectMonth called with:', monthYear);

      // Reset other filters if they exist in the component
      if (this.selectedStatus !== undefined) {
        this.selectedStatus = 'All';
        this.filters.status.value = '';
      }
      if (this.selectedCreatedBy !== undefined) {
        this.selectedCreatedBy = 'All';
        this.filters.createdBy.value = '';
      }
      if (this.selectedPM !== undefined) {
        this.selectedPM = 'All';
        this.filters.pm.value = '';
      }
      if (this.selectedCompany !== undefined) {
        this.selectedCompany = 'All';
        this.filters.company.value = '';
      }

      if (/^\d{4}$/.test(monthYear)) { // Check if monthYear is a full year (e.g., 2022, 2023, 2024)
        this.selectedMonth = {
          label: monthYear,
          value: monthYear,
        };
        this.currentPage = 1;

        const year = parseInt(monthYear, 10);
        const startOfYear = new Date(year, 0, 1); // January 1st of the selected year
        const endOfYear = new Date(year, 11, 31); // December 31st of the selected year

        const startOfLastYear = new Date(year - 1, 0, 1); // January 1st of the previous year
        const endOfLastYear = new Date(year - 1, 11, 31); // December 31st of the previous year

        // Checks if this.records exists to see if the mixin is called from quotes.vue or from orders.vue
        if (this.records !== undefined) {
          this.records = await this.fetchRecordsForPeriod(startOfYear, endOfYear);
        } else {
          this.thisYearOrders = await this.fetchRecordsForPeriod(startOfYear, endOfYear);
          this.lastYearOrders = await this.fetchRecordsForPeriod(startOfLastYear, endOfLastYear);
        }
      } else {
        const [month, year] = monthYear.split(' ');
        const monthName = new Date(Date.parse(`1 ${monthYear}`)).toLocaleString('en-US', { month: 'long' });
        this.selectedMonth = {
          label: `${monthName} (${year})`,
          value: monthYear,
        };
        this.currentPage = 1;

        // Checks if this.records exists to see if the mixin is called from quotes.vue or from orders.vue
        if (this.records !== undefined) {
          this.records = await this.fetchRecords(month, year);
        } else {
          this.thisYearOrders = await this.fetchRecords(month, year);

          const lastYear = parseInt(year) - 1;
          this.lastYearOrders = await this.fetchRecords(month, lastYear.toString());
        }
      }

      this.$router.replace({
        query: {
          ...this.$route.query,
          view: this.selectedMonth.value,
        },
      }).catch(() => {});
    },
  }
};