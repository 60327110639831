<!-- ReportOppsByCompany.vue -->

<template>
  <div class="border rounded mb-4">
    <div class="p-2 bg-light fw-semibold small rounded-top rounded-right">
      Opportunities by Company ({{ tableRows.length }})
    </div>
    <div class="p-2">
      <div class="table-responsive">
        <VTable :data="tableRows" class="table table-sm mb-0">
          <template #head>
            <tr class="small">
              <VTh sortKey="name" class="text-nowrap small" style="max-width: 320px;">Company Name</VTh>
              <VTh sortKey="description" class="text-nowrap small" style="width: 420px;">Description</VTh>
              <VTh sortKey="website" class="text-nowrap small" style="max-width: 220px;">Website</VTh>
              <VTh sortKey="country" class="text-nowrap small">Country</VTh>
              <VTh sortKey="contact" class="text-nowrap small">Contacts</VTh>
              <VTh sortKey="contactEmail" class="text-nowrap small">Contact Emails</VTh>
              <VTh sortKey="contactTitle" class="text-nowrap small">Contact Titles</VTh>
              <VTh sortKey="totalValue" defaultSort="desc" class="text-nowrap text-end small">Total</VTh>
              <VTh sortKey="oppsCount" class="text-nowrap text-end small">Opps</VTh>
              <VTh sortKey="oppsCreatedBy" class="text-nowrap small">Opps Created By</VTh>
              <VTh sortKey="acquiredOn" class="text-nowrap text-end small">Acquired On</VTh>
              <VTh sortKey="acquiredBy" class="text-nowrap small">Acquired By</VTh>
              <VTh sortKey="totalOpps" class="text-nowrap text-end small">Total Lifetime</VTh>
            </tr>
          </template>

          <template #body="{ rows }">
            <tr v-for="row in rows" :key="row.name" v-expandable class="custom-row small">
              <td class="align-middle small">
                <div class="text-truncate" style="max-width: 300px;">
                  <a :href="`https://app.languageburo.com/companies/${row.companyId}`" target="_blank">{{ row.name }}</a>
                </div>
              </td>
              <td class="align-middle small">
                <div class="text-truncate" style="max-width: 400px;">
                  {{ row.description }}
                </div>
              </td>
              <td class="text-nowrap align-middle small" @click.stop>
                <div class="text-truncate" style="max-width: 200px;">
                  <template v-if="row.website">
                    <a :href="row.website" target="_blank" class="text-dark text-decoration-none hover-blue">{{ row.website }}</a>
                  </template>
                </div>
              </td>
              <td class="text-nowrap align-middle small" @click.stop>{{ row.country }}</td>
              <td class="text-nowrap align-middle small" @click.stop>
                <div v-for="contact in row.contacts" :key="contact">{{ contact }}</div>
              </td>
              <td class="text-nowrap align-middle small" @click.stop>
                <div v-for="contactEmail in row.contactEmails" :key="contactEmail">{{ contactEmail }}</div>
              </td>
              <td class="text-nowrap align-middle small" @click.stop>
                <div v-for="contactTitle in row.contactTitles" :key="contactTitle">{{ contactTitle }}</div>
              </td>
              <td class="text-nowrap align-middle text-end small" @click.stop>€{{ mixinFormatNumber(row.totalValue) }}</td>
              <td class="text-nowrap align-middle text-end small" @click.stop>{{ row.oppsCount }}</td>
              <td class="text-nowrap align-middle small" @click.stop>{{ row.oppsCreatedBy }}</td>
              <td class="text-nowrap align-middle text-end small" @click.stop>{{ row.acquiredOn }}</td>
              <td class="text-nowrap align-middle small" @click.stop>{{ row.acquiredBy }}</td>
              <td class="text-nowrap align-middle text-end small" @click.stop>€{{ mixinFormatNumber(row.totalOpps) }}</td>
            </tr>
          </template>
        </VTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReportOppsByCompany',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
    records: {
      type: Array,
      required: true,
    },
  },
  data() { },
  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',
    ]),
    tableRows() {
      const companyStats = this.records.reduce((acc, record) => {
        const company = record.fields['Company Lookup'] ? record.fields['Company Lookup'][0] : '';
        const contacts = record.fields['Contact Lookup'] || [];
        const contactTitles = record.fields['Contact Title'] || [];
        const contactEmails = record.fields['Email (from "Contact" table)'] || [];
        const website = record.fields['Company Website'] ? record.fields['Company Website'][0] : '';
        const companyId = record.fields['Company ID'] ? record.fields['Company ID'][0] : '';
        const description = record.fields['Company Description'] ? record.fields['Company Description'][0] : '';
        const country = record.fields['Company Country Lookup'] ? record.fields['Company Country Lookup'][0] : '';
        const oppsCreatedBy = record.fields['Opps Created By Lookup'] ? record.fields['Opps Created By Lookup'].join(', ') : '';
        const acquiredOn = record.fields['Company Acquired On'] ? record.fields['Company Acquired On'][0] : null;
        const totalOpps = record.fields['Company Opps Won in € Lookup'] ? record.fields['Company Opps Won in € Lookup'][0] : null;
        const acquiredBy = record.fields['Company Acquired By Lookup'] ? record.fields['Company Acquired By Lookup'][0] : null;
        const recordValue = record.fields['9 - Gross Revenue in €'] || 0;

        if (!acc[company]) {
          acc[company] = {
            name: company,
            contacts: new Set(),
            contactTitles: new Set(),
            contactEmails: new Set(),
            website: website,
            companyId: companyId,
            description: description,
            country: country,
            totalValue: 0,
            oppsCount: 0,
            oppsCreatedBy: oppsCreatedBy,
            acquiredOn: acquiredOn,
            acquiredBy: acquiredBy,
            totalOpps: totalOpps,
          };
        }

        contacts.forEach(contact => acc[company].contacts.add(contact));
        contactTitles.forEach(contactTitle => acc[company].contactTitles.add(contactTitle));
        contactEmails.forEach(contactEmail => acc[company].contactEmails.add(contactEmail));
        acc[company].totalValue += recordValue;
        acc[company].oppsCount += 1;

        return acc;
      }, {});

      const statsArray = Object.values(companyStats).map(company => ({
        ...company,
        contacts: Array.from(company.contacts),
        contactTitles: Array.from(company.contactTitles),
        contactEmails: Array.from(company.contactEmails),
      }));

      statsArray.sort((a, b) => b.totalValue - a.totalValue);

      return statsArray;
    },
  },
  watch: { },
  methods: {},
  created() { },
};
</script>