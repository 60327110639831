<!-- DeleteVendorModal.vue -->

<template>

  <div class="modal" id="deleteVendorModal" tabindex="-1" aria-labelledby="deleteVendorModalLabel" aria-hidden="true" ref="deleteVendorModal">
    <div class="modal-dialog">

      <div class="modal-content shadow shadow-lg">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">Delete Vendor</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div v-if="!isSubmitting">
            Are you sure you want to delete this vendor?
          </div>

          <div v-else>
            Deleting vendor and fetching list of vendors...
          </div>
        </div>

        <div class="modal-footer">
          <span v-if="isDeleting('deletingVendor') || isSubmitting" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isDeleting('deletingVendor') || isSubmitting">Cancel</button>
          <button type="button" class="btn btn-danger" @click="deleteRecord" :disabled="isDeleting('deletingVendor') || isSubmitting">Delete</button>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';

export default {
  name: 'DeleteVendorModal',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
    vendorId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isSubmitting: false,
      modalInstance: null,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  watch: { },

  methods: {
    openModal() {
      if (!this.modalInstance) {
        const modalElement = this.$refs.deleteVendorModal;
        this.modalInstance = new bootstrap.Modal(modalElement);
      }
      this.modalInstance.show();
    },

    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },

    async deleteRecord() {
      this.isSubmitting = true;

      try {
        await this.$store.dispatch('deleteRecord', {
          tableName: 'Vendors',
          recordId: this.vendorId,
          deletingStateVariable: 'deletingVendor',
        });

        await this.$store.dispatch('fetchRecords', {
          tableName: 'Vendors',
          stateVariable: 'vendors',
          fetchingStateVariable: 'fetchingVendors',
        }),

        this.$emit('showAlert', 'success', 'Successfully deleted vendor');
        this.closeModal();

        this.$router.push('/vendors');

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error deleting vendor: ' + error.message);
        console.error('Error deleting vendor: ', error);

      } finally {
        this.isSubmitting = false;
      }
    },
  },

  async mounted() { },

  created() { },
};
</script>