<!-- ReusableJobsTable.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance', 'VM'])">
    <div v-if="records.length > 0">
      <!-- Table filters and search -->
      <div class="filters mb-1 p-2 ps-0 pe-0">
        <div class="d-flex align-items-center">
          <!-- Filters -->
          <div v-if="showFilters" class="d-flex align-items-center">
            <!-- Filter: Order Started On -->
            <div class="d-flex align-items-center">
              <div>
                <VueDatePicker
                  v-model="filters.dateRange.value"
                  id="dateRange"
                  range
                  :enable-time-picker="false"
                  :preset-dates="presetDates"
                  input-class-name="custom-date-picker"
                  hide-input-icon
                  format="dd MMM yyyy"
                  placeholder="Filter by Order Start Date"
                />
              </div>
            </div>
          </div>

          <!-- Showing text, Pagination and Search -->
          <div :class="['d-flex align-items-center', { 'ms-auto': showFilters }]">
            <!-- Showing text -->
            <div class="text-muted small me-3">{{ showingText }}</div>

            <!-- Pagination -->
            <VTPagination
              v-model:currentPage="currentPage"
              :totalPages="totalPages"
              :maxPageLinks="3"
              :boundaryLinks="false"
              class="me-2"
            >
              <template #next>
                <span class="prevent-select">Next</span>
              </template>

              <template #previous>
                <span class="prevent-select">Previous</span>
              </template>
            </VTPagination>

            <!-- Search -->
            <input v-model="filters.name.value" type="text" class="form-control form-control-sm" placeholder="Filter by Opp #, Language Pair, Vendor, Company or Contact" style="width: 500px;" />
          </div>
        </div>
      </div>

      <!-- Checked rows actions -->
      <div v-if="mixinUserInRoles(['Admin'])">
        <div v-if="selectedRows.length > 0" class="p-2 border-bottom">
          <span class="text-muted small">{{ selectedRows.length }} {{ selectedRows.length > 1 ? 'rows' : 'row' }} selected. Total Billed: €{{ mixinFormatNumber(totalRevenueOfSelectedRows) }}, Total Vendor Costs: €{{ mixinFormatNumber(totalVendorCostsOfSelectedRows) }}, Profit: €{{ mixinFormatNumber(totalProfitOfSelectedRows) }}, Average Margin: {{ mixinFormatMargin(averageMarginOfSelectedRows) }}</span>
        </div>
      </div>

      <div class="table-responsive">
        <VTable
          :data="tableRows"
          :pageSize="pageSize"
          v-model:currentPage="currentPage"
          @totalPagesChanged="totalPages = $event"
          @stateChanged="handleStateChanged"
          :filters="filters"
          sortHeaderClass="sort-header"
          class="table custom-table border"
          selectionMode="multiple"
          :selectOnClick="false"
          selectedClass="bg-light"
        >
          <!-- Commented because of known issue with "select all" checkbox -->
          <!-- <template #head="{ allRowsSelected, toggleAllRows }"> -->
          <template #head>
            <tr class="custom-row small">
              <!-- <th class="small"> -->
                <!-- Commented because of known issue with "select all" checkbox -->
                <!-- <input type="checkbox" class="form-check-input" :checked="allRowsSelected" @change="toggleAllRows" /> -->
              <!-- </th> -->
              <!-- <th class="small" style="min-width: 50px;"></th> -->
              <VTh sortKey="jobId" class="text-nowrap text-end small" style="">Job</VTh>
              <VTh sortKey="quoteNumber" class="text-nowrap text-end small" style="">Opp</VTh>
              <VTh sortKey="orderId" class="text-nowrap text-end small" style="">Order</VTh>
              <VTh defaultSort="desc" sortKey="oppCreatedOn" class="text-nowrap text-end small" style="">Opp Created On</VTh>
              <VTh sortKey="oppStatus" class="text-nowrap small" style="">Opp Status</VTh>
              <VTh sortKey="orderStartDate" class="text-nowrap small" style="">Order Start Date</VTh>
              <VTh sortKey="orderDeliveryDate" class="text-nowrap small" style="">Order Delivery Date</VTh>
              <VTh sortKey="oppCurrency" class="text-nowrap text-end small" style="">Opp Currency</VTh>
              <VTh sortKey="oppCompany" class="text-nowrap small" style="">Client</VTh>
              <VTh sortKey="service" class="text-nowrap small" style="">Service</VTh>
              <VTh sortKey="languagePair" class="text-nowrap small" style="">Language Pair</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" sortKey="unit" class="text-nowrap small" style="">Unit</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" sortKey="units" class="text-nowrap text-end small" style="">Units</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" sortKey="unitPrice" class="text-nowrap text-end small" style="">Unit Price</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" sortKey="subTotal" class="text-nowrap text-end small" style="">Sub Total</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" sortKey="discount" class="text-nowrap text-end small" style="">Disc %</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" sortKey="total" class="text-nowrap text-end small" style="">Total</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" sortKey="totalInEuro" class="text-nowrap text-end border-end small" style="">Total in Euro</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="vendor" class="text-nowrap small" style="min-width: 220px;">Vendor</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="vendorCurrency" class="text-nowrap text-end small" style="">Vendor Currency</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="vendorUnit" class="text-nowrap small" style="">Vendor Unit</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="vendorUnits" class="text-nowrap text-end small" style="">Vendor Units</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="vendorUnitPrice" class="text-nowrap text-end small" style="">Vendor Unit Price</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="vendorSubTotal" class="text-nowrap text-end small" style="">Vendor Sub Total</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="vendorDiscount" class="text-nowrap text-end small" style="">Vendor Disc %</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="vendorTotal" class="text-nowrap text-end small" style="">Vendor Total</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="vendorTotalInEuro" class="text-nowrap text-end border-end small" style="">Vendor Total in Euro</VTh>
              <VTh sortKey="title" class="text-nowrap small" style="min-width: 220px;">Title</VTh>
              <VTh sortKey="notes" class="text-nowrap small" style="min-width: 220px;">Notes</VTh>
              <VTh sortKey="matecatLink" class="text-nowrap small" style="">MateCat Link</VTh>
              <VTh sortKey="translatedOffer" class="text-nowrap small" style="">Translated.com Offer</VTh>
              <VTh v-if="mixinUserInRoles(['Admin'])" sortKey="profit" class="text-nowrap text-end small" style="">Profit in €</VTh>
              <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="margin" class="text-nowrap text-end small" style="">Margin</VTh>
            </tr>
          </template>
          <template #body="{rows}">
            <VTr
                v-for="(row, index) in rows"
                :key="index"
                :row="row"
                v-slot="{ }"
                v-expandable
                class="custom-row small"
              >

              <!-- Checkbox -->
              <!-- <td class="align-middle">
                <input type="checkbox" class="form-check-input" :checked="isSelected" @change.stop="toggle" />
              </td> -->

              <!-- Index -->
              <!-- <td class="text-nowrap align-middle text-end text-muted small" style="">
                {{ (currentPage - 1) * pageSize + index + 1 }}
              </td> -->

              <!-- Job ID -->
              <td class="text-nowrap align-middle text-end" style="">
                {{ row.jobId }}
              </td>

              <!-- Opp -->
              <td class="text-nowrap align-middle text-end" style="">
                <a :href="`/opps/${row.oppId}`" target="_blank" class="text-dark text-decoration-none hover-blue">{{ row.quoteNumber }}</a>
              </td>

              <!-- Order -->
              <td class="text-nowrap align-middle text-end" style="">
                {{ row.orderId }}
              </td>

              <!-- Opp Created On -->
              <td class="text-nowrap align-middle text-end" style="">
                {{ mixinFormatDate(row.oppCreatedOn) }}
              </td>

              <!-- Status -->
              <td class="text-nowrap align-middle" style="">
                <span :class="['badge custom-badge', mixinGetBadgeClass(row.oppStatus)]">
                  {{ row.oppStatus }}
                </span>
              </td>

              <!-- Order Start Date -->
              <td class="text-nowrap align-middle text-end" style="">
                {{ mixinFormatDate(row.orderStartDate) }}
              </td>

              <!-- Order Delivery Date -->
              <td class="text-nowrap align-middle text-end" style="">
                {{ mixinFormatDate(row.orderDeliveryDate) }}
              </td>

              <!-- Opp Currency -->
              <td class="text-nowrap align-middle text-end" style="">
                <span :class="['badge custom-badge', mixinGetCurrencyBadgeClass(row.oppCurrency)]">
                  {{ row.oppCurrency }}
                </span>
              </td>

              <!-- Client -->
              <td class="text-nowrap align-middle" style="">
                <div class="text-truncate" style="max-width: 300px;">
                  <a :href="`/companies/${row.companyId}`" target="_blank" class="text-dark text-decoration-none hover-blue">{{ row.oppCompany }}</a>
                  <br>
                  <span class="text-muted small">
                    <a :href="`/contacts/${row.contactId}`" target="_blank" class="text-muted text-decoration-none hover-blue">
                      {{ row.oppContact }}
                    </a>
                  </span>
                </div>
              </td>

              <!-- Service -->
              <td class="text-nowrap align-middle" style="">
                {{ row.service }}
              </td>

              <!-- Language Pair -->
              <td class="text-nowrap align-middle" style="">
                {{ row.languagePair.replace('>', '›') }}
              </td>

              <!-- Unit -->
              <td v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="text-nowrap align-middle" style="">
                {{ row.unit }}
              </td>

              <!-- Units -->
              <td v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="text-nowrap align-middle text-end" style="">
                {{ row.units }}
              </td>

              <!-- Unit Price -->
              <td v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="text-nowrap align-middle text-end" style="">
                {{ getCurrencySymbol[row.oppCurrency] }}{{ row.unitPrice }}
              </td>

              <!-- Sub Total -->
              <td v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="text-nowrap align-middle text-end" style="">
                {{ getCurrencySymbol[row.oppCurrency] }}{{ mixinFormatNumber(row.subTotal) }}
              </td>

              <!-- Disc % -->
              <td v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="text-nowrap align-middle text-end" style="">{{ row.discount }}</td>

              <!-- Total -->
              <td v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="text-nowrap align-middle text-end" style="">
                {{ getCurrencySymbol[row.oppCurrency] }}{{ mixinFormatNumber(row.total) }}
              </td>

              <!-- Total in Euro -->
              <td v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="text-nowrap align-middle text-end border-end" style="">
                €{{ mixinFormatNumber(row.totalInEuro) }}
              </td>

              <!-- Vendor -->
              <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle">
                <div class="text-truncate" style="max-width: 300px;">
                  <span v-for="(vendor, index) in row.vendors" :key="index">
                    <router-link :to="`/vendors/${vendor.id}`" target="_blank" class="text-dark text-decoration-none hover-blue">{{ vendor.name }}</router-link>
                    <span v-if="index < row.vendors.length - 1">, </span>
                  </span>
                </div>
              </td>

              <!-- Vendor Currency -->
              <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end" style="">
                <span :class="['badge custom-badge', mixinGetCurrencyBadgeClass(row.vendorCurrency)]">
                  {{ row.vendorCurrency }}
                </span>
              </td>

              <!-- Vendor Unit -->
              <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle" style="">
                {{ row.vendorUnit }}
              </td>

              <!-- Vendor Units -->
              <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end" style="">
                {{ row.vendorUnits }}
              </td>

              <!-- Vendor Unit Price -->
              <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end" style="">
                {{ getCurrencySymbol[row.vendorCurrency] }}{{ row.vendorUnitPrice }}
              </td>

              <!-- Vendor Sub Total -->
              <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end" style="">
                {{ getCurrencySymbol[row.vendorCurrency] }}{{ mixinFormatNumber(row.vendorSubTotal) }}
              </td>

              <!-- Vendor Disc % -->
              <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end" style="">
                {{ row.vendorDiscount }}
              </td>

              <!-- Vendor Total -->
              <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end" style="">
                {{ getCurrencySymbol[row.vendorCurrency] }}{{ mixinFormatNumber(row.vendorTotal) }}
              </td>

              <!-- Vendor Total in Euro -->
              <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end" style="">
                €{{ mixinFormatNumber(row.vendorTotalInEuro) }}
              </td>

              <!-- Title -->
              <td class="text-nowrap align-middle" style="">
                <div class="text-truncate" style="max-width: 200px;">
                  {{ row.title }}
                </div>
              </td>

              <!-- Notes -->
              <td class="text-nowrap align-middle" style="">
                <div class="text-truncate" style="max-width: 200px;">
                  {{ row.notes }}
                </div>
              </td>

              <!-- MateCat Link -->
              <td class="text-nowrap align-middle" style="">
                {{ row.matecatLink }}
              </td>

              <!-- Translated.com Offer -->
              <td class="text-nowrap align-middle" style="">
                {{ row.translatedOffer }}
              </td>

              <!-- Profit in € -->
              <td v-if="mixinUserInRoles(['Admin'])" class="text-nowrap align-middle text-end" style="">
                €{{ mixinFormatNumber(row.profit) }}
              </td>

              <!-- Margin -->
              <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end" :class="mixinGetPercentageClass(row.margin)" style="">
                {{ mixinFormatMargin(row.margin) }}
              </td>
            </VTr>
            <!-- <tr v-if="mixinUserInRoles(['Admin'])" class="tfoot">
              <td colspan="19"></td>
              <td class="text-nowrap align-middle text-end text-muted small border-end">€{{ mixinFormatNumber(totalFilteredTotal) }}</td>
              <td colspan="8"></td>
              <td class="text-nowrap align-middle text-end text-muted small">€{{ mixinFormatNumber(totalFilteredVendorCosts) }}</td>
              <td colspan="4"></td>
              <td class="text-nowrap align-middle text-end text-muted small">€{{ mixinFormatNumber(totalFilteredProfit) }}</td>
              <td class="text-nowrap align-middle text-end text-muted small">{{ mixinFormatMargin(averageFilteredMargin) }}</td>
            </tr> -->
          </template>
        </VTable>

        <div v-if="totalFilteredItems === 0" class="text-center text-muted p-3">
          <p class="mb-0">No results found based on the current filters.</p>
        </div>
      </div>
    </div>

    <div v-else class="text-center text-muted p-5">
      No jobs found.
    </div>
  </div>

  <div v-else>
    <p class="p-4">You are not authorized to see this page.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { startOfMonth, endOfMonth, startOfYear, endOfYear, subMonths, subYears } from 'date-fns';

export default {
  name: 'ReusableJobsTable',

  emits: [
    'showAlert',
    'showError',
  ],

  props: {
    records: {
      type: Array,
      required: true,
    },

    showFilters: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      filters: {
        name: { value: '', keys: ['quoteNumber', 'languagePair', 'vendor', 'oppCompany', 'oppContact'] },

        dateRange: { value: [] },
      },

      selectedRows: [],

      presetDates: [
        { label: 'This month', value: [startOfMonth(new Date()), endOfMonth(new Date())] },
        { label: 'Last month', value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
        { label: 'This year', value: [startOfYear(new Date()), endOfYear(new Date())] },
        { label: 'Last year', value: [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))] },
        { label: '2 years ago', value: [startOfYear(subYears(new Date(), 2)), endOfYear(subYears(new Date(), 2))] },
      ],

      totalPages: 1, // TODO: This gives a warning, but not sure how to fix. It doesn't alter the functionality though
      currentPage: 1,
      pageSize: 100,

      totalFilteredItems: 0,
      totalFilteredTotal: 0,
      totalFilteredProfit: 0,
      totalFilteredVendorCosts: 0,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',
      'getCurrencySymbol',
    ]),

    averageFilteredMargin() {
      if (this.totalFilteredTotal === 0) {
        return 0;
      }
      return (this.totalFilteredProfit / this.totalFilteredTotal);
    },

    totalRevenueOfSelectedRows() {
      return this.selectedRows.reduce((total, row) => total + row.totalInEuro, 0);
    },

    totalVendorCostsOfSelectedRows() {
      return this.selectedRows.reduce((total, row) => total + row.vendorTotalInEuro, 0);
    },

    totalProfitOfSelectedRows() {
      return this.selectedRows.reduce((total, row) => total + row.profit, 0);
    },

    averageMarginOfSelectedRows() {
      const totalProfit = this.totalProfitOfSelectedRows;
      const totalRevenue = this.totalRevenueOfSelectedRows;
      return totalRevenue !== 0 ? (totalProfit / totalRevenue) * 100 : 0;
    },

    showingText() {
      const start = (this.currentPage - 1) * this.pageSize + 1;
      const end = Math.min(this.currentPage * this.pageSize, this.totalFilteredItems);
      return `Showing ${start}-${end} of ${this.totalFilteredItems}`;
    },

    tableRows() {
      // Filter the records based on the selected date range
      const filteredRecords = this.records.filter(record => {
        const orderStartDate = new Date(record.fields['Order Started On (Date)']);
        const [start, end] = Array.isArray(this.filters.dateRange.value) ? this.filters.dateRange.value : [null, null];
        return (!start || orderStartDate >= start) && (!end || orderStartDate <= end);
      });

      return filteredRecords.map(row => {
        let vendors = Array.isArray(row.fields['Vendors Lookup']) ? row.fields['Vendors Lookup'] : [];
        let vendorIds = row.fields['Vendors ID'] ? row.fields['Vendors ID'] : [];

        let vendorLinks = vendors.map((vendor, index) => {
          return {
            name: vendor,
            id: vendorIds[index]
          };
        });

        return {
          id: row.id,
          jobId: row.fields['Job ID'],
          quoteNumber: row.fields['Quote No.'] ? row.fields['Quote No.'].join(', ') : '',
          oppId: row.fields['Opp ID Lookup'], // not real lookups, so they are not arrays
          orderId: row.fields['OID'] ? row.fields['OID'].join(', ') : '',
          oppCreatedOn: row.fields['Opp Created On'] ? row.fields['Opp Created On'].join(', ') : '',
          orderStartDate: row.fields['Order Started On (Date)'] ? row.fields['Order Started On (Date)'].join(', ') : '',
          orderDeliveryDate: row.fields['Order Completed On (Date)'] ? row.fields['Order Completed On (Date)'].join(', ') : '',
          oppStatus: row.fields['Status'] ? row.fields['Status'].join(', ') : '',
          oppCurrency: row.fields['Opp Currency'] ? row.fields['Opp Currency'].join(', ') : '',
          oppCompany: row.fields['Company Lookup'], // not real lookups, so they are not arrays
          companyId: row.fields['Company ID'] ? row.fields['Company ID'].join(', ') : '',
          oppContact: row.fields['Contact Lookup'], // not real lookups, so they are not arrays
          contactId: row.fields['Contact ID'] ? row.fields['Contact ID'].join(', ') : '',
          service: row.fields['Service ID Lookup'] ? row.fields['Service ID Lookup'].join(', ') : '',
          languagePair: row.fields['Language Pair Lookup'] ? row.fields['Language Pair Lookup'].join(', ') : '',
          unit: row.fields['Unit ID Lookup'] ? row.fields['Unit ID Lookup'].join(', ') : '',
          units: row.fields['Units'],
          unitPrice: row.fields['Unit Price'],
          subTotal: row.fields['1 - Sub Total in Opp Currency'],
          discount: row.fields['Disc %'],
          total: row.fields['3 - Total in Opp Currency'],
          totalInEuro: row.fields['7 - Total in €'],
          vendors: vendorLinks,
          vendorCurrency: row.fields['Vendor Currency'],
          vendorUnit: row.fields['Vendor Unit ID Lookup'] ? row.fields['Vendor Unit ID Lookup'].join(', ') : '',
          vendorUnits: row.fields['Vendor Units'],
          vendorUnitPrice: row.fields['Vendor Unit Price'],
          vendorSubTotal: row.fields['1 - Vendor Sub Total in Vendor Currency (Formula)'],
          vendorDiscount: row.fields['2 - Vendor Disc in %'],
          vendorTotal: row.fields['4 - Vendor Total in Vendor Currency'],
          vendorTotalInEuro: row.fields['6 - Vendor Total in € (Number)'],
          title: row.fields['Title'],
          notes: row.fields['Notes'],
          matecatLink: row.fields['MateCat Link'],
          translatedOffer: row.fields['Translated.com Offer'],
          profit: row.fields['Profit in €'],
          margin: typeof row.fields['Margin'] === 'number' ? parseFloat(row.fields['Margin']).toFixed(2) : null,
        };
      });
    },
  },

  watch: {
    'filters.dateRange.value': function() {
      this.selectedRows.splice(0, this.selectedRows.length);
    },

    'filters.name.value': function() {
      this.totalFilteredTotal = 0;
      this.totalFilteredVendorCosts = 0;
    },
  },

  methods: {
    handleStateChanged(tableState) {
      this.totalFilteredItems = tableState.rowsPrePagination.length;
      this.selectedRows = tableState.selectedRows;

      this.totalFilteredTotal = 0;
      this.totalFilteredVendorCosts = 0;
      this.totalFilteredProfit = 0;

      for (let row of tableState.rowsPrePagination) {
        this.totalFilteredTotal += row.totalInEuro || 0;
        this.totalFilteredProfit += row.profit || 0;
        this.totalFilteredVendorCosts += row.vendorTotalInEuro || 0;
      }
    },
  },

  created() { },
};
</script>