<!-- CreateUnitModal.vue -->

<template>

  <div class="modal" id="createUnitModal" tabindex="-1" aria-labelledby="createUnitModalLabel" aria-hidden="true" ref="createUnitModal">
    <div class="modal-dialog">
      <div class="modal-content shadow shadow-lg">

        <div class="modal-header border-bottom">
          <h5 class="modal-title">Create New Unit</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div v-if="createdUnit" class="alert alert-success mt-3">
          <i class="bi bi-check-circle-fill me-1"></i>
          Unit "{{ createdUnit.fields['Name'] }}" created. <a class="alert-link" :href="'/settings/units/' + createdUnit.id" target="_blank">Open this unit profile</a>.
        </div>

        <div class="modal-body" v-if="!createdUnit">
          <!-- Name -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Name
            </div>

            <div class="col-8">
              <input class="form-control" type="text" v-model="newUnit.name">
            </div>
          </div>
        </div>

        <div class="modal-footer" v-if="!createdUnit">
          <span v-if="isCreating('creatingUnit')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-primary" @click="createUnit" :disabled="isCreating('creatingUnit') || isSubmitting">Create Unit</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';

export default {
  name: 'createUnitModal',
  emits: [
    'showAlert',
    'showError',
  ],

  data() {
    return {
      newUnit: this.defaultNewUnit(),
      createdUnit: null,
      isSubmitting: false,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  methods: {
    openModal() {
      if (!this.modalInstance) {
        const modalElement = this.$refs.createUnitModal;
        this.modalInstance = new bootstrap.Modal(modalElement);
      }
      this.modalInstance.show();
    },

    defaultNewUnit() {
      return {
        name: '',
      };
    },

    resetForm() {
      this.newUnit = this.defaultNewUnit();
    },

    resetCreatedUnit() {
      this.createdUnit = null;
    },

    validateFields() {
      let errors = [];

      if (!this.newUnit.name) { errors.push('"Name" is required.'); }

      return errors;
    },

    formatData() {
      const newUnitName = this.newUnit.name.trim();

      const formattedData = {
        'Name': newUnitName,
      };

      return formattedData;
    },

    async createUnit() {
      this.isSubmitting = true;

      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        this.isSubmitting = false;
        return;
      }

      const formattedData = this.formatData();

      try {
        const createdUnit = await this.$store.dispatch('createRecord', {
          tableName: 'Units',
          newRecord: formattedData,
          creatingStateVariable: 'creatingUnit',
        });

        // for use in the view and as a flag
        this.createdUnit = createdUnit.records[0];
        this.resetForm();

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error creating unit: ' + error.message);
        console.error('Error creating unit: ', error);

      } finally {
        this.isSubmitting = false;
      }
    },
  },

  mounted() {
    // Resets modal on close
    // we use this.resetForm() and not this.resetForm because we are calling 2 methods
    this.$refs.createUnitModal.addEventListener('hidden.bs.modal', () => {
      this.resetForm();
      this.resetCreatedUnit();
    });
  }
}
</script>