<!-- QuoteDetails.vue -->

<template>

  <div v-if="!isFetching('fetchingOpp') && isDataFetched">

    <div class="mobile-message p-4 text-center">
      <div class="mb-4">
        <img src="@/assets/images/lb-logo.svg" alt="Language Buró" class="lb-logo">
      </div>

      <p>This page is not available on mobile. Please open this link in a web browser.</p>
    </div>

    <div class="main-content">
      <!-- Confirm Order CTA -->
      <template v-if="showConfirmOrderButton">
        <div class="fixed-top p-3 bg-white text-center shadow-sm">
          <div class="container">
            <div class="d-flex justify-content-center align-items-center">
              <span class="fs-6 me-2">Total: {{ currency }}{{ value }}</span>

              <button class="btn btn-lg btn-primary ms-2"
                @click.prevent="confirmOrder()"
                :disabled="isUpdating('updatingOpp')">
                <div v-if="isUpdating('updatingOpp')">
                  <div class="spinner-border spinner-border-sm" role="status"></div>
                </div>
                <div v-else>
                  Confirm Order
                </div>
              </button>
            </div>

            <p class="mt-2 mb-0 small">Quality 100% guaranteed, pay by invoice in 30 days</p>
          </div>
        </div>
      </template>

      <!-- Edit Opp Button -->
      <template v-if="user">
        <div class="d-flex justify-content-center align-items-center p-3 small bg-white border-bottom">
          <p class="mb-0 me-2">You are viewing Quote {{ quoteNumber }} created by {{ createdBy }}</p>
          <a :href="oppUrl" class="btn btn-sm btn-outline-secondary"><i class="bi bi-pencil-fill me-1"></i> Edit</a>
        </div>
      </template>

      <!-- Main -->
      <div class="bg-light p-4 pt-3">
        <div class="container">
          <div class="bg-white p-5 shadow-sm rounded">

            <!-- Notification: Quote Won -->
            <div v-if="isQuoteWon" class="alert alert-warning mb-4 p-4 text-center hide">
              <p class="fs-3 fw-semibold mb-2">Thank you for confirming this quote!</p>
              <p class="small mb-0">Our team will start this project immediately. If you require assistance, please contact {{ createdBy }}, at {{ createdByEmail }}</p>
            </div>

            <!-- Notification: Order in Progress -->
            <div v-if="isOrderInProgress" class="alert alert-info mb-4 p-4 text-center hide">
              <p class="fs-3 fw-semibold mb-2">Your order is in progress</p>
              <p class="small mb-0">Our team is currently working on your project. If you require assistance, please contact {{ createdBy }}, at {{ createdByEmail }}</p>
            </div>

            <!-- Notification: Order Completed -->
            <div v-if="isOrderCompleted" class="alert alert-success mb-4 p-4 text-center hide">
              <p class="fs-3 fw-semibold mb-2">Your order has been completed</p>
              <p class="small mb-0">Thank you for working with us. If you require assistance, please contact {{ createdBy }}, at {{ createdByEmail }}</p>
            </div>

            <!-- Header -->
            <div class="">
              <div class="mb-2 p-3 pt-0 pb-2 mb-4">
                <img src="@/assets/images/lb-logo.svg" alt="Language Buró" class="lb-logo">
              </div>

              <div class="d-flex mb-2 p-3 pt-0 pb-2 align-items-end">
                <div class="col">
                  <div class="mb-2 fs-4 fw-semibold">
                    Quote {{ quoteNumber }}
                  </div>

                  <div class="small">
                    <!-- Title -->
                    <div class="d-flex pt-1 pb-1 align-items-center">
                      <div class="col-2 text-muted small">
                        Project Title
                      </div>

                      <div class="col">
                        <template v-if="record.fields['Title']">{{ record.fields['Title'] }}</template><template v-else>Untitled</template>
                      </div>
                    </div>

                    <!-- Date -->
                    <div class="d-flex pt-1 pb-1 align-items-center">
                      <div class="col-2 text-muted small">
                        Date
                      </div>

                      <div class="col">
                        {{ mixinFormatDate(record.fields['Created On']) }}
                      </div>
                    </div>

                    <!-- Notes -->
                    <template v-if="record.fields['Public Notes']">
                      <div class="d-flex pt-1 pb-1 align-items-center">
                        <div class="col-2 text-muted small">
                          Notes
                        </div>

                        <div class="col">
                          {{ record.fields['Public Notes'] }}
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <div class="col text-end">
                  <button class="btn btn-sm btn-outline-secondary hide" @click="saveAsPDF"><i class="bi bi-printer me-1"></i> Save as PDF</button>
                </div>
              </div>
            </div>

            <!-- To, From -->
            <div class="row mb-4">
              <div class="col-6">
                <div class="block border rounded p-3 small">
                  <div class="d-flex pt-1 pb-1 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      To
                    </div>

                    <div class="col">
                      {{ company }}
                    </div>
                  </div>

                  <div class="d-flex pt-1 pb-1 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Address
                    </div>

                    <div class="col">
                      <template v-if="record.fields['Billing Address (from Company)']">
                        {{ record.fields['Billing Address (from Company)'] ? record.fields['Billing Address (from Company)'].join(', ') : '' }}
                      </template>
                      <template v-else>
                        N/A
                      </template>
                    </div>
                  </div>

                  <div class="d-flex pt-1 pb-1 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      VAT
                    </div>

                    <div class="col">
                      <template v-if="record.fields['Company VAT Lookup']">
                        {{ record.fields['Company VAT Lookup'] ? record.fields['Company VAT Lookup'].join(', ') : '' }}
                      </template>
                      <template v-else>
                        N/A
                      </template>
                    </div>
                  </div>

                  <div class="d-flex pt-1 pb-1 align-items-center">
                    <div class="col-3 text-muted small">
                      Contact
                    </div>

                    <div class="col">
                      {{ contact }}<br>
                      <span class="text-muted small">{{ contactEmail }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="block border rounded p-3 small">
                  <div class="d-flex pt-1 pb-1 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      From
                    </div>

                    <div class="col">
                      Language Buró OÜ
                    </div>
                  </div>

                  <div class="d-flex pt-1 pb-1 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Address
                    </div>

                    <div class="col">
                      Sepapaja tn 6, Tallinn, 15551, Estonia
                    </div>
                  </div>

                  <div class="d-flex pt-1 pb-1 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      VAT
                    </div>

                    <div class="col">
                      EE10196419
                    </div>
                  </div>

                  <div class="d-flex pt-1 pb-1 align-items-center">
                    <div class="col-3 text-muted small">
                      Contact
                    </div>

                    <div class="col">
                      {{ createdBy }}<br>
                      <span class="text-muted small">{{ createdByEmail }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Jobs -->
            <div class="block small mb-3">
              <div v-if="jobs.length > 0">
                <div class="table-responsive">
                  <VTable :data="tableRows" :pageSize="pageSize" v-model:currentPage="currentPage" @totalPagesChanged="totalPages = $event" sortHeaderClass="sort-header" class="table mb-0 border table-quote-details">
                    <template #head>
                      <tr class="">
                        <th class="text-nowrap text-muted small">Service</th>
                        <VTh sortKey="languagePair" defaultSort="asc" class="text-nowrap text-muted small">Language Pair</VTh>
                        <th class="text-nowrap text-muted small" style="">Unit</th>
                        <th class="text-nowrap text-muted small text-end" style="">Units</th>
                        <th class="text-nowrap text-muted small text-end" style="">Unit Price</th>
                        <th v-if="hasDiscounts" class="text-nowrap text-muted small text-end" style="">Sub Total</th>
                        <th v-if="hasDiscounts" class="text-nowrap text-muted small text-end" style="">Discount</th>
                        <th class="text-nowrap text-muted small text-end" style="">Total</th>
                      </tr>
                    </template>
                    <template #body="{rows}">
                      <VTr v-for="(row, index) in rows" :key="index" :row="row" class="">
                        <!-- Service -->
                        <td class="text-nowrap align-middle">
                          {{ row.service }}

                          <div v-if="row.title" class="text-muted small text-truncate" style="max-width: 400px;">
                            Notes: {{ row.title }}
                          </div>
                        </td>

                        <!-- Language Pair -->
                        <td class="text-nowrap align-middle">{{ row.languagePair.replace('>', '›') }}</td>

                        <!-- Unit -->
                        <td class="text-nowrap align-middle">{{ row.unit }}</td>

                        <!-- Units -->
                        <td class="text-nowrap align-middle text-end">{{ mixinFormatNumber(row.units, 0) }}</td>

                        <!-- Unit Price -->
                        <td class="text-nowrap align-middle text-end">{{ getCurrencySymbol[row.oppCurrency] }}{{ mixinFormatNumber(row.unitPrice) }}</td>

                        <!-- Sub Total -->
                        <td v-if="hasDiscounts" class="text-nowrap align-middle text-end">{{ getCurrencySymbol[row.oppCurrency] }}{{ mixinFormatNumber(row.subTotal) }}</td>

                        <!-- Disc % -->
                        <td v-if="hasDiscounts" class="text-nowrap align-middle text-end">{{ row.discount > 0 ? `${row.discount}%` : '' }}</td>

                        <!-- Total -->
                        <td class="text-nowrap align-middle text-end">{{ getCurrencySymbol[row.oppCurrency] }}{{ mixinFormatNumber(row.total) }}</td>
                      </VTr>
                    </template>
                  </VTable>
                </div>
              </div>

              <div v-else class="text-center text-muted p-5">
                No jobs found.
              </div>
            </div>

            <!-- Extra -->
            <div class="me-2" style="border: 1px solid transparent;">
              <!-- Sub Total -->
              <div class="d-flex text-end mb-2 small">
                <div class="col-1 ms-auto">
                  Subtotal
                </div>

                <div class="col-2">
                  <span class="">{{ record.fields['Currency Lookup'] ? getCurrencySymbol[record.fields['Currency Lookup']] : '' }}{{ mixinFormatNumber(record.fields['1 - Sub Total in Opp Currency']) }}</span>
                </div>
              </div>

              <!-- Discount -->
              <div v-if="record.fields['Disc %']" class="d-flex text-end mb-2 small">
                <div class="col-1 ms-auto">
                  Discount
                </div>

                <div class="col-2">
                  <span class="ms-5">{{ mixinFormatNumber(record.fields['Disc %']) }}%</span>
                </div>
              </div>

              <!-- VAT -->
              <div v-if="record.fields['3 - VAT in Opp Currency']" class="d-flex text-end mb-2 small">
                <div class="col-1 ms-auto">
                  VAT (22%)
                </div>

                <div class="col-2">
                  <span class="ms-5">{{ record.fields['Currency Lookup'] ? getCurrencySymbol[record.fields['Currency Lookup']] : '' }}{{ mixinFormatNumber(record.fields['3 - VAT in Opp Currency']) }}</span>
                </div>
              </div>

              <!-- <div class="d-flex text-end mb-2 border-top border-bottom pt-2 pb-2">
                <div class="col-5 ms-auto">
                  <div class="form-check mb-0">
                    <input class="form-check-input" type="checkbox" id="addRevision">
                    <label class="form-check-label" for="addRevision"><span class="fw-semibold">+ Click to add Specialized Revision (Recommended)</span> <i class="bi bi-question-circle-fill" data-bs-toggle="tooltip" data-bs-placement="top" title="Add Specialized Revision by a second professional independent translator. Recommended for high-quality translation for publication purposes. This option is the most used by all our clients."></i></label>
                  </div>
                </div>

                <div class="col-2">
                  €123
                </div>
              </div> -->

              <!-- Total -->
              <div class="d-flex mt-4 text-end fs-5 fw-semibold">
                <div class="col-1 ms-auto">
                  <span>Total</span>
                </div>

                <div class="col-2">
                  <span class="ms-5">{{ record.fields['Currency Lookup'] ? getCurrencySymbol[record.fields['Currency Lookup']] : '' }}{{ mixinFormatNumber(record.fields['4 - Total in Opp Currency']) }}</span>
                </div>
              </div>
            </div>

            <!-- Confirm Order -->
            <div class="mt-4 mb-3 me-2 text-end hide" style="border: 1px solid transparent;">
              <div class="text-end ms-auto">
                <button v-if="!isConfirmButtonDisabled" class="btn btn-lg btn-primary ms-2"
                  @click.prevent="confirmOrder()"
                  :disabled="isUpdating('updatingOpp')">
                  <div v-if="isUpdating('updatingOpp')">
                    <div class="spinner-border spinner-border-sm" role="status"></div>
                  </div>
                  <div v-else>
                    Confirm Order
                  </div>
                </button>
                <button v-else class="btn btn-lg btn-primary ms-2" disabled>
                  Order Confirmed
                </button>

                <p class="mt-2 mb-0 small">Quality 100% guaranteed, pay by invoice in 30 days</p>
              </div>
            </div>

            <!-- VAT Note -->
            <div class="mt-4 pt-3">
              <div class="text-muted small">
                <p class="small mb-0">
                  <template v-if="record.fields['3 - VAT in Opp Currency']">
                    * The price shown includes VAT (22%). VAT shall apply to private customers residing in the European Union, to EU-based companies which do not have a VAT number, as well as to Estonian private customers and companies.
                  </template>
                  <template v-else>
                    * The price shown doesn't include VAT (22%). VAT shall apply to private customers residing in the European Union, to EU-based companies which do not have a VAT number, as well as to Estonian private customers and companies.
                  </template>
                </p>
                <p class="small mb-0 hide">** By clicking in "Confirm Order", you agree to our <a href="https://languageburo.com/terms-of-sale" class="text-muted text-decoration-none hover-blue" target="_blank">Terms of Sale</a>.</p>
              </div>
            </div>

            <!-- Section: Why -->
            <div class="p-5 pb-0 mt-5 mb-3 border-top hide">
              <div class="container">
                <h2 class="display-6 fw-semibold text-center mb-4 pb-4">Why work with Language Buró?</h2>

                <div class="row mb-4">
                  <div class="col">
                    <div class="text-center small">
                      <div class="icon d-inline-flex align-items-center justify-content-center text-primary fs-4 mb-3">
                        <i class="bi bi-check-lg"></i>
                      </div>

                      <h5 class="fw-semibold">Quality fully guaranteed</h5>
                      <p>Most of our orders are accepted without any further revisions. We work exclusively with professional native-speaking translators.</p>
                    </div>
                  </div>

                  <div class="col">
                    <div class="text-center small">
                      <div class="icon d-inline-flex align-items-center justify-content-center text-primary fs-4 mb-3">
                        <i class="bi bi-speedometer2"></i>
                      </div>

                      <h5 class="fw-semibold">Delivery starting in 24 hours</h5>
                      <p>We understand the urgency in getting your files translated. We start all orders within 60 minutes and deliver as soon as possible.</p>
                    </div>
                  </div>

                  <div class="col">
                    <div class="text-center small">
                      <div class="icon d-inline-flex align-items-center justify-content-center text-primary fs-4 mb-3">
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>

                      <h5 class="fw-semibold">Competitive pricing</h5>
                      <p>Thanks to advanced language technology and streamlined processes, we offer our services at a fraction of cost compared to traditional translation agencies.</p>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-4">
                    <div class="text-center small">
                      <div class="icon d-inline-flex align-items-center justify-content-center text-primary fs-4 mb-3">
                        <i class="bi bi-shield-check"></i>
                      </div>

                      <h5 class="fw-semibold">Secure & confidential</h5>
                      <p>Your files are always treated with the utmost confidentiality. All communications between you and our team are kept private.</p>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="text-center small">
                      <div class="icon d-inline-flex align-items-center justify-content-center text-primary fs-4 mb-3">
                        <i class="bi bi-receipt"></i>
                      </div>

                      <h5 class="fw-semibold">Pay by invoice in 30 days</h5>
                      <p>Our 30-day invoice terms are designed to align with your company’s accounting cycles, simplifying financial processes. Payments are accepted in EUR, USD, or GBP.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Section: Testimonials -->
            <div class="p-5 pb-0 mt-5 mb-3 border-top hide">
              <div class="container">
                <h2 class="display-6 fw-semibold text-center mb-3 pb-4">In the words of our clients</h2>

                <div class="trustpilot-widget mt-4 mb-4">
                  <div id="trustpilotCarousel" class="carousel carousel-dark slide" data-bs-ride="carousel">
                    <div class="carousel-inner quote-details">
                      <div class="carousel-item active">
                        <div class="row">
                          <div class="col">
                            <div class="border rounded p-4 bg-white small">
                              <div>
                                <img src="@/assets/images/trustpilot-rating.png" alt="Trustpilot Rating">
                              </div>

                              <div>
                                <h5 class="fw-semibold mt-3">Everyone was extremely responsive and…</h5>
                                <p>Everyone was extremely responsive and proactive in managing our project. We will be using their services going forward.</p>

                                <div class="text-muted small mt-3">
                                  Stephanie Milburn
                                  <span class="ms-3 text-success">
                                    <i class="bi bi-check-circle-fill"></i>
                                    Verified customer
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col">
                            <div class="border rounded p-4 bg-white small">
                              <div>
                                <img src="@/assets/images/trustpilot-rating.png" alt="Trustpilot Rating">
                              </div>

                              <div>
                                <h5 class="fw-semibold mt-3">Impressive results</h5>
                                <p>I needed 2 language translations for a Strategy Document which had already been designed in English. I received a quick quote to include inputting the translated copy into the design. I must say I was thoroughly impressed on all fronts from speed, project management and of course cost. I’ll definitely be back.</p>

                                <div class="text-muted small mt-3">
                                  Nick Redeyoff
                                  <span class="ms-3 text-success">
                                    <i class="bi bi-check-circle-fill"></i>
                                    Verified customer
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col">
                            <div class="border rounded p-4 bg-white small">
                              <div>
                                <img src="@/assets/images/trustpilot-rating.png" alt="Trustpilot Rating">
                              </div>

                              <div>
                                <h5 class="fw-semibold mt-3">Happy customer</h5>
                                <p>This is the 3rd time we use Language Buró for translations and we're very happy with the level of professionalism of the team and with the quality of the work. We highly recommend them!</p>

                                <div class="text-muted small mt-3">
                                  IPPF European Network
                                  <span class="ms-3 text-success">
                                    <i class="bi bi-check-circle-fill"></i>
                                    Verified customer
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="carousel-item">
                        <div class="row">
                          <div class="col">
                            <div class="border rounded p-4 bg-white small">
                              <div>
                                <img src="@/assets/images/trustpilot-rating.png" alt="Trustpilot Rating">
                              </div>

                              <div>
                                <h5 class="fw-semibold mt-3">Great service!</h5>
                                <p>This is a great company to work with. We needed our Help Guides translated into 4 languages, the service was super quick. Really pleased with the quality of the work delivered. They are a friendly and helpful team, we will definitely use them again in the future!</p>

                                <div class="text-muted small mt-3">
                                  Barend Faber
                                  <span class="ms-3 text-success">
                                    <i class="bi bi-check-circle-fill"></i>
                                    Verified customer
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col">
                            <div class="border rounded p-4 bg-white small">
                              <div>
                                <img src="@/assets/images/trustpilot-rating.png" alt="Trustpilot Rating">
                              </div>

                              <div>
                                <h5 class="fw-semibold mt-3">We have worked with Language Buró to produce global translations to support our product portfolio</h5>
                                <p>We have worked with Language Buró on a series of projects. We are delighted with their services from rapid response from quotation to delivery of proof read materials. Their demonstration of expertise with our translation demands and aggressive timelines has made the entire experience faultless. Language Buro' deliver world class services and we highly recommend the company.</p>

                                <div class="text-muted small mt-3">
                                  Samantha Sanders-Mansfield
                                  <span class="ms-3 text-success">
                                    <i class="bi bi-check-circle-fill"></i>
                                    Verified customer
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col">
                            <div class="border rounded p-4 bg-white small">
                              <div>
                                <img src="@/assets/images/trustpilot-rating.png" alt="Trustpilot Rating">
                              </div>

                              <div>
                                <h5 class="fw-semibold mt-3">Great experience</h5>
                                <p>It was great wo work with Language Buró. Perfect service, perfect translation. Fast and very convenient! Thank you! We will definitely use the service again.</p>

                                <div class="text-muted small mt-3">
                                  Nataša Zorica
                                  <span class="ms-3 text-success">
                                    <i class="bi bi-check-circle-fill"></i>
                                    Verified customer
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="carousel-item">
                        <div class="row">
                          <div class="col">
                            <div class="border rounded p-4 bg-white small">
                              <div>
                                <img src="@/assets/images/trustpilot-rating.png" alt="Trustpilot Rating">
                              </div>

                              <div>
                                <h5 class="fw-semibold mt-3">Swift and convenient</h5>
                                <p>The system is very covenient and the service super quick. We are always pleased with the translations and can highly recommend working with Language Buró.</p>

                                <div class="text-muted small mt-3">
                                  Azemos Partner AG
                                  <span class="ms-3 text-success">
                                    <i class="bi bi-check-circle-fill"></i>
                                    Verified customer
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col">
                            <div class="border rounded p-4 bg-white small">
                              <div>
                                <img src="@/assets/images/trustpilot-rating.png" alt="Trustpilot Rating">
                              </div>

                              <div>
                                <h5 class="fw-semibold mt-3">The result exceeded our expectations</h5>
                                <p>The result exceeded our expectations. Fast, reliable and very good quality (English-> Chinese). The team is very cooperative and responsive to our requests. Excellent price-quality ratio. Thank you very much!</p>

                                <div class="text-muted small mt-3">
                                  Michaela B.
                                  <span class="ms-3 text-success">
                                    <i class="bi bi-check-circle-fill"></i>
                                    Verified customer
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col">
                            <div class="border rounded p-4 bg-white small">
                              <div>
                                <img src="@/assets/images/trustpilot-rating.png" alt="Trustpilot Rating">
                              </div>

                              <div>
                                <h5 class="fw-semibold mt-3">Good translation service!</h5>
                                <p>The service provided was very quick and the translation were of good quality according to the native speakers who reviewed them. We will work with them again!</p>

                                <div class="text-muted small mt-3">
                                  Sara Giovannini
                                  <span class="ms-3 text-success">
                                    <i class="bi bi-check-circle-fill"></i>
                                    Verified customer
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col trustpilot-widget-small d-flex align-items-center justify-content-center">
                  <span class="me-3 fs-4 fw-semibold" style="padding-top: 5px;">Excellent</span>
                  <img src="@/assets/images/trustpilot-rating.png" alt="Trustpilot Rating" class="trustpilot-rating me-2" />
                  <img src="@/assets/images/trustpilot-logo.svg" alt="Trustpilot" class="trustpilot-logo" />
                </div>

                <p class="text-center text-muted small mt-4 pt-4">Read more testimonials from verified customers in our <a href="https://www.trustpilot.com/review/languageburo.com" target="_blank">Trustpilot page &rsaquo;</a></p>
              </div>
            </div>

            <!-- Section: Logos -->
            <div class="p-5 pb-0 mt-5 mb-3 border-top hide">
              <div class="container">
                <h2 class="display-6 fw-semibold text-center mb-4">We are trusted by leading international organizations</h2>
                <p class="text-center mb-4 pb-4">Language Buró is a leading provider of professional translation solutions for organizations working in many industries, including the Life Sciences, Banking & Finance, Legal & IP Law and International Development sectors.</p>

                <div class="d-flex justify-content-center align-items-center">
                  <div class="text-center">
                    <!-- <img src="@/assets/images/amazon.svg" alt="Amazon" class="img-fluid mx-5 mb-4" style="max-height: 50px;">
                    <img src="@/assets/images/dhl.png" alt="DHL" class="img-fluid mx-5 mb-4" style="max-height: 30px;">
                    <img src="@/assets/images/siemens.png" alt="Siemens" class="img-fluid mx-5 mb-4" style="max-height: 50px;"> -->
                    <!-- <img src="@/assets/images/wd-40.svg" alt="WD-40" class="img-fluid mx-5 mb-4" style="max-height: 120px;"> -->
                    <img src="@/assets/images/msf.png" alt="MSF" class="img-fluid mx-5 mb-4" style="max-height: 80px;">
                    <img src="@/assets/images/fsc.png" alt="FSC" class="img-fluid mx-5 mb-4" style="max-height: 80px;">
                    <img src="@/assets/images/wwf.svg" alt="WWF" class="img-fluid mx-5 mb-4" style="max-height: 100px;">
                    <img src="@/assets/images/unf.png" alt="UNF" class="img-fluid mx-5 mb-4" style="max-height: 50px;">
                    <img src="@/assets/images/goethe.svg" alt="UNF" class="img-fluid mx-5 mb-4" style="max-height: 90px;">
                    <img src="@/assets/images/bayer.svg" alt="MSF" class="img-fluid mx-5 mb-4" style="max-height: 90px;">
                    <img src="@/assets/images/aji.svg" alt="MSF" class="img-fluid mx-5" style="max-height: 100px;">
                  </div>
                </div>
              </div>
            </div>

            <!-- Section: Partner message -->
            <div class="p-5 pb-0 mt-5 mb-3 border-top hide">
              <div class="container">
                <h2 class="display-6 fw-semibold text-center mb-4">Our commitment to high-quality translation services</h2>

                <p class="text-center mb-4">At Language Buró, our top priority is delivering exceptional quality in our services. We pride ourselves on our meticulous attention to detail and our strong focus on customer satisfaction. I'm confident that you will appreciate our dedication. Do you have any questions? I will be happy to answer them personally! You can email me directly at <a href="mailto:valentina@languageburo.com">valentina@languageburo.com</a>. Additionally, feel free to download our brochure, available in <a href="https://www.dropbox.com/s/izl4mw5uzfrt0dn/language-buro-brief-english.pdf?dl=0">English</a> and <a href="https://www.dropbox.com/s/aie31aotnqy4ih2/language-buro-brief-german.pdf?dl=0">German</a>.</p>
                <p class="text-center mb-4 pb-4">We look forward to working with you,</p>

                <div class="team-member d-flex align-items-center justify-content-center text-center">
                  <img src="https://languageburo.com/content/snippet-team/valentina.jpg" alt="Valentina Farallo" class="team-member-photo">

                  <ul class="list-unstyled ms-3 mb-0">
                    <li><img src="@/assets/images/signature-2.png" alt="Signature" style="max-height: 60px;"></li>
                    <li>Valentina Farallo, Partner</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="bg-light p-5 pt-2 small text-center text-muted">
        Offer prepared by Language Buró OÜ. <a href="https://languageburo.com/terms-of-sale" class="text-muted text-decoration-none hover-blue" target="_blank">Terms of Sale</a>. <a href="https://languageburo.com/privacy-policy" class="text-muted text-decoration-none hover-blue" target="_blank">Privacy Policy</a>. <a href="https://languageburo.com/translation-quality" class="text-muted text-decoration-none hover-blue" target="_blank">Quality Guarantee</a>.
      </div>
    </div>
  </div>

  <div v-else>
    <div class="d-flex justify-content-center p-5">
      <div class="spinner-border text-secondary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';
import { sendEmail } from '@/services/mailgun.js';
import { sendMessageToSlack } from '@/services/slack.js';
import html2pdf from 'html2pdf.js';

export default {
  name: 'QuoteDetails',

  emits: [
    'showAlert',
    'showError',
  ],

  components: { },

  data() {
    return {
      isDataFetched: false,

      totalPages: 1, // TODO: This gives a warning, but not sure how to fix. It doesn't alter the functionality though
      currentPage: 1,
      pageSize: 500,

      recordId: this.$route.params.id,

      record: { },
      jobs: { },

      tableName: 'Opportunities',
      stateVariable: 'opp',
      updatingStateVariable: 'updatingOpp',

      oppConfirmedStatus: 'Quote: Won',

      confirmOrderVisible: false,

      user: this.$clerk.user,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',
      'getCurrencySymbol',
    ]),

    showConfirmOrderButton() {
      return this.confirmOrderVisible && !this.isConfirmButtonDisabled;
    },

    isQuoteWon() {
      return this.record.fields['Status'] === 'Quote: Won';
    },

    isOrderInProgress() {
      return this.record.fields['Status'] === 'Order: In Progress';
    },

    isOrderCompleted() {
      return this.record.fields['Status'] === 'Order: Completed';
    },

    isConfirmButtonDisabled() {
      return this.isQuoteWon || this.isOrderInProgress || this.isOrderCompleted;
    },

    company() {
      return this.record.fields['Company Lookup'] ? this.record.fields['Company Lookup'].join(', ') : '';
    },

    contact() {
      return this.record.fields['Contact Lookup'] ? this.record.fields['Contact Lookup'].join(', ') : '';
    },

    contactEmail() {
      return this.record.fields['Email (from "Contact" table)'] ? this.record.fields['Email (from "Contact" table)'].join(', ') : '';
    },

    quoteNumber() {
      return this.record.fields['Quote No.'];
    },

    quoteUrl() {
      return `https://app.languageburo.com/quotes/${this.record.fields['Opp ID']}`;
    },

    oppUrl() {
      return `https://app.languageburo.com/opps/${this.record.fields['Opp ID']}`;
    },

    createdBy() {
      return this.record.fields['Created By Lookup'] ? this.record.fields['Created By Lookup'].join(', ') : '';
    },

    createdByEmail() {
      return this.record.fields['Created By Email'] ? this.record.fields['Created By Email'].join(', ') : '';
    },

    currency() {
      return this.record.fields['Currency Lookup'] ? this.getCurrencySymbol[this.record.fields['Currency Lookup']] : '';
    },

    value() {
      return this.mixinFormatNumber(this.record.fields['4 - Total in Opp Currency']);
    },

    privateNotes() {
      return this.record.fields['Private Notes'];
    },

    hasDiscounts() {
      return this.tableRows.some(row => row.discount > 0);
    },

    tableRows() {
      return this.jobs.map(row => {
        return {
          service: row.fields['Service ID Lookup'] ? row.fields['Service ID Lookup'].join(', ') : '',
          title: row.fields['Title'],
          languagePair: row.fields['Language Pair Lookup'] ? row.fields['Language Pair Lookup'].join(', ') : '',
          oppCurrency: row.fields['Opp Currency'] ? row.fields['Opp Currency'].join(', ') : '',
          unit: row.fields['Unit ID Lookup'] ? row.fields['Unit ID Lookup'].join(', ') : '',
          units: row.fields['Units'],
          unitPrice: row.fields['Unit Price'],
          subTotal: row.fields['1 - Sub Total in Opp Currency'],
          discount: row.fields['Disc %'],
          total: row.fields['3 - Total in Opp Currency'],
        };
      });
    },
  },

  watch: { },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    handleScroll() {
      this.confirmOrderVisible = window.scrollY > 2000;
    },

    saveAsPDF() {
      // We create a clone of the container so the .hide elements don't flick
      // (very hacky)

      // Create a detached element
      const element = document.querySelector('.container');
      const clone = element.cloneNode(true);

      // Add the class to hide elements
      clone.querySelectorAll('.hide').forEach(el => el.classList.add('hide-for-pdf'));

      // Create a detached container
      const detachedContainer = document.createElement('div');
      detachedContainer.style.position = 'absolute';
      detachedContainer.style.left = '-9999px';
      detachedContainer.appendChild(clone);

      // Append the detached container to the body
      document.body.appendChild(detachedContainer);

      const options = {
        margin:       1,
        filename:     `Quote_${this.quoteNumber}_Language_Buró.pdf`,
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'cm', format: 'a4', orientation: 'landscape' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      };

      html2pdf().from(clone).set(options).save().then(() => {
        // Remove the detached container after generating the PDF
        document.body.removeChild(detachedContainer);
      });
     },

    createUpdatedFields() {
      return {
        'Status': 'Quote: Won',
      };
    },

    async updateRecord() {
      try {
        await this.$store.dispatch('updateRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          updatedFields: this.createUpdatedFields(),
          updatingStateVariable: this.updatingStateVariable,
        });

      } catch (error) {
        console.error(error);
      }
    },

    async sendEmails(subjectClientEmail, contentClientEmail, subjectInternalEmail, contentInternalEmail) {
      try {
        // Send email to client
        await sendEmail({
          from: 'Language Buró <no-reply@languageburo.com>',
          to: this.contactEmail,
          subject: subjectClientEmail,
          content: contentClientEmail,
        });

        // Send email internally
        await sendEmail({
          from: '🤖 Cashbot <cashbot@languageburo.com>',
          to: this.createdByEmail,
          subject: subjectInternalEmail,
          content: contentInternalEmail,
        });

        await sendEmail({
          from: '🤖 Cashbot <cashbot@languageburo.com>',
          to: 'quotes@languageburo.com',
          subject: subjectInternalEmail,
          content: contentInternalEmail,
        });

      } catch (error) {
        console.error('Failed to send emails', error);
      }
    },

    generateEmailContent() {
      const subjectClientEmail = `Thank you for accepting our Quote ${this.quoteNumber}`;

      const contentClientEmail = `
        <p>Dear ${this.contact},</p>
        <p>Your confirmation for Quote ${this.quoteNumber} has been received.</p>
        <p>You can review the quote details here: <a href="${this.quoteUrl}">View Quote</a></p>
        <p>Your Project Manager will soon contact you with an update. Thank you for choosing our services!</p>
        <p>Please do not reply to this message as this is an automated email.</p>
        <p>Language Buró<br>
        Contact: ${this.createdBy} (<a href="mailto:${this.createdByEmail}">${this.createdByEmail}</a>)</p>
      `;

      const subjectInternalEmail = `💰 Quote ${this.quoteNumber} (${this.currency}${this.value}) from ${this.company} (${this.contactEmail}) confirmed!`;

      const contentInternalEmail = `
        <p>Great!</p>
        <p>Quote ${this.quoteNumber} (${this.currency}${this.value}) has been confirmed by the client.</p>
        <p>${this.oppUrl}</p>
        <p>Well done,</p>
        <p>🤖 Cashbot</p>
      `;

      return { subjectClientEmail, contentClientEmail, subjectInternalEmail, contentInternalEmail };
    },

    async confirmOrder() {
      try {

        // if the server fails, the updateRecord and notification will still continue
        // so we need to find a way to filter all opps by Opp Won status? just in case?

        // Update the opp
        await this.updateRecord();

        // Update the local state to reflect the new opp status
        this.record.fields['Status'] = this.oppConfirmedStatus;
        window.scrollTo(0, 0);

        // Send a message to Slack
        await sendMessageToSlack({
          company: this.company,
          contactEmail: this.contactEmail,
          quoteNumber: this.quoteNumber,
          oppUrl: this.oppUrl,
          createdBy: this.createdBy,
          currency: this.currency,
          value: this.value,
          privateNotes: this.privateNotes,
          messageType: 'confirmed',
        });

        // Send emails
        const { subjectClientEmail, contentClientEmail, subjectInternalEmail, contentInternalEmail } = this.generateEmailContent();
        await this.sendEmails(subjectClientEmail, contentClientEmail, subjectInternalEmail, contentInternalEmail);

      } catch (error) {
        this.showError('There was an issue while processing order confirmation. Please refresh the page and try again.');
        console.error(error);

      }
    },

    async fetchRelatedRecords() {
      this.isDataFetched = false;

      try {
        this.jobs = await this.$store.dispatch('fetchRelatedRecords', {
          parentTableName: 'Opportunities',
          childTableName: 'Jobs',
          recordId: this.recordId,
          relatedFieldName: 'Jobs ID',
          stateVariable: 'oppJobs',
          fetchingStateVariable: 'fetchingOppJobs',
        });

        this.isDataFetched = true;

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error fetching related records: ${error.message}`);
        console.error(error);

      }
    },

    async fetchRecord() {
      try {
        this.record = await this.$store.dispatch('fetchRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          stateVariable: this.stateVariable,
          fetchingStateVariable: this.fetchingStateVariable,
        });

        this.isDataFetched = true;

      } catch (error) {
        this.$emit('showError', error.message);
        console.error(error);
      }
    },
  },

  async mounted() {
    await this.fetchRecord();
    await this.fetchRelatedRecords();

    const title = `Quote ${this.quoteNumber} (${this.company}, ${this.contact})`;
    document.title = title;

    window.addEventListener('scroll', this.handleScroll);

    // Initialize tooltips after ensuring the DOM is fully updated
    [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipTriggerEl => {
      new bootstrap.Tooltip(tooltipTriggerEl);
    });
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style>
  .hide-for-pdf {
    display: none !important;
  }

  /* Hide the mobile message by default */
  .mobile-message {
    display: none;
  }

  /* Show the mobile message and hide the main content on small screens */
  @media (max-width: 767px) {
    .mobile-message {
      display: block;
    }

    .main-content {
      display: none;
    }
  }
</style>