<!-- SearchResults.vue -->

<template>

  <!-- Navigation -->
  <div class="row mb-3">
    <!-- Tabs -->
    <div class="col">
      <ul class="nav nav-tabs" id="tabNav" v-show="tabsVisible">
        <li class="nav-item small">
          <a class="nav-link active" data-bs-toggle="tab" href="#opps">Opportunities</a>
        </li>

        <li class="nav-item small">
          <a class="nav-link" data-bs-toggle="tab" href="#companies">Companies</a>
        </li>

        <li class="nav-item small">
          <a class="nav-link" data-bs-toggle="tab" href="#contacts">Contacts</a>
        </li>

        <li class="nav-item small">
          <a class="nav-link" data-bs-toggle="tab" href="#jobs">Jobs</a>
        </li>
      </ul>
    </div>
  </div>

  <!-- Tabs -->
  <div class="tab-content">
    <!-- Opps -->
    <div id="opps" class="tab-pane show active">
      <ReusableOpportunitiesTable :records="opps" @showAlert="showAlert" @showError="showError" :showFilters="false" />
    </div>

    <!-- Companies -->
    <div id="companies" class="tab-pane">
      <ReusableCompaniesTable :records="companies" @showAlert="showAlert" @showError="showError" :showFilters="false" />
    </div>

    <!-- Contacts -->
    <div id="contacts" class="tab-pane">
      <ReusableContactsTable :records="contacts" @showAlert="showAlert" @showError="showError" :showFilters="false" />
    </div>

    <!-- Jobs -->
    <div id="jobs" class="tab-pane">
      <ReusableJobsTable :records="jobs" @showAlert="showAlert" @showError="showError" :showFilters="false" />
    </div>
  </div>

</template>

<script>
/* global bootstrap */
import { mapMutations } from 'vuex';
import ReusableOpportunitiesTable from '@/components/ReusableOpportunitiesTable.vue';
import ReusableCompaniesTable from '@/components/ReusableCompaniesTable.vue';
import ReusableContactsTable from '@/components/ReusableContactsTable.vue';
import ReusableJobsTable from '@/components/ReusableJobsTable.vue';

export default {
  name: 'SearchResults',
  props: {
    opps: {
      type: Array,
      required: true,
    },

    companies: {
      type: Array,
      required: true,
    },

    contacts: {
      type: Array,
      required: true,
    },

    jobs: {
      type: Array,
      required: true,
    },
  },

  components: {
    ReusableOpportunitiesTable,
    ReusableCompaniesTable,
    ReusableContactsTable,
    ReusableJobsTable,
  },

  data() {
    return {
      tabsVisible: false,
    };
  },

  computed: { },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    ...mapMutations([
      'resetSearchResultsOpps',
      'resetSearchResultsCompanies',
      'resetSearchResultsContacts',
      'resetSearchResultsJobs',
    ]),

    onModalHidden() {
      this.resetSearchResultsOpps();
      this.resetSearchResultsCompanies();
      this.resetSearchResultsContacts();
      this.resetSearchResultsJobs();
    },
  },

  mounted() {
    const modalElement = document.getElementById('searchResultsModal');
    modalElement.addEventListener('hidden.bs.modal', this.onModalHidden);

    this.$nextTick(() => {
      let firstTab = new bootstrap.Tab(document.querySelector('#tabNav .nav-item:first-child .nav-link'));
      firstTab.show();
      this.tabsVisible = true;
    });
  },

  beforeUnmount() {
    const modalElement = document.getElementById('searchResultsModal');
    modalElement.removeEventListener('hidden.bs.modal', this.onModalHidden);
  },
};
</script>