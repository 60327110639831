<!-- CreateVendorModal.vue -->

<template>

  <div class="modal" id="createVendorModal" tabindex="-1" aria-labelledby="createVendorModalLabel" aria-hidden="true" ref="createVendorModal">
    <div class="modal-dialog">
      <div class="modal-content shadow shadow-lg">

        <div class="modal-header border-bottom">
          <h5 class="modal-title">Create New Vendor</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div v-if="createdVendor" class="alert alert-success mt-3">
          <i class="bi bi-check-circle-fill me-1"></i>
          Vendor "{{ createdVendor.fields['Full Name'] }}" created. <a class="alert-link" :href="'/vendors/' + createdVendor.id" target="_blank">Open this vendor profile</a>.
        </div>

        <div class="modal-body" v-if="!createdVendor">
          <!-- Added By / Created By -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Created By
            </div>

            <div class="col-8">
              <v-select v-if="!isFetching('fetchingTeam')" v-model="newVendor.addedBy" :options="allTeamOptions" placeholder="Select..." :clearable="false">
                <template v-slot:open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                  </span>
                </template>
              </v-select>

              <div v-else class="d-inline-block">
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Type -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Type
            </div>

            <div class="col-8">
              <v-select v-model="newVendor.type" :options="['Agency', 'Individual']" placeholder="Select..." :clearable="false">
                <template v-slot:open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                  </span>
                </template>
              </v-select>
            </div>
          </div>

          <!-- Vendor Name -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Vendor Name
            </div>

            <div class="col-8">
              <input class="form-control" type="text" v-model="newVendor.fullName">
            </div>
          </div>

          <!-- Email -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Email
            </div>

            <div class="col-8">
              <input class="form-control" type="email" v-model="newVendor.email">
            </div>
          </div>
        </div>

        <div class="modal-footer" v-if="!createdVendor">
          <span v-if="isCreating('creatingVendor')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-primary" @click="createVendor" :disabled="isCreating('creatingVendor') || isSubmitting">Create Vendor</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CreateVendorModal',
  emits: [
    'showAlert',
    'showError',
  ],

  data() {
    return {
      newVendor: this.defaultNewVendor(),
      createdVendor: null,
      isSubmitting: false,
    };
  },

  computed: {
    ...mapGetters([
      'allTeamOptions',

      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  methods: {
    defaultNewVendor() {
      return {
        addedBy: '',
        type: '',
        fullName: '',
        email: '',
      };
    },

    resetForm() {
      this.newVendor = this.defaultNewVendor();
    },

    resetCreatedVendor() {
      this.createdVendor = null;
    },

    validateFields() {
      let errors = [];

      if (!this.newVendor.addedBy) { errors.push('"Created By" is required.'); }
      if (!this.newVendor.type) { errors.push('"Type" is required.'); }
      if (!this.newVendor.fullName) { errors.push('"Vendor Name" is required.'); }
      if (!this.newVendor.email) { errors.push('"Email" is required.'); }

      return errors;
    },

    formatData() {
      const newVendorFullName = this.newVendor.fullName.trim();
      const newVendorEmail = this.newVendor.email.trim();

      const formattedData = {
        'Added By': [this.newVendor.addedBy.value],
        'Type': this.newVendor.type,
        'Full Name': newVendorFullName,
        'Email': newVendorEmail,

        // mandatory fields
        'Blacklisted?': 'Yes',
        'Self Invoicing?': 'Other',
        'Currency': 'Other',
        'Country': ['recosaqYwiti9cIIk'],
        'Native Languages': ['rechGm307PtKlv3cM'],
      };

      // Adds date
      const createdOn = new Date().toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });

      formattedData['Created On'] = createdOn;

      return formattedData;
    },

    async createVendor() {
      this.isSubmitting = true;

      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        this.isSubmitting = false;
        return;
      }

      const formattedData = this.formatData();

      try {
        const createdVendor = await this.$store.dispatch('createRecord', {
          tableName: 'Vendors',
          newRecord: formattedData,
          creatingStateVariable: 'creatingVendor',
        });

        // for use in the view and as a flag
        this.createdVendor = createdVendor.records[0];
        this.resetForm();

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error creating vendor: ' + error.message);
        console.error('Error creating vendor: ', error);

      } finally {
        this.isSubmitting = false;
      }
    },
  },

  mounted() {
    // Resets modal on close
    // we use this.resetForm() and not this.resetForm because we are calling 2 methods
    this.$refs.createVendorModal.addEventListener('hidden.bs.modal', () => {
      this.resetForm();
      this.resetCreatedVendor();
    });
  }
}
</script>