<!-- createServiceModal.vue -->

<template>

  <div class="modal" id="createServiceModal" tabindex="-1" aria-labelledby="createServiceModalLabel" aria-hidden="true" ref="createServiceModal">
    <div class="modal-dialog">
      <div class="modal-content shadow shadow-lg">

        <div class="modal-header border-bottom">
          <h5 class="modal-title">Create New Service</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div v-if="createdService" class="alert alert-success mt-3">
          <i class="bi bi-check-circle-fill me-1"></i>
          Service "{{ createdService.fields['Name'] }}" created. <a class="alert-link" :href="'/settings/services/' + createdService.id" target="_blank">Open this service profile</a>.
        </div>

        <div class="modal-body" v-if="!createdService">
          <!-- Name -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Name
            </div>

            <div class="col-8">
              <input class="form-control" type="text" v-model="newService.name">
            </div>
          </div>
        </div>

        <div class="modal-footer" v-if="!createdService">
          <span v-if="isCreating('creatingService')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-primary" @click="createService" :disabled="isCreating('creatingService') || isSubmitting">Create Service</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';

export default {
  name: 'createServiceModal',
  emits: [
    'showAlert',
    'showError',
  ],

  data() {
    return {
      newService: this.defaultNewService(),
      createdService: null,
      isSubmitting: false,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  methods: {
    openModal() {
      if (!this.modalInstance) {
        const modalElement = this.$refs.createServiceModal;
        this.modalInstance = new bootstrap.Modal(modalElement);
      }
      this.modalInstance.show();
    },

    defaultNewService() {
      return {
        name: '',
      };
    },

    resetForm() {
      this.newService = this.defaultNewService();
    },

    resetCreatedService() {
      this.createdService = null;
    },

    validateFields() {
      let errors = [];

      if (!this.newService.name) { errors.push('"Name" is required.'); }

      return errors;
    },

    formatData() {
      const newServiceName = this.newService.name.trim();

      const formattedData = {
        'Name': newServiceName,
      };

      return formattedData;
    },

    async createService() {
      this.isSubmitting = true;

      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        this.isSubmitting = false;
        return;
      }

      const formattedData = this.formatData();

      try {
        const createdService = await this.$store.dispatch('createRecord', {
          tableName: 'Services',
          newRecord: formattedData,
          creatingStateVariable: 'creatingService',
        });

        // for use in the view and as a flag
        this.createdService = createdService.records[0];
        this.resetForm();

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error creating service: ' + error.message);
        console.error('Error creating service: ', error);

      } finally {
        this.isSubmitting = false;
      }
    },
  },

  mounted() {
    // Resets modal on close
    // we use this.resetForm() and not this.resetForm because we are calling 2 methods
    this.$refs.createServiceModal.addEventListener('hidden.bs.modal', () => {
      this.resetForm();
      this.resetCreatedService();
    });
  }
}
</script>