<!-- ReusableOpportunitiesTable.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance', 'VM'])">
    <div v-if="records.length > 0">
      <!-- Table filters and search -->
      <div class="filters mb-1 p-2 ps-0 pe-0">
        <div class="d-flex align-items-center">
          <!-- Filters -->
          <div v-if="showFilters" class="d-flex align-items-center">
            <!-- Filter: Order Start Date -->
            <div class="d-flex align-items-center">
              <div>
                <VueDatePicker
                  v-model="filters.dateRange.value"
                  id="dateRange"
                  range
                  :enable-time-picker="false"
                  :preset-dates="presetDates"
                  input-class-name="custom-date-picker"
                  hide-input-icon
                  format="dd MMM yyyy"
                  placeholder="Filter by Order Start Date"
                />
              </div>
            </div>
          </div>

          <!-- Showing text, Pagination and Search -->
          <div :class="['d-flex align-items-center', { 'ms-auto': showFilters }]">
            <!-- Showing text -->
            <div class="text-muted small me-3">{{ showingText }}</div>

            <!-- Pagination -->
            <VTPagination
              v-model:currentPage="currentPage"
              :totalPages="totalPages"
              :maxPageLinks="3"
              :boundaryLinks="false"
              class="me-2"
            >
              <template #next>
                <span class="prevent-select">Next</span>
              </template>

              <template #previous>
                <span class="prevent-select">Previous</span>
              </template>
            </VTPagination>

            <!-- Search -->
            <input v-model="filters.name.value" type="text" class="form-control form-control-sm" placeholder="Filter by Quote, Order, Client, Email, Created By, PM or Language Pairs" style="width: 500px;" />
          </div>
        </div>
      </div>

      <!-- Checked rows actions -->
      <div v-if="mixinUserInRoles(['Admin'])">
        <div v-if="selectedRows.length > 0" class="p-2 border-bottom">
          <span class="text-muted small">{{ selectedRows.length }} {{ selectedRows.length > 1 ? 'rows' : 'row' }} selected. Revenue: €{{ mixinFormatNumber(totalRevenueOfSelectedRows) }}, Profit: €{{ mixinFormatNumber(totalProfitOfSelectedRows) }}, Average Margin: {{ mixinFormatMargin(averageMarginOfSelectedRows) }}</span>
        </div>
      </div>

      <div class="table-responsive">
        <VTable
          :data="tableRows"
          :pageSize="pageSize"
          v-model:currentPage="currentPage"
          @totalPagesChanged="totalPages = $event"
          @stateChanged="handleStateChanged"
          :filters="filters"
          sortHeaderClass="sort-header"
          class="table custom-table border"
          selectionMode="multiple"
          :selectOnClick="false"
          selectedClass="bg-light"
        >
          <!-- Commented because of known issue with "select all" checkbox -->
          <!-- <template #head="{ allRowsSelected, toggleAllRows }"> -->
          <template #head="">
            <tr class="custom-row small">
              <!-- <th class="small"> -->
                <!-- Commented because of known issue with "select all" checkbox -->
                <!-- <input type="checkbox" class="form-check-input" :checked="allRowsSelected" @change="toggleAllRows" /> -->
              <!-- </th> -->
              <!-- <th class="small" style="min-width: 50px;"></th> -->
              <th v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="text-nowrap sticky-column small" style="min-width: 100px;"></th>
              <VTh sortKey="status" class="text-nowrap small" style="min-width: 180px;">Status</VTh>
              <VTh sortKey="quoteNumber" class="text-nowrap text-end small" style="left: 280px; min-width: 100px;">Opp</VTh>
              <VTh sortKey="orderId" class="text-nowrap text-end small" style="min-width: 80px;">Order</VTh>
              <VTh v-if="mixinUserInRoles(['Admin'])" sortKey="revenue" class="text-nowrap text-end small" style="min-width: 100px;">Revenue in €</VTh>
              <!-- <VTh v-if="mixinUserInRoles(['Admin'])" sortKey="profit" class="text-nowrap text-end small" style="min-width: 100px;">Profit in €</VTh> -->
              <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="margin" class="text-nowrap text-end small" style="min-width: 80px;">Margin</VTh>
              <VTh sortKey="currency" class="text-nowrap text-end small" style="">Currency</VTh>
              <VTh sortKey="company" class="text-nowrap small" style="min-width: 320px;">Client</VTh>
              <VTh defaultSort="desc" sortKey="createdOn" class="text-nowrap text-end small" style="">Created On</VTh>
              <VTh sortKey="createdBy" class="text-nowrap small" style="">Created By</VTh>
              <VTh sortKey="forwardedBy" class="text-nowrap small" style="">Forwarded By</VTh>
              <VTh sortKey="pm" class="text-nowrap small" style="">PM</VTh>
              <VTh sortKey="orderStartDate" class="text-nowrap text-end small" style="">Order Start Date</VTh>
              <VTh sortKey="orderDeliveryDate" class="text-nowrap text-end small" style="">Order Delivery Date</VTh>
              <th v-if="mixinUserInRoles(['Admin', 'PM', 'VM'])" class="text-nowrap small" style="min-width: 320px;">Vendors</th>
              <th class="text-nowrap small" style="min-width: 320px;">Language Pairs</th>
              <th class="text-nowrap small" style="min-width: 320px;">Title</th>
              <th class="text-nowrap small" style="min-width: 320px;">Private Notes</th>
              <th class="text-nowrap text-end small" style="">Invoice</th>
              <!-- <th class="text-nowrap small" style="">Delivery Confirmed?</th> -->
            </tr>
          </template>
          <template #body="{rows}">
            <VTr
                v-for="(row, index) in rows"
                :key="index"
                :row="row"
                v-slot="{ }"
                v-expandable
                class="custom-row small"
              >

              <!-- Checkbox -->
              <!-- <td class="align-middle">
                <input type="checkbox" class="form-check-input" :checked="isSelected" @change.stop="toggle" />
              </td> -->

              <!-- Index -->
              <!-- <td class="text-nowrap align-middle text-end text-muted small">
                {{ (currentPage - 1) * pageSize + index + 1 }}
              </td> -->

              <!-- Actions -->
              <td v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="text-nowrap align-middle sticky-column border-end" style="min-width: 100px;" @click.stop>
                <!-- Opens in new tab -->
                <a :href="`/opps/${row.id}`" target="_blank" class="btn btn-sm btn-outline-secondary"><i class="bi bi-box-arrow-up-right small"></i> Open</a>

                <!-- Opens in same tab -->
                <!-- <router-link :to="`/opps/${row.id}`" class="btn btn-sm btn-outline-secondary">
                  <i class="bi bi-box-arrow-up-right small"></i> Open
                </router-link> -->
              </td>

              <!-- Status -->
              <td class="text-nowrap align-middle" style="min-width: 180px;" @click.stop>
                <span :class="['badge custom-badge', mixinGetBadgeClass(row.status)]">
                  {{ row.status }}
                </span>
              </td>

              <!-- Quote Number -->
              <td class="text-nowrap align-middle text-end" style="min-width: 100px;" @click.stop>
                {{ row.quoteNumber }}
              </td>

              <!-- Order -->
              <td class="text-nowrap align-middle text-end" style="min-width: 80px;" @click.stop>
                {{ row.orderId }}
              </td>

              <!-- Revenue -->
              <td v-if="mixinUserInRoles(['Admin'])" class="text-nowrap align-middle text-end" style="min-width: 100px;" @click.stop>
                €{{ mixinFormatNumber(row.revenue)}}
              </td>

              <!-- Profit -->
              <!-- <td v-if="mixinUserInRoles(['Admin'])" class="text-nowrap align-middle text-end">
                €{{ mixinFormatNumber(row.profit) }}
              </td> -->

              <!-- Margin -->
              <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end" :class="mixinGetPercentageClass(row.margin)" @click.stop>
                {{ row.margin }}%
              </td>

              <!-- Currency -->
              <td class="text-nowrap align-middle text-end" style="" @click.stop>
                <span :class="['badge custom-badge', mixinGetCurrencyBadgeClass(row.currency)]">
                  {{ row.currency }}
                </span>
              </td>

              <!-- Client -->
              <td class="text-nowrap align-middle">
                <div class="text-truncate" style="max-width: 300px;">
                  <a :href="`/companies/${row.companyId}`" target="_blank" class="text-dark text-decoration-none hover-blue">{{ row.company }}</a>
                  <br>
                  <span class="text-muted small">
                    <a :href="`/contacts/${row.contactId}`" target="_blank" class="text-muted text-decoration-none hover-blue">
                      {{ row.contact }}
                    </a>
                  </span>
                </div>
              </td>

              <!-- Created On -->
              <td class="text-nowrap align-middle text-end" @click.stop>
                {{ mixinFormatDate(row.createdOn) }}
              </td>

              <!-- Created By -->
              <td class="text-nowrap align-middle small" @click.stop>
                <span class="user border p-0 pe-2 d-inline-block">
                  <img :src="row.createdByPhoto" class="rounded-circle user-picture-small"> {{ row.createdBy }}
                </span>
              </td>

              <!-- Forwarded By -->
              <td class="text-nowrap align-middle small" @click.stop>
                <span :class="row.forwardedBy === 'Unassigned' ? '' : 'user border p-0 pe-2 d-inline-block'">
                  <img :src="row.forwardedByPhoto ? row.forwardedByPhoto : ''" class="rounded-circle user-picture-small">
                  {{ row.forwardedBy }}
                </span>
              </td>

              <!-- PM -->
              <td class="text-nowrap align-middle small" @click.stop>
                <span :class="row.pm === 'Unassigned' ? '' : 'user border p-0 pe-2 d-inline-block'">
                  <img :src="row.pmPhoto ? row.pmPhoto : ''" class="rounded-circle user-picture-small">
                  {{ row.pm }}
                </span>
              </td>

              <!-- Order Start Date -->
              <td class="text-nowrap align-middle text-end" @click.stop>
                {{ mixinFormatDate(row.orderStartDate) }}
              </td>

              <!-- Order Delivery Date -->
              <td class="text-nowrap align-middle text-end" @click.stop>
                {{ mixinFormatDate(row.orderDeliveryDate) }}
              </td>

              <!-- Vendors -->
              <td v-if="mixinUserInRoles(['Admin', 'PM', 'VM'])" class="text-nowrap align-middle small">
                <div class="text-truncate" style="max-width: 300px;">
                  <span v-for="(vendor, index) in row.vendors" :key="index" class="vendor border p-1 ps-2 pe-2 me-1 mt-1 mb-1 d-inline-block">
                    <router-link :to="`/vendors/${vendor.id}`" target="_blank" class="text-dark text-decoration-none hover-blue">{{ vendor.name }}</router-link>
                  </span>
                </div>
              </td>

              <!-- Language Pairs -->
              <td class="text-wrap align-middle small">
                <div class="text-truncate" style="max-width: 300px;">
                  <span v-for="(pair, index) in row.languagePairs" :key="index" class="language-pair border p-1 ps-2 pe-2 me-1 mt-1 mb-1 d-inline-block">
                    {{ pair.replace('>', '›') }}
                  </span>
                </div>
               </td>

              <!-- Title -->
              <td class="text-nowrap align-middle">
                <div class="text-truncate" style="max-width: 300px;">
                  {{ row.title }}
                </div>
              </td>

              <!-- Private Notes -->
              <td class="text-nowrap align-middle">
                <div class="text-truncate" style="max-width: 300px;">
                  {{ row.privateNotes }}
                </div>
              </td>

              <!-- Invoice -->
              <td class="text-nowrap align-middle text-end" @click.stop>
                {{ row.invoice }}
              </td>

              <!-- Delivery Confirmed? -->
              <!-- <td class="text-nowrap align-middle">
                {{ row.deliveryConfirmed }}
              </td> -->
            </VTr>
            <tr v-if="mixinUserInRoles(['Admin'])" class="tfoot">
              <td colspan="4"></td>
              <td class="text-nowrap align-middle text-end text-muted small">€{{ mixinFormatNumber(totalFilteredRevenue) }}</td>
              <!-- <td class="text-nowrap align-middle text-end text-muted small">€{{ mixinFormatNumber(totalFilteredProfit) }}</td> -->
              <td class="text-nowrap align-middle text-end text-muted small">{{ mixinFormatMargin(averageFilteredMargin) }}</td>
            </tr>
          </template>
        </VTable>

        <div v-if="totalFilteredItems === 0" class="text-center text-muted p-3">
          <p class="mb-0">No results found based on the current filters.</p>
        </div>
      </div>
    </div>

    <div v-else class="text-center text-muted p-5">
      No opportunities found.
    </div>
  </div>

  <div v-else>
    <p class="p-4">You are not authorized to see this page.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { startOfMonth, endOfMonth, startOfYear, endOfYear, subMonths, subYears } from 'date-fns';

export default {
  name: 'ReusableOpportunitiesTable',

  emits: [
    'showAlert',
    'showError',
  ],

  props: {
    records: {
      type: Array,
      required: true,
    },

    showFilters: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      filters: {
        name: { value: '', keys: ['quoteNumber', 'orderId', 'company', 'contact', 'contactEmail', 'createdBy', 'pm', 'languagePairs'] },

        dateRange: { value: [] },
      },

      selectedRows: [],

      presetDates: [
        { label: 'This month', value: [startOfMonth(new Date()), endOfMonth(new Date())] },
        { label: 'Last month', value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
        { label: 'This year', value: [startOfYear(new Date()), endOfYear(new Date())] },
        { label: 'Last year', value: [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))] },
      ],

      totalPages: 1, // TODO: This gives a warning, but not sure how to fix. It doesn't alter the functionality though
      currentPage: 1,
      pageSize: 50,

      totalFilteredItems: 0,
      totalFilteredRevenue: 0,
      totalFilteredProfit: 0,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',
    ]),

    averageFilteredMargin() {
      if (this.totalFilteredRevenue === 0) {
        return 0;
      }
      return (this.totalFilteredProfit / this.totalFilteredRevenue);
    },

    totalRevenueOfSelectedRows() {
      return this.selectedRows.reduce((total, row) => total + row.revenue, 0);
    },

    totalProfitOfSelectedRows() {
      return this.selectedRows.reduce((total, row) => total + row.profit, 0);
    },

    averageMarginOfSelectedRows() {
      const totalProfit = this.totalProfitOfSelectedRows;
      const totalRevenue = this.totalRevenueOfSelectedRows;
      return totalRevenue !== 0 ? (totalProfit / totalRevenue) * 100 : 0;
    },

    showingText() {
      const start = (this.currentPage - 1) * this.pageSize + 1;
      const end = Math.min(this.currentPage * this.pageSize, this.totalFilteredItems);
      return `Showing ${start}-${end} of ${this.totalFilteredItems}`;
    },

    tableRows() {
      // Filter the records based on the selected date range
      const filteredRecords = this.records.filter(record => {
        const orderStartDate = new Date(record.fields['Order Start Date']);
        const [start, end] = Array.isArray(this.filters.dateRange.value) ? this.filters.dateRange.value : [null, null];
        return (!start || orderStartDate >= start) && (!end || orderStartDate <= end);
      });

      // Map the filtered records to the table rows
      return filteredRecords.map(row => {
        let vendors = row.fields['Vendors'] ? row.fields['Vendors'].split(',').map(pair => pair.trim()) : [];
        let vendorIds = row.fields['Vendors ID'] ? row.fields['Vendors ID'] : [];

        let vendorLinks = vendors.map((vendor, index) => {
          return {
            name: vendor,
            id: vendorIds[index]
          };
        });

        return {
          id: row.id,
          status: row.fields['Status'],
          quoteNumber: row.fields['Quote No.'],
          orderId: row.fields['OID'],
          revenue: row.fields['9 - Gross Revenue in €'],
          currency: row.fields['Currency'],
          company: row.fields['Company Lookup'] ? row.fields['Company Lookup'].join(', ') : '',
          companyId: row.fields['Company ID'] ? row.fields['Company ID'].join(', ') : '',
          contact: row.fields['Contact Lookup'] ? row.fields['Contact Lookup'].join(', ') : '',
          contactId: row.fields['Contact ID'] ? row.fields['Contact ID'].join(', ') : '',
          contactEmail: row.fields['Email Lookup'] ? row.fields['Email Lookup'].join(', ') : '',
          createdOn: row.fields['Created On'],
          createdBy: row.fields['Created By Lookup'] ? row.fields['Created By Lookup'].join(', ') : '',
          createdByPhoto: row.fields['Created By Photo'] ? row.fields['Created By Photo'][0].url : '',
          forwardedBy: row.fields['Forwarded By Lookup'] ? row.fields['Forwarded By Lookup'].join(', ') : '',
          forwardedByPhoto: row.fields['Forwarded By Photo'] ? row.fields['Forwarded By Photo'][0].url : '',
          pm: row.fields['PM Lookup'] ? row.fields['PM Lookup'].join(', ') : '',
          pmPhoto: row.fields['PM Photo'] ? row.fields['PM Photo'][0].url : '',
          orderStartDate: row.fields['Order Start Date'],
          orderDeliveryDate: row.fields['Order Delivery Date'],
          vendors: vendorLinks,
          languagePairs: row.fields['Language Pairs'] ? row.fields['Language Pairs'].split(',').map(pair => pair.trim()).filter(pair => pair !== '') : [],
          title: row.fields['Title'],
          privateNotes: row.fields['Private Notes'] ? row.fields['Private Notes'].trim() : '', // Trimming the notes here
          publicNotes: row.fields['Public Notes'] ? row.fields['Public Notes'].trim() : '', // Trimming the notes here
          invoice: row.fields['Invoice'],
          deliveryConfirmed: row.fields['Delivery Confirmed?'],
          profit: row.fields['11 - Profit in €'],
          margin: typeof row.fields['12 - Margin'] === 'number' ? row.fields['12 - Margin'].toFixed(2) : '0',
        };
      });
},
  },

  watch: {
    // Deselects all rows in the table when the date picker has changed
    'filters.dateRange.value': function() {
      this.selectedRows.splice(0, this.selectedRows.length);
    },

    'filters.name.value': function() {
      this.totalFilteredRevenue = 0;
      this.totalFilteredProfit = 0;
    },
  },

  methods: {
    handleStateChanged(tableState) {
      this.totalFilteredItems = tableState.rowsPrePagination.length;
      this.selectedRows = tableState.selectedRows;

      this.totalFilteredRevenue = 0;
      this.totalFilteredProfit = 0;

      for (let row of tableState.rowsPrePagination) {
        this.totalFilteredRevenue += row.revenue || 0;
        this.totalFilteredProfit += row.profit || 0;
      }
    },
  },

  created() { },
};
</script>