<!-- BootstrapToast.vue -->

<template>
  <div v-if="show" :class="['small alert shadow-sm', toastClass]" role="alert" aria-live="assertive" aria-atomic="true">
    {{ message }}
  </div>
</template>

<script>
/* global bootstrap */
export default {
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    show: {
      type: Boolean,
      default: true,
    },
    autoHideDelay: {
      type: Number,
      default: 5000,
    },
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    toastClass() {
      return `alert-${this.type}`;
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.showToast();
      }
    },
  },
  methods: {
    showToast() {
      const toastEl = this.$el;
      const toast = new bootstrap.Toast(toastEl, {
        autohide: true,
        delay: this.autoHideDelay,
      });
      toast.show();
    },
  },
  mounted() {
    this.showToast();
  },
};
</script>

<style scoped>
  .alert {
    padding: 10px;
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
  }
</style>