<!-- CreateCompanyModal.vue -->

<template>

  <div class="modal" id="createCompanyModal" tabindex="-1" aria-labelledby="createCompanyModalLabel" aria-hidden="true" ref="createCompanyModal">
    <div class="modal-dialog">
      <div class="modal-content shadow shadow-lg">

        <div class="modal-header border-bottom">
          <h5 class="modal-title">Create New Company</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div v-if="createdCompany" class="alert alert-success mt-3">
          <i class="bi bi-check-circle-fill me-1"></i>
          Company "{{ createdCompany.fields['Name'] }}" created. <a class="alert-link" :href="'/companies/' + createdCompany.id" target="_blank">Open this company profile</a>.
        </div>

        <div class="modal-body" v-if="!createdCompany">
          <!-- Company Name -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Company Name
            </div>

            <div class="col-8">
              <input class="form-control" type="text" v-model="newCompany.name">
            </div>
          </div>
        </div>

        <div class="modal-footer" v-if="!createdCompany">
          <span v-if="isCreating('creatingCompany')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-primary" @click="createCompany" :disabled="isCreating('creatingCompany') || isSubmitting">Create Company</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CreateCompanyModal',
  emits: [
    'showAlert',
    'showError',
  ],

  data() {
    return {
      newCompany: this.defaultNewCompany(),
      createdCompany: null,
      isSubmitting: false,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  methods: {
    defaultNewCompany() {
      return {
        name: '',
      };
    },

    resetForm() {
      this.newCompany = this.defaultNewCompany();
    },

    resetCreatedCompany() {
      this.createdCompany = null;
    },

    validateFields() {
      let errors = [];

      if (!this.newCompany.name) { errors.push('"Name" is required.'); }

      return errors;
    },

    formatData() {
      const newCompanyName = this.newCompany.name.trim();

      const formattedData = {
        'Name': newCompanyName,

        // mandatory fields
        'Country': ['recosaqYwiti9cIIk'],
        'Industry': ['reczQ7LVS0JUMsXZQ'],
        'Preferred Currency': 'Other',
        'Preferred PM': ['rect8tJzlNsWIQSoQ'],
        'Acquired By': ['rect8tJzlNsWIQSoQ'],
      };

      return formattedData;
    },

    async createCompany() {
      this.isSubmitting = true;

      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        this.isSubmitting = false;
        return;
      }

      const formattedData = this.formatData();

      try {
        const createdCompany = await this.$store.dispatch('createRecord', {
          tableName: 'Companies',
          newRecord: formattedData,
          creatingStateVariable: 'creatingCompany',
        });

        // for use in the view and as a flag
        this.createdCompany = createdCompany.records[0];
        this.resetForm();

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error creating company: ' + error.message);
        console.error('Error creating company: ', error);

      } finally {
        this.isSubmitting = false;
      }
    },
  },

  mounted() {
    // Resets modal on close
    // we use this.resetForm() and not this.resetForm because we are calling 2 methods
    this.$refs.createCompanyModal.addEventListener('hidden.bs.modal', () => {
      this.resetForm();
      this.resetCreatedCompany();
    });
  }
}
</script>