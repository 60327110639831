<!-- Test.vue -->

<template>

  Test app

</template>

<script>

export default {
  name: 'AppTest',

  emits: [
    'showAlert',
    'showError',
  ],

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },
  },

  created() {

  },

  mounted() {
  }
};
</script>
