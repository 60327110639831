// onesimpleapi.js

import axios from 'axios';

const ONE_SIMPLE_API_BASE_URL = 'https://onesimpleapi.com/api/';
const API_TOKEN = 'PPr6FsuCmdYil1vtlE1JLoRTayVA5H256HcUwN3G';

// Function to fetch the exchange rate from USD to EUR
export async function fetchUSDtoEURExchangeRate() {
  try {
    const response = await axios.get(`${ONE_SIMPLE_API_BASE_URL}exchange_rate`, {
      params: {
        token: API_TOKEN,
        from_currency: 'USD',
        to_currency: 'EUR',
        from_value: 1,
      },
    });

    // console.log('services/onesimpleapi.js: ', response.data); // debug
    return response.data;
  } catch (error) {
    console.error('Error fetching USD to EUR exchange rate:', error);
    throw new Error('Failed to fetch USD to EUR exchange rate.');
  }
}

// Function to fetch the exchange rate from GBP to EUR
export async function fetchGBPtoEURExchangeRate() {
  try {
    const response = await axios.get(`${ONE_SIMPLE_API_BASE_URL}exchange_rate`, {
      params: {
        token: API_TOKEN,
        from_currency: 'GBP',
        to_currency: 'EUR',
        from_value: 1,
      },
    });

    // console.log('services/onesimpleapi.js: ', response.data); // debug
    return response.data;
  } catch (error) {
    console.error('Error fetching GBP to EUR exchange rate:', error);
    throw new Error('Failed to fetch GBP to EUR exchange rate.');
  }
}