<!-- QuotesHeader.vue -->

<template>

  <div v-if="!isFetching('fetchingQuotes')">
    <div class="d-flex mb-3 border-bottom align-items-end">
      <div class="col-4 d-flex align-items-end">
        <div class="p-2 ps-0">
          <div class="d-flex align-items-center">
            <div class="fs-5 fw-semibold me-2">
              Quotes
            </div>

            <!-- View Picker -->
            <div class="dropdown me-2">
              <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="monthDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ selectedMonth.label }}
              </button>
              <div class="dropdown-menu dropdown-menu-scroll" aria-labelledby="monthDropdownMenuButton">
                <h6 class="dropdown-header">Select a View</h6>
                <a
                  class="dropdown-item small"
                  href="#"
                  v-for="month in allMonths"
                  :key="month.value"
                  @click.prevent="selectMonth(month.value)"
                >
                  <span>{{ month.label }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Stats: Total -->
      <div class="col-2 text-end" v-if="mixinUserInRoles(['Admin'])">
        <div class="p-2">
          <p class="mb-0 text-muted">Total</p>
          <div class="fs-5 mb-0 fw-semibold">€{{ mixinFormatNumber(totalRevenue) }}</div>
        </div>
      </div>

      <!-- Stats: Open -->
      <div class="col-2 text-end" v-if="mixinUserInRoles(['Admin'])">
        <div class="p-2">
          <p class="mb-0 text-muted">Open</p>
          <div class="fs-5 mb-0 fw-semibold">€{{ mixinFormatNumber(totalOpenQuotesRevenue) }}</div>
        </div>
      </div>

      <!-- Stats: Abandoned -->
      <div class="col-2 text-end" v-if="mixinUserInRoles(['Admin'])">
        <div class="p-2">
          <p class="mb-0 text-muted">Abandoned</p>
          <div class="fs-5 mb-0 fw-semibold">€{{ mixinFormatNumber(totalAbandonedQuotesRevenue) }}</div>
        </div>
      </div>

      <!-- Stats: Lost -->
      <div class="col-2 text-end" v-if="mixinUserInRoles(['Admin'])">
        <div class="p-2">
          <p class="mb-0 text-muted">Lost</p>
          <div class="fs-5 mb-0 fw-semibold">€{{ mixinFormatNumber(totalLostQuotesRevenue) }}</div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="text-center text-muted p-5">
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'QuotesHeader',
  emits: [
    'showAlert',
    'showError',
    'totalRevenueChanged',
    'selectMonth',
  ],
  props: {
    selectedMonth: {
      type: Object,
      required: true,
    },

    quotes: {
      type: Object,
      required: true,
    },
  },

  data() { },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',
      'allMonths',
    ]),

    totalRevenue() {
      return this.quotes.reduce((total, row) => {
        total += row.fields['9 - Gross Revenue in €'];
        return total;
      }, 0);
    },

    totalOpenQuotesRevenue() {
      return this.quotes.reduce((total, row) => {
        if (row.fields.Status === "Quote: Open") {
          total += row.fields['9 - Gross Revenue in €'];
        }
        return total;
      }, 0);
    },

    totalAbandonedQuotesRevenue() {
      return this.quotes.reduce((total, row) => {
        if (row.fields.Status === "Quote: Abandoned") {
          total += row.fields['9 - Gross Revenue in €'];
        }
        return total;
      }, 0);
    },

    totalLostQuotesRevenue() {
      return this.quotes.reduce((total, row) => {
        if (row.fields.Status === "Quote: Lost") {
          total += row.fields['9 - Gross Revenue in €'];
        }
        return total;
      }, 0);
    },
  },

  watch: {
    totalRevenue(newVal) {
      this.$emit('totalRevenueChanged', newVal);
    },
  },

  methods: {
    selectMonth(month) {
      this.$emit('selectMonth', month);
    },
  },

  created() { },
};
</script>
