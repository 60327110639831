<!-- AppLogin.vue -->

<template>

  <div id="sign-in" style="margin: 0 auto;"></div>

</template>

<script>

export default {
  name: 'AppLogin',
  emits: [
    'showAlert',
    'showError',
  ],

  mounted() {
    const signInComponent = document.querySelector('#sign-in');
    this.$clerk.mountSignIn(signInComponent, {
      appearance: {
        baseTheme: 'dark',
      },
    });
  },
  beforeUnmount() {
    const signInComponent = document.querySelector('#sign-in');
    this.$clerk.unmountSignIn(signInComponent);
  },
};
</script>
