// router/index.js

import { createRouter, createWebHistory } from 'vue-router';

import Login from '@/components/AppLogin.vue';

import Dashboard from '@/components/Dashboard.vue';

import Quotes from '@/components/Quotes.vue';
import Orders from '@/components/Orders.vue';
import OppDetails from '@/components/OppDetails.vue';

import Jobs from '@/components/Jobs.vue';

import Companies from '@/components/Companies.vue';
import CompanyDetails from '@/components/CompanyDetails.vue';

import Contacts from '@/components/Contacts.vue';
import ContactDetails from '@/components/ContactDetails.vue';

import Vendors from '@/components/Vendors.vue';
import VendorDetails from '@/components/VendorDetails.vue';

import VendorsServices from '@/components/VendorsServices.vue';

import Services from '@/components/Services.vue';
import ServiceDetails from '@/components/ServiceDetails.vue';

import Units from '@/components/Units.vue';
import UnitDetails from '@/components/UnitDetails.vue';

import QuoteDetails from '@/components/QuoteDetails.vue';

import Test from '@/components/Test.vue';

const routes = [
  // Login
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },

  // Dashboard
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },

  // Quotes
  {
    path: '/opps/quotes',
    name: 'Quotes',
    component: Quotes,
  },

  // Orders
  {
    path: '/opps/orders',
    name: 'Orders',
    component: Orders,
  },

  // Opp Details
  {
    path: '/opps/:id',
    name: 'Opp Details',
    component: OppDetails,
  },

  // Jobs
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs,
  },

  // Companies
  {
    path: '/companies',
    name: 'Companies',
    component: Companies,
  },

  // Company Details
  {
    path: '/companies/:id',
    name: 'Company Details',
    component: CompanyDetails,
    props: { isPublicMode: false },
  },

  // Company Details Public
  {
    path: '/companies/public/:id',
    name: 'Company Details Public',
    component: CompanyDetails,
    props: { isPublicMode: true },
  },

  // Contacts
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
  },

  // Contact Details
  {
    path: '/contacts/:id',
    name: 'Contact Details',
    component: ContactDetails,
  },

  // Vendors
  {
    path: '/vendors',
    name: 'Vendors',
    component: Vendors,
  },

  // Vendor Details
  {
    path: '/vendors/:id',
    name: 'Vendor Details',
    component: VendorDetails,
    props: { isPublicMode: false },
  },

  // Vendor Details Public
  {
    path: '/vendors/public/:id',
    name: 'Vendor Details Public',
    component: VendorDetails,
    props: { isPublicMode: true },

  },

  // Vendor Services
  {
    path: '/vendors/services',
    name: 'Vendors Services',
    component: VendorsServices,
  },

  // Services
  {
    path: '/settings/services',
    name: 'Services',
    component: Services,
  },

  // Service Details
  {
    path: '/settings/services/:id',
    name: 'Service Details',
    component: ServiceDetails,
  },

  // Units
  {
    path: '/settings/units',
    name: 'Units',
    component: Units,
  },

  // Unit Details
  {
    path: '/settings/units/:id',
    name: 'Unit Details',
    component: UnitDetails,
  },

  // Quote Details
  {
    path: '/quotes/:id',
    name: 'Quote Details',
    component: QuoteDetails,
  },

  // Test
  {
    path: '/test',
    name: 'Test',
    component: Test,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, left: 0, behavior: 'instant' });

  next();
});

export default router;