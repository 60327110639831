// mailgun.js

import axios from 'axios';

const apiKey = process.env.VUE_APP_API_KEY;
const apiUrl = process.env.VUE_APP_NODE_ENV === 'production' ? process.env.VUE_APP_PROD_API_URL : process.env.VUE_APP_DEV_API_URL;

export async function sendEmail({ from, to, subject, content }) {
  try {
    const response = await axios.post(`${apiUrl}/api/send-email`, {
      from,
      to,
      subject,
      content,
    }, {
      headers: {
        'x-api-key': apiKey,
      },
    });

    if (response.status === 200) {
      console.log('Email sent successfully');
    } else {
      throw new Error('Failed to send email');
    }
  } catch (error) {
    console.error('Error sending email:', error);
    throw new Error('Failed to send email');
  }
}