<!-- CreateVendorServiceModal.vue -->

<template>

  <div class="modal" id="createVendorServiceModal" tabindex="-1" aria-labelledby="createVendorServiceModalLabel" aria-hidden="true" ref="createVendorServiceModal">
    <div class="modal-dialog">

      <div class="modal-content shadow shadow-lg">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">Create Service</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <!-- Service -->
          <div class="row align-items-center pt-3">
            <div class="col-4 text-muted">
              Service
            </div>

            <div class="col-8">
              <v-select v-if="!isFetching('fetchingServices')" v-model="newVendorService.service" :options="allServicesOptions" placeholder="Select..." :clearable="false">
                <template v-slot:option="option">
                  <div class="text-wrap">{{ option.label }}</div>
                </template>

                <template v-slot:open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                  </span>
                </template>
              </v-select>

              <div v-else class="d-inline-block">
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Language Pair -->
          <div class="row align-items-center pt-3">
            <div class="col-4 text-muted">
              Language Pair
            </div>

            <div class="col-8">
              <v-select v-if="!isFetching('fetchingLanguagePairs')" v-model="newVendorService.languagePair" :options="allLanguagePairsOptions" placeholder="Select..." :clearable="false">
                <template v-slot:option="option">
                  <div class="text-wrap">{{ option.label.replace('>', '›') }}</div>
                </template>

                <template v-slot:open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                  </span>
                </template>
              </v-select>

              <div v-else class="d-inline-block">
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Unit -->
          <div class="row align-items-center pt-3">
            <div class="col-4 text-muted">
              Unit
            </div>

            <div class="col-8">
              <v-select v-if="!isFetching('fetchingUnits')" v-model="newVendorService.unit" :options="allUnitsOptions" placeholder="Select..." :clearable="false">
                <template v-slot:open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                  </span>
                </template>
              </v-select>

              <div v-else class="d-inline-block">
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Unit Price -->
          <div class="row align-items-center pt-3">
            <div class="col-4 text-muted">
              Unit Price
              <div class="form-text">
                <!-- In {{ record.fields['Currency (from "Vendors")'] ? record.fields['Currency (from "Vendors")'].join(', ') : '' }} -->
              </div>
            </div>

            <div class="col-8">
              <input class="form-control" type="number" v-model="newVendorService.unitPrice">
            </div>
          </div>

          <!-- Specialities -->
          <div class="row align-items-center pt-3">
            <div class="col-4 text-muted">
              Specialities
            </div>

            <div class="col-8">
              <v-select v-if="!isFetching('fetchingSpecialities')" v-model="newVendorService.specialities" :options="allSpecialitiesOptions" placeholder="Select..." multiple>
                <template v-slot:option="option">
                  <div class="text-wrap">{{ option.label }}</div>
                </template>

                <template v-slot:open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                  </span>
                </template>
              </v-select>

              <div v-else class="d-inline-block">
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span v-if="isCreating('creatingVendorService')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-primary" @click="createRecord" :disabled="isCreating('creatingVendorService')|| isSubmitting">Create Service</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';

export default {
  name: 'CreateVendorServiceModal',
  emits: [
    'showAlert',
    'showError',
    'fetchVendorServices',
  ],
  props: {
    vendorId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      newVendorService: this.defaultVendorService(),
      isSubmitting: false,
      modalInstance: null,
    };
  },

  computed: {
    ...mapGetters([
      'allLanguagePairsOptions',
      'allServicesOptions',
      'allUnitsOptions',
      'allSpecialitiesOptions',

      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',
    ]),
  },

  watch: { },

  methods: {
    defaultVendorService() {
      return {
        service: '',
        languagePair: '',
        unit: '',
        unitPrice: '',
        specialities: [],
      };
    },

    openModal() {
      if (!this.modalInstance) {
        const modalElement = this.$refs.createVendorServiceModal;
        this.modalInstance = new bootstrap.Modal(modalElement);
      }
      this.modalInstance.show();
    },

    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },

    validateFields() {
      let errors = [];

      if (!this.newVendorService.service) { errors.push('"Service" is required.'); }
      if (!this.newVendorService.languagePair) { errors.push('"Language Pair" is required.'); }
      if (!this.newVendorService.unit) { errors.push('"Unit" is required.'); }
      if (!this.newVendorService.unitPrice) { errors.push('"Unit Price" is required.'); }
      if (this.newVendorService.specialities.length === 0) { errors.push('At least one speciality is required.'); }

      return errors;
    },

    formatData() {
      const formattedData = {
        'Service': [this.newVendorService.service.value],
        'Language Pair': [this.newVendorService.languagePair.value],
        'Unit': [this.newVendorService.unit.value],
        'Unit Price': this.newVendorService.unitPrice,
        'Specialities': this.newVendorService.specialities.map(speciality => speciality.value),

        // mandatory fields
        'Vendor': [this.vendorId],
      };

      return formattedData;
    },

    resetForm() {
      this.newVendorService = this.defaultVendorService();
    },

    async createRecord() {
      this.isSubmitting = true;

      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        this.isSubmitting = false;
        return;
      }

      const formattedData = this.formatData();
      // console.log('New vendor service to be created: ', formattedData); // debug

      try {
        await this.$store.dispatch('createRecord', {
          tableName: 'Vendor Services',
          newRecord: formattedData,
          creatingStateVariable: 'creatingVendorService',
        });

        this.$emit('showAlert', 'success', 'Successfully created vendor service');
        this.closeModal();
        this.resetForm();
        this.$emit('fetchVendorServices');

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error creating vendor service: ' + error.message);
        console.error('Error creating vendor service: ', error);

      } finally {
        this.isSubmitting = false;
      }
    },
  },

  async mounted() { },

  created() { },
};
</script>