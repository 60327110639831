// slack.js

import axios from 'axios';

const apiKey = process.env.VUE_APP_API_KEY;
const apiUrl = process.env.VUE_APP_NODE_ENV === 'production' ? process.env.VUE_APP_PROD_API_URL : process.env.VUE_APP_DEV_API_URL;

function getRandomMessageAndEmoji() {
  const messages = [
    "Great job",
    "Awesome",
    "Keep 'em comin",
    "Sweet",
    "Well done"
  ];

  const emojis = [":muscle:", ":rocket:", ":fire:", ":star2:", ":boom:", ":tada:"];

  const randomMessage = messages[Math.floor(Math.random() * messages.length)];
  const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];

  return { randomMessage, randomEmoji };
}

function generateSlackMessage(company, contactEmail, quoteNumber, oppUrl, createdBy, currency, value, privateNotes, messageType) {
  if (messageType === 'confirmed') {
    const { randomMessage, randomEmoji } = getRandomMessageAndEmoji();
    return `*Opp ${quoteNumber} (${currency}${value}) from ${company} (${contactEmail}) confirmed!*\n`
      + `* • Opp created by:* ${createdBy}\n`
      + `* • Opp link:* <${oppUrl}>\n`
      + `${randomMessage}, ${createdBy.split(' ')[0]}! ${randomEmoji}`;

  } else if (messageType === 'abandoned') {
    return `*Opp ${quoteNumber} (${currency}${value}) from ${company} (${contactEmail}) abandoned*\n`
      + `* • Opp created by:* ${createdBy}\n`
      + `* • Opp link:* <${oppUrl}>\n`
      + `* • Private notes:*\n${privateNotes}`

  } else if (messageType === 'lost') {
    return `*Opp ${quoteNumber} (${currency}${value}) from ${company} (${contactEmail}) lost*\n`
      + `* • Opp created by:* ${createdBy}\n`
      + `* • Opp link:* <${oppUrl}>\n`
      + `* • Private notes:*\n${privateNotes}`

  }
}

export async function sendMessageToSlack({ company, contactEmail, quoteNumber, oppUrl, createdBy, currency, value, privateNotes, messageType }) {
  try {
    const slackMessage = generateSlackMessage(company, contactEmail, quoteNumber, oppUrl, createdBy, currency, value, privateNotes, messageType);
    const response = await axios.post(`${apiUrl}/api/send-slack-message`, {
      text: slackMessage,
    }, {
      headers: {
        'x-api-key': apiKey,
      },
    });

    if (response.status === 200) {
      // console.log('Message sent to Slack successfully'); // debug
    } else {
      throw new Error('Failed to send message to Slack');
    }
  } catch (error) {
    // console.error('Error sending message to Slack:', error); // debug
    throw new Error('Failed to send message to Slack');
  }
}