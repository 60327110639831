import {
  fetchUSDtoEURExchangeRate,
  fetchGBPtoEURExchangeRate
} from '@/services/onesimpleapi';

const onesimpleapi = {
  state: {
    usdToEurExchangeRate: null,
    gbpToEurExchangeRate: null,
  },

  mutations: {
    setUSDtoEURExchangeRate(state, exchangeRate) {
      state.usdToEurExchangeRate = exchangeRate;
    },
    setGBPtoEURExchangeRate(state, exchangeRate) {
      state.gbpToEurExchangeRate = exchangeRate;
    },
  },

  actions: {
    async fetchUSDtoEURExchangeRate({ commit }) {
      try {
        const exchangeRate = await fetchUSDtoEURExchangeRate();
        // console.log('store/modules/onesimpleapi.js: ', exchangeRate); // debug
        commit('setUSDtoEURExchangeRate', exchangeRate);
        return exchangeRate;
      } catch (error) {
        console.error('Error fetching USD to EUR exchange rate:', error);
        throw new Error('Failed to fetch USD to EUR exchange rate.');
      }
    },
    async fetchGBPtoEURExchangeRate({ commit }) {
      try {
        const exchangeRate = await fetchGBPtoEURExchangeRate();
        // console.log('store/modules/onesimpleapi.js: ', exchangeRate); // debug
        commit('setGBPtoEURExchangeRate', exchangeRate);
        return exchangeRate;
      } catch (error) {
        console.error('Error fetching GBP to EUR exchange rate:', error);
        throw new Error('Failed to fetch GBP to EUR exchange rate.');
      }
    },
  },

  getters: {
    usdToEurExchangeRate: (state) => state.usdToEurExchangeRate,
    gbpToEurExchangeRate: (state) => state.gbpToEurExchangeRate,
  },
};

export default onesimpleapi;
