<template>

  <Line :options="chartOptions" :data="chartData" />

</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale)

export default {
  name: 'LineChart',
  components: { Line },
  props: {
    chartData: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false, // Hide vertical grid lines
            },
          },
          y: {
            ticks: {
              callback: function(value) {
                return '€' + value;
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                var label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += '€' + Number(context.parsed.y).toFixed(2);
                }
                return label;
              }
            }
          },
          legend: {
            display: false
          }
        }
      }
    }
  },
  created() {
    // Assuming chartData has a datasets array
    this.chartData.datasets.forEach(dataset => {
      dataset.borderColor = '#0d6efd'; // Change this to your desired color
      dataset.borderWidth = 1.5; // Increase the border width for more noticeable rounded joins
      dataset.tension = 0.0;

      dataset.pointBackgroundColor = 'white'; // Set the fill color of the circles
      dataset.pointBorderColor = '#0d6efd'; // Set the border color of the circles
      dataset.pointBorderWidth = 2; // Set the border width of the circles
      dataset.pointRadius = 3;


    });
  },
}
</script>