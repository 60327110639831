<!-- Contacts.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'Finance'])">
    <div v-if="findDuplicates.length > 0" class="alert alert-warning p-2 small">
      <i class="bi bi-exclamation-triangle-fill"></i> <strong>There are duplicate contacts:</strong> {{ findDuplicates.join(', ') }}
    </div>

    <ContactsHeader @showAlert="showAlert" @showError="showError" />

    <div v-if="!isFetching('fetchingContacts')">
      <div class="p-3 shadow-sm bg-white rounded">
        <ReusableContactsTable :records="getProperty('contacts')" @showAlert="showAlert" @showError="showError" />
      </div>
    </div>

    <div v-else class="text-center text-muted p-5">
      <div class="spinner-border text-secondary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div v-else>
    <p class="">You are not authorized to see this page.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContactsHeader from '@/components/ContactsHeader.vue';
import ReusableContactsTable from '@/components/ReusableContactsTable.vue';

export default {
  name: 'AppContacts',
  emits: [
    'showAlert',
    'showError',
  ],
  components: {
    ContactsHeader,
    ReusableContactsTable,
  },

  data() {
    return {
      hasDuplicates: false,
      duplicateNames: [],
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),

    findDuplicates() {
      const nameCount = {};
      const duplicates = [];
      const originalNames = {};

      this.getProperty('contacts').forEach(row => {
        const originalName = row.fields['Name'];
        const name = originalName.toLowerCase(); // convert to lowercase for comparison
        originalNames[name] = originalName; // store the original name
        if (nameCount[name]) {
          nameCount[name]++;
        } else {
          nameCount[name] = 1;
        }
      });

      Object.keys(nameCount).forEach(name => {
        if (nameCount[name] > 1) {
          duplicates.push(originalNames[name]); // push the original name, not the lowercase version
        }
      });

      return duplicates;
    },
  },

  watch: { },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },
  },

  async mounted() {
    document.title = `Contacts`;
  },

  async created() {
    this.mixinCheckUserAuthentication();
  },
};
</script>