<!-- App.vue -->

<template>

  <div id="app">
    <template v-if="user && !isQuoteDetailsPage">
      <AppNavbar @showAlert="showAlert" />
    </template>

    <div v-if="errorVisible" class="container pt-3 pb-3">
      <div class="alert alert-danger text-center mb-0">
        <p>There was a critical error and this page didn't fully load. If you did a change, it might not have been saved.</p>
        <p><a href="" class="btn btn-sm btn-danger" @click="refreshPage">Refresh this page</a></p>
        <span class="small">{{ errorMessage }}</span>
      </div>
    </div>

    <div v-else>
      <bootstrap-toast
        v-if="alertVisible"
        :type="alertType"
        :show="alertVisible"
        :message="alertMessage"
        @update:show="alertVisible = $event"
      />

      <div :class="getBodyClass" class="custom-container">
        <router-view @showAlert="showAlert" @showError="showError" />
      </div>
    </div>

    <template v-if="!isQuoteDetailsPage">
      <div class="custom-container mt-4 mb-5 pt-2 border-top small text-muted">
        &copy; Language Buró OÜ
      </div>
    </template>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

import AppNavbar from '@/components/AppNavbar.vue';
import BootstrapToast from '@/components/BootstrapToast.vue';

export default {
  name: 'App',
  components: {
    AppNavbar,
    BootstrapToast
  },

  data() {
    return {
      alertVisible: false,
      alertType: '',
      alertMessage: '',
      errorMessage: '',

      user: null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters([
        'fetchedOrderDetails',
        'allTeam',
        'allPMs',
        'allCompanies',
        'allContacts',
        'allLanguagePairs',
        'allWorkableVendors',
        'allVendors',
        'allLanguages',
        'allCountries',

        'getProperty',
      ]),

    errorVisible() {
      return this.errorMessage !== '';
    },

    getBodyClass() {
      switch (this.$route.name) {
        case 'Quote Details':
          return 'quote-details';
        default:
          return 'app'; // Default class
      }
    },

    isQuoteDetailsPage() {
      return this.$route.name === 'Quote Details';
    },
  },

  watch: {
    $route() {
      document.body.className = this.getBodyClass;
    },
  },

  methods: {
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async delayedDispatch(action, payload, delay) {
      await this.delay(delay);
      return this.$store.dispatch(action, payload);
    },

    refreshPage() {
      window.location.reload();
    },

    // App: Alerts
    showAlert(type, message) {
      this.alertType = type;
      this.alertMessage = message;
      this.alertVisible = false;
      this.$nextTick(() => {
        this.alertVisible = true;
      });

      // console.log(`Received alert: type=${type}, message=${message}`); // debug
    },

    showError(errorMessage) {
      this.errorMessage = errorMessage;
    },
  },

  async created() {
    document.body.className = this.getBodyClass;

    this.user = this.$clerk.user;

    try {
      const actions = [
        // Delayed dispatch for Team
        this.delayedDispatch('fetchRecords', {
          tableName: 'Team',
          filter: '{Active?} = "Yes"',
          stateVariable: 'team',
          fetchingStateVariable: 'fetchingTeam',
        }, 0),

        // Delayed dispatch for PMs
        this.delayedDispatch('fetchRecords', {
          tableName: 'Team',
          filter: 'AND({Active?} = "Yes", {PM?} = "Yes")',
          stateVariable: 'PMs',
          fetchingStateVariable: 'fetchingPMs',
        }, 200),

        // Delayed dispatch for Companies
        this.delayedDispatch('fetchRecords', {
          tableName: 'Companies',
          stateVariable: 'companies',
          fetchingStateVariable: 'fetchingCompanies',
        }, 400),

        // Delayed dispatch for Contacts
        this.delayedDispatch('fetchRecords', {
          tableName: 'Contacts',
          stateVariable: 'contacts',
          fetchingStateVariable: 'fetchingContacts',
        }, 600),

        // Delayed dispatch for Language Pairs
        this.delayedDispatch('fetchRecords', {
          tableName: 'Language Pairs',
          stateVariable: 'languagePairs',
          fetchingStateVariable: 'fetchingLanguagePairs',
        }, 800),

        // Delayed dispatch for Vendors
        this.delayedDispatch('fetchRecords', {
          tableName: 'Vendors',
          stateVariable: 'vendors',
          fetchingStateVariable: 'fetchingVendors',
        }, 800),

        // Delayed dispatch for Vendors Services
        this.delayedDispatch('fetchRecords', {
          tableName: 'Vendor Services',
          stateVariable: 'vendorsServices',
          fetchingStateVariable: 'fetchingVendorsServices',
        }, 1000),

        // Languages
        this.delayedDispatch('fetchRecords', {
          tableName: 'Languages',
          stateVariable: 'languages',
          fetchingStateVariable: 'fetchingLanguages',
        }, 1200),

        // Countries
        this.delayedDispatch('fetchRecords', {
          tableName: 'Countries',
          stateVariable: 'countries',
          fetchingStateVariable: 'fetchingCountries',
        }, 1400),

        // Services
        this.delayedDispatch('fetchRecords', {
          tableName: 'Services',
          stateVariable: 'services',
          fetchingStateVariable: 'fetchingServices',
        }, 1600),

        // Units
        this.delayedDispatch('fetchRecords', {
          tableName: 'Units',
          stateVariable: 'units',
          fetchingStateVariable: 'fetchingUnits',
        }, 1800),

        // Specialities
        this.delayedDispatch('fetchRecords', {
          tableName: 'Specialities',
          stateVariable: 'specialities',
          fetchingStateVariable: 'fetchingSpecialities',
        }, 2000),

        // Industries
        this.delayedDispatch('fetchRecords', {
          tableName: 'Industries',
          stateVariable: 'industries',
          fetchingStateVariable: 'fetchingIndustries',
        }, 2200),
      ];

      await Promise.all(actions);

      // Sort the arrays after fetching them concurrently
      // REMEMBER: If there is an empty row in Airtable, the sorting will throw an error
      // await Promise.all([
      //   this.$store.dispatch('sortArrayAlphabetically', { arrayName: 'team', field: 'Name' }),
      //   this.$store.dispatch('sortArrayAlphabetically', { arrayName: 'PMs', field: 'Name' }),
      //   this.$store.dispatch('sortArrayAlphabetically', { arrayName: 'companies', field: 'Name' }),
      //   this.$store.dispatch('sortArrayAlphabetically', { arrayName: 'contacts', field: 'Name' }),
      //   this.$store.dispatch('sortArrayAlphabetically', { arrayName: 'languagePairs', field: 'Name' }),
      //   this.$store.dispatch('sortArrayAlphabetically', { arrayName: 'vendors', field: 'Full Name' }),
      //   this.$store.dispatch('sortArrayAlphabetically', { arrayName: 'languages', field: 'Name' }),
      //   this.$store.dispatch('sortArrayAlphabetically', { arrayName: 'countries', field: 'Name' }),
      //   this.$store.dispatch('sortArrayAlphabetically', { arrayName: 'services', field: 'Name' }),
      //   this.$store.dispatch('sortArrayAlphabetically', { arrayName: 'units', field: 'Name' }),
      //   this.$store.dispatch('sortArrayAlphabetically', { arrayName: 'specialities', field: 'Name' }),
      // ]);

      // console.log('Fetched team data:', this.getProperty('team')); // debug
      // console.log('Fetched PMs data:', this.getProperty('PMs')); // debug
      // console.log('Fetched companies data:', this.getProperty('companies')); // debug
      // console.log('Fetched contacts data:', this.getProperty('contacts')); // debug
      // console.log('Fetched language pairs data:', this.getProperty('languagePairs')); // debug
      // console.log('Fetched vendors data:', this.getProperty('vendors')); // debug
      // console.log('Fetched vendor services data:', this.getProperty('vendorsServices')); // debug
      // console.log('Fetched languages data:', this.getProperty('languages')); // debug
      // console.log('Fetched countries data:', this.getProperty('countries')); // debug
      // console.log('Fetched services data:', this.getProperty('services')); // debug
      // console.log('Fetched units data:', this.getProperty('units')); // debug
      // console.log('Fetched specialities data:', this.getProperty('specialities')); // debug

    } catch (error) {
      this.errorMessage = `Error fetching data: ${error.message}`;
      console.error('Error fetching data:', error);

    }
  },

};
</script>