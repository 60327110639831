<!-- Services.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin'])">
    <ServicesHeader @showAlert="showAlert" @showError="showError" />

    <div v-if="findDuplicates.length > 0" class="alert alert-warning">
      <i class="bi bi-exclamation-triangle-fill"></i> <strong>There are duplicate services:</strong> {{ findDuplicates.join(', ') }}
    </div>

    <div class="p-3 shadow-sm bg-white rounded">
      <div v-if="!isFetching('fetchingServices')">
        <!-- Table filters and search -->
        <div class="filters mb-1 p-2 ps-0 pe-0">
          <div class="d-flex align-items-center">
            <!-- Showing text, Pagination and Search -->
            <div class="d-flex align-items-center ms-auto">
              <!-- Showing text -->
              <div class="text-muted small me-3">{{ showingText }}</div>

              <!-- Pagination -->
              <VTPagination
                v-model:currentPage="currentPage"
                :totalPages="totalPages"
                :maxPageLinks="3"
                :boundaryLinks="false"
                class="me-2"
              >
                <template #next>
                  <span class="prevent-select">Next</span>
                </template>

                <template #previous>
                  <span class="prevent-select">Previous</span>
                </template>
              </VTPagination>

              <!-- Search -->
              <input v-model="filters.name.value" type="text" class="form-control form-control-sm" placeholder="Filter by Name" style="width: 450px;" />
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="table-responsive">
          <VTable :data="tableRows" :pageSize="pageSize" v-model:currentPage="currentPage" @totalPagesChanged="totalPages = $event" @stateChanged="handleStateChanged" :filters="filters" sortHeaderClass="sort-header" class="table custom-table">
            <template #head>
              <tr class="bg-light custom-row small">
                <!-- Index -->
                <th></th>

                <!-- Actions -->
                <th class="text-nowrap sticky-column" style="min-width: 100px;">Actions</th>

                <!-- Name -->
                <VTh sortKey="name" defaultSort="asc" class="text-nowrap" style="min-width: 100px;">Name</VTh>

                <!-- Description -->
                <VTh sortKey="notes" class="text-nowrap" style="min-width: 320px;">Description</VTh>

                <!-- Vendor Services -->
                <VTh sortKey="vendorServicesLookup" class="text-nowrap" style="min-width: 320px;">Vendor Services</VTh>

                <!-- Jobs -->
                <VTh sortKey="jobsLookup" class="text-nowrap" style="min-width: 320px;">Jobs</VTh>
              </tr>
            </template>
            <template #body="{rows}">
              <VTr v-for="(row, index) in rows" :key="index" :row="row" v-expandable class="custom-row small">
                <!-- Index -->
                <td class="text-nowrap align-middle text-end text-muted small">{{ (currentPage - 1) * pageSize + index + 1 }}</td>

                <!-- Actions -->
                <td class="text-nowrap align-middle sticky-column" style="">
                  <!-- Opens in new tab -->
                  <a :href="`/settings/services/${row.id}`" target="_blank" class="btn btn-sm btn-outline-secondary"><i class="bi bi-box-arrow-up-right small"></i> Open</a>

                  <!-- Opens in same tab -->
                  <!-- <router-link :to="`/settings/services/${row.id}`" class="btn btn-sm btn-outline-secondary">
                    <i class="bi bi-box-arrow-up-right small"></i> Open
                  </router-link> -->
                </td>

                <!-- Name -->
                <td class="text-nowrap align-middle sticky-column" style="min-width: 100px;">
                  <div class="text-truncate" style="max-width: 300px;">
                    {{ row.name }}
                  </div>
                </td>

                <!-- Description -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 300px;">
                    {{ row.description }}
                  </div>
                </td>

                <!-- Vendor Services -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 300px;">
                    {{ row.vendorServicesLookup }}
                  </div>
                </td>

                <!-- Jobs -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 300px;">
                    {{ row.jobsLookup }}
                  </div>
                </td>
              </VTr>
            </template>
          </VTable>

          <div v-if="totalFilteredItems === 0" class="text-center text-muted p-3">
            <p class="mb-0">No results found based on the current filters.</p>
          </div>
        </div>
      </div>

      <div v-else class="text-center text-muted p-5">
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <p class="">You are not authorized to see this page.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ServicesHeader from '@/components/ServicesHeader.vue';

export default {
  name: 'AppServices',
  emits: [
    'showAlert',
    'showError',
  ],
  components: {
    ServicesHeader,
  },

  data() {
    return {
      filters: {
        name: { value: '', keys: ['name'] },
      },

      totalPages: 1, // TODO: This gives a warning, but not sure how to fix. It doesn't alter the functionality though
      currentPage: 1,
      pageSize: 50,

      totalFilteredItems: 0,
    }
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',

      'getCurrencySymbol',
    ]),

    showingText() {
      const start = (this.currentPage - 1) * this.pageSize + 1;
      const end = Math.min(this.currentPage * this.pageSize, this.totalFilteredItems);
      return `Showing ${start}-${end} of ${this.totalFilteredItems}`;
    },

    tableRows() {
      return this.getProperty('services').map(service => {
        return {
          id: service.id,
          name: service.fields['Name'],
          description: service.fields['Description'],
          vendorServicesLookup: service.fields['Vendor Services Lookup'] ? service.fields['Vendor Services Lookup'].slice(0, 10).join(', ') + (service.fields['Vendor Services Lookup'].length > 10 ? '...' : '') : '',
          jobsLookup: service.fields['Jobs Lookup'] ? service.fields['Jobs Lookup'].slice(0, 10).join(', ') + (service.fields['Jobs Lookup'].length > 10 ? '...' : '') : '',
        };
      });
    },

    findDuplicates() {
      const nameCount = {};
      const duplicates = [];
      const originalNames = {};

      this.getProperty('services').forEach(service => {
        const originalName = service.fields['Name'];
        const name = originalName.toLowerCase(); // convert to lowercase for comparison
        originalNames[name] = originalName; // store the original name
        if (nameCount[name]) {
          nameCount[name]++;
        } else {
          nameCount[name] = 1;
        }
      });

      Object.keys(nameCount).forEach(name => {
        if (nameCount[name] > 1) {
          duplicates.push(originalNames[name]); // push the original name, not the lowercase version
        }
      });

      return duplicates;
    },
  },

  watch: { },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    handleStateChanged(tableState) {
      this.totalFilteredItems = tableState.rowsPrePagination.length;
    },
  },

  async mounted() {
    document.title = `Settings › Services`;
  },

  async created() {
    this.mixinCheckUserAuthentication();
  },
};
</script>