<!-- VendorDetailsServices.vue -->

<template>

  <div id="services" class="tab-pane">
    <div class="p-0 bg-white shadow-sm rounded">
      <div class="p-4 pb-2 mb-3">
        <div class="row align-items-center">
          <div class="col">
            <span class="fs-5 fw-semibold mb-3">Services ({{ vendorServices.length  }})</span>
          </div>
          <div class="col text-end" v-if="mixinUserInRoles(['Admin', 'VM'])">
            <button type="button" class="btn btn-sm btn-primary" @click="openCreateVendorServiceModal"><i class="bi bi-plus-lg"></i> Add Service</button>
          </div>
        </div>
      </div>

      <div v-if="!isFetching('fetchingVendorServices') && isDataFetched">
        <div v-if="vendorServices.length > 0">
          <div class="table-responsive">
            <VTable :data="tableRows" :pageSize="pageSize" v-model:currentPage="currentPage" @totalPagesChanged="totalPages = $event" sortHeaderClass="sort-header" class="table custom-table">
              <template #head>
                <tr class="custom-row small">
                  <th style="min-width: 50px;"></th>
                  <th v-if="mixinUserInRoles(['Admin', 'VM'])" class="text-nowrap" style="">Actions</th>
                  <VTh sortKey="service" class="text-nowrap" style="">Service</VTh>
                  <VTh defaultSort="asc" sortKey="languagePair" class="text-nowrap" style="">Language Pair</VTh>
                  <VTh sortKey="currency" class="text-nowrap text-end" style="">Currency</VTh>
                  <VTh sortKey="unit" class="text-nowrap" style="">Unit</VTh>
                  <VTh sortKey="unitPrice" class="text-nowrap text-end" style="">Unit Price</VTh>
                  <VTh sortKey="specialities" class="text-nowrap" style="">Specialities</VTh>
                </tr>
              </template>
              <template #body="{rows}">
                <VTr v-for="(row, index) in rows" :key="index" :row="row" v-expandable class="custom-row small">
                  <td class="text-nowrap align-middle text-end text-muted small">{{ (currentPage - 1) * pageSize + index + 1 }}</td>
                  <td v-if="mixinUserInRoles(['Admin', 'VM'])" class="text-nowrap align-middle">
                    <!-- Edit -->
                    <a
                      title="Edit"
                      @click="openUpdateVendorServiceModal(row.id)"
                      class="btn btn-sm btn-outline-secondary"
                      style="background: #fff !important;"
                    >
                      <i class="bi bi-pencil-fill"></i>
                    </a>

                    <!-- Delete -->
                    <a
                      title="Delete"
                      @click="openDeleteVendorServiceModal(row.id)"
                      class="btn btn-sm btn-outline-secondary ms-1"
                      style="background: #fff !important;"
                    >
                      <i class="bi bi-trash-fill"></i>
                    </a>
                  </td>
                  <td class="text-nowrap align-middle">{{ row.service }}</td>
                  <td class="text-nowrap align-middle">{{ row.languagePair.replace('>', '›') }}</td>
                  <td class="text-nowrap align-middle text-end"><span :class="['badge custom-badge opp-detail', mixinGetCurrencyBadgeClass(row.currency)]">{{ row.currency }}</span></td>
                  <td class="text-nowrap align-middle">{{ row.unit }}</td>
                  <td class="text-nowrap align-middle text-end">{{ getCurrencySymbol[row.currency] }}{{ row.unitPrice }}</td>
                  <td class="text-nowrap align-middle">{{ row.specialities }}</td>
                </VTr>
              </template>
            </VTable>
          </div>
        </div>

        <div v-else class="text-center text-muted p-5">
          No vendor services found.
        </div>
      </div>

      <div v-else class="d-flex justify-content-center p-5">
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Create Vendor Service -->
  <CreateVendorServiceModal ref="createVendorServiceModal" :vendorId="recordId" @showAlert="showAlert" @showError="showError" @fetchVendorServices="fetchRelatedRecords" />

  <!-- Update Vendor Service -->
  <UpdateVendorServiceModal ref="updateVendorServiceModal" :vendorServiceId="selectedVendorServiceId" @showAlert="showAlert" @showError="showError" @fetchVendorServices="fetchRelatedRecords" />

  <!-- Delete Vendor Service -->
  <DeleteVendorServiceModal ref="deleteVendorServiceModal" :vendorServiceId="selectedVendorServiceId" @showAlert="showAlert" @showError="showError" @fetchVendorServices="fetchRelatedRecords" />

</template>

<script>
import { mapGetters } from 'vuex';
import CreateVendorServiceModal from '@/components/CreateVendorServiceModal.vue';
import UpdateVendorServiceModal from '@/components/UpdateVendorServiceModal.vue';
import DeleteVendorServiceModal from '@/components/DeleteVendorServiceModal.vue';

export default {
  name: 'VendorDetailsServicesTab',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
    recordId: {
      type: String,
      required: true,
    },
  },
  components: {
    UpdateVendorServiceModal,
    CreateVendorServiceModal,
    DeleteVendorServiceModal,
  },

  data() {
    return {
      isDataFetched: false,

      totalPages: 1, // TODO: This gives a warning, but not sure how to fix. It doesn't alter the functionality though
      currentPage: 1,
      pageSize: 50,

      vendorServices: { },
      selectedVendorServiceId: '',
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',

      'getCurrencySymbol',
    ]),

    tableRows() {
      return this.vendorServices.map(row => {
        return {
          id: row.id,
          service: row.fields['Service Lookup'] ? row.fields['Service Lookup'].join(', ') : '',
          languagePair: row.fields['Language Pair Lookup'] ? row.fields['Language Pair Lookup'].join(', ') : '',
          currency: row.fields['Currency (from "Vendors")'] ? row.fields['Currency (from "Vendors")'].join(', ') : '',
          unit: row.fields['Unit Lookup'] ? row.fields['Unit Lookup'].join(', ') : '',
          unitPrice: row.fields['Unit Price'],
          specialities: row.fields['Specialities Lookup'] ? row.fields['Specialities Lookup'].join(', ') : '',
        };
      });
    },
  },

  watch: {

  },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    async openCreateVendorServiceModal() {
      this.$refs.createVendorServiceModal.openModal();
    },

    async openUpdateVendorServiceModal(vendorServiceId) {
      this.selectedVendorServiceId = vendorServiceId;
      this.$refs.updateVendorServiceModal.openModal();
    },

    async openDeleteVendorServiceModal(vendorServiceId) {
      this.selectedVendorServiceId = vendorServiceId;
      this.$refs.deleteVendorServiceModal.openModal();
    },

    async fetchRelatedRecords() {
      try {
        this.vendorServices = await this.$store.dispatch('fetchRelatedRecords', {
          parentTableName: 'Vendors',
          childTableName: 'Vendor Services',
          recordId: this.recordId,
          relatedFieldName: 'Vendor Services ID',
          stateVariable: 'vendorServices',
          fetchingStateVariable: 'fetchingVendorServices',
        });

        this.isDataFetched = true;

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error fetching related records: ${error.message}`);
        console.error(error);
      }
    }
  },

  async mounted() {
    await this.fetchRelatedRecords();
  },

  created() { },
};
</script>