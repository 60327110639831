<!-- ReusableContactsTable.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])">
    <div v-if="records.length > 0">
    <!-- Table filters and search -->
    <div class="filters mb-1 p-2 ps-0 pe-0">
      <div class="d-flex align-items-center">
        <!-- Showing text, Pagination and Search -->
        <div :class="['d-flex align-items-center', { 'ms-auto': showFilters }]">
          <!-- Showing text -->
          <div class="text-muted small me-3">{{ showingText }}</div>

          <!-- Pagination -->
          <VTPagination
            v-model:currentPage="currentPage"
            :totalPages="totalPages"
            :maxPageLinks="3"
            :boundaryLinks="false"
            class="me-2"
          >
            <template #next>
              <span class="prevent-select">Next</span>
            </template>

            <template #previous>
              <span class="prevent-select">Previous</span>
            </template>
          </VTPagination>

          <!-- Search -->
          <input v-model="filters.name.value" type="text" class="form-control form-control-sm" placeholder="Filter by Name or Email" style="width: 500px;" />
        </div>
      </div>
    </div>

    <!-- Checked rows actions -->
    <div v-if="mixinUserInRoles(['Admin'])">
      <div v-if="selectedRows.length > 0" class="p-2 border-bottom">
        <span class="text-muted small">{{ selectedRows.length }} {{ selectedRows.length > 1 ? 'rows' : 'row' }} selected.</span>
      </div>
    </div>

    <div class="table-responsive">
      <VTable
        :data="tableRows"
        :pageSize="pageSize"
        v-model:currentPage="currentPage"
        @totalPagesChanged="totalPages = $event"
        @stateChanged="handleStateChanged"
        :filters="filters"
        sortHeaderClass="sort-header"
        class="table custom-table border"
        selectionMode="multiple"
        :selectOnClick="false"
        selectedClass="bg-light"
      >
        <!-- Commented because of known issue with "select all" checkbox -->
        <!-- <template #head="{ allRowsSelected, toggleAllRows }"> -->
        <template #head="">
          <tr class="custom-row small">
            <!-- <th class="small"> -->
              <!-- Commented because of known issue with "select all" checkbox -->
              <!-- <input type="checkbox" class="form-check-input" :checked="allRowsSelected" @change="toggleAllRows" /> -->
            <!-- </th> -->
            <!-- <th class="small" style="min-width: 50px;"></th> -->
            <th class="text-nowrap sticky-column small"></th>
            <VTh sortKey="name" class="text-nowrap small">Name</VTh>
            <VTh sortKey="title" class="text-nowrap small">Title</VTh>
            <VTh v-if="mixinUserInRoles(['Admin'])" sortKey="email" class="text-nowrap small">Email</VTh>
            <VTh sortKey="didAfterSales" class="text-nowrap text-end small">Did After Sales?</VTh>
            <VTh sortKey="stage" class="text-nowrap small">Contact Stage</VTh>
            <VTh sortKey="companies" class="text-nowrap small">Companies</VTh>
          </tr>
        </template>
        <template #body="{rows}">
          <VTr
              v-for="(row, index) in rows"
              :key="index"
              :row="row"
              v-slot="{ }"
              v-expandable
              class="custom-row small"
            >

            <!-- Checkbox -->
            <!-- <td class="align-middle">
              <input type="checkbox" class="form-check-input" :checked="isSelected" @change.stop="toggle" />
            </td> -->

            <!-- Index -->
            <!-- <td class="text-nowrap align-middle text-end text-muted small">{{ (currentPage - 1) * pageSize + index + 1 }}</td> -->

            <!-- Actions -->
            <td class="text-nowrap align-middle sticky-column border-end" @click.stop>
              <!-- Opens in new tab -->
              <a :href="`/contacts/${row.id}`" target="_blank" class="btn btn-sm btn-outline-secondary"><i class="bi bi-box-arrow-up-right small"></i> Open</a>

              <!-- Opens in same tab -->
              <!-- <router-link :to="`/contacts/${row.id}`" class="btn btn-sm btn-outline-secondary">
                <i class="bi bi-box-arrow-up-right small"></i> Open
              </router-link> -->
            </td>

            <!-- Name -->
            <td class="text-nowrap align-middle">{{ row.name }}</td>

            <!-- Title -->
            <td class="text-nowrap align-middle">{{ row.title }}</td>

            <!-- Email -->
            <td v-if="mixinUserInRoles(['Admin'])" class="text-nowrap align-middle">{{ row.email }}</td>

            <!-- Did After Sales? -->
            <td class="text-nowrap align-middle text-end">{{ row.didAfterSales }}</td>

            <!-- Contact Stage -->
            <td class="text-nowrap align-middle">{{ row.stage }}</td>

            <!-- Companies -->
            <td class="text-nowrap align-middle">{{ row.companies }}</td>
          </VTr>
        </template>
      </VTable>

      <div v-if="totalFilteredItems === 0" class="text-center text-muted p-3">
        <p class="mb-0">No results found based on the current filters.</p>
      </div>
    </div>
    </div>

    <div v-else class="text-center text-muted p-5">
      No contacts found.
    </div>
  </div>

  <div v-else>
    <p class="p-4">You are not authorized to see this page.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReusableContactsTable',

  emits: [
    'showAlert',
    'showError',
  ],

  props: {
    records: {
      type: Array,
      required: true,
    },

    showFilters: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      filters: {
        name: { value: '', keys: ['name', 'email'] },
      },

      selectedRows: [],

      totalPages: 1, // TODO: This gives a warning, but not sure how to fix. It doesn't alter the functionality though
      currentPage: 1,
      pageSize: 50,

      totalFilteredItems: 0,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',
    ]),

    showingText() {
      const start = (this.currentPage - 1) * this.pageSize + 1;
      const end = Math.min(this.currentPage * this.pageSize, this.totalFilteredItems);
      return `Showing ${start}-${end} of ${this.totalFilteredItems}`;
    },

    tableRows() {
      return this.records.map(row => {
        return {
          id: row.id,
          name: row.fields['Name'],
          title: row.fields['Title'],
          email: row.fields['Email'],
          didAfterSales: row.fields['Did After Sales?'],
          stage: row.fields['Contact Stage'],
          companies: row.fields['Companies Lookup'] ? row.fields['Companies Lookup'].join(', ') : '',
        };
      });
    },
  },

  watch: { },

  methods: {
    handleStateChanged(tableState) {
      this.totalFilteredItems = tableState.rowsPrePagination.length;
      this.selectedRows = tableState.selectedRows;
    },
  },

  created() { },
};
</script>