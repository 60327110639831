<!-- OppDetailsHeader.vue -->

<template>

  <div v-if="!isFetching('fetchingOpp')">
    <div class="d-flex mb-3 border-bottom align-items-end">
      <div class="col-4 d-flex align-items-end">
        <div class="p-2 ps-0">

          <div class="fs-4 fw-semibold mb-1">
            <div v-if="record.fields['Title']">
              <div class="text-truncate" style="max-width: 600px;">
                <i class="bi bi-bookmark-fill"></i> {{ record.fields['Title'] }}
              </div>
            </div>

            <div v-else>
              <i class="bi bi-bookmark-fill"></i> Untitled
            </div>
          </div>

          <div class="mb-1 small">
            <i class="bi bi-stars"></i> {{ record.fields['Quote No.'] }}<span v-if="record.fields['OID']">-{{ record.fields['OID'] }}</span>
            &nbsp;&nbsp;<i class="bi bi-building-fill"></i> <a :href="`/companies/${record.fields['Company ID']}`" target="_blank">{{ record.fields['Company Lookup'] ? record.fields['Company Lookup'].join(', ') : '' }}</a>
            &nbsp;&nbsp;<i class="bi bi-person-circle"></i> <a :href="`/contacts/${record.fields['Contact ID']}`" target="_blank">{{ record.fields['Contact Lookup'] ? record.fields['Contact Lookup'].join(', ') : '' }}</a>
          </div>

        </div>
      </div>

      <!-- Stats: Revenue -->
      <div class="col-2 text-end ms-auto">
        <div class="p-2">
          <p class="mb-0 text-muted">Revenue</p>
          <div class="fs-5 mb-0 fw-semibold">{{ record.fields['Currency Lookup'] ? getCurrencySymbol[record.fields['Currency Lookup']] : '' }}{{ mixinFormatNumber(record.fields['4 - Total in Opp Currency']) }}</div>
        </div>
      </div>

      <!-- Stats: Vendor Costs -->
      <div v-if="mixinUserInRoles(['Admin'])" class="col-2 text-end">
        <div class="p-2">
          <p class="mb-0 text-muted">Vendor Costs in Euro</p>
          <div class="fs-5 mb-0 fw-semibold">€{{ mixinFormatNumber(record.fields['10 - Costs in €']) }}</div>
        </div>
      </div>

      <!-- Stats: Profit -->
      <div v-if="mixinUserInRoles(['Admin'])" class="col-2 text-end">
        <div class="p-2">
          <p class="mb-0 text-muted">Profit in Euro</p>
          <div class="fs-5 mb-0 fw-semibold">€{{ mixinFormatNumber(record.fields['11 - Profit in €']) }}</div>
        </div>
      </div>

      <!-- Stats: Profit Margin -->
      <div v-if="mixinUserInRoles(['Admin', 'PM'])" class="col-2 text-end">
        <div class="p-2">
          <p class="mb-0 text-muted">Profit Margin</p>
          <div class="fs-5 mb-0 fw-semibold" :class="mixinGetPercentageClass(record.fields['12 - Margin'])">{{ mixinFormatMargin(record.fields['12 - Margin']) }}</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OppDetailsHeader',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
    record: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {

    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
      'getCurrencySymbol',
    ]),
  },

  watch: { },

  methods: { },

  created() { },
};
</script>