// main.js

import { createApp } from 'vue';
import Clerk from '@clerk/clerk-js';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/master.css';
import 'vue-select/dist/vue-select.css';
import globalMixin from '@/mixins/globalMixin';
import vSelect from 'vue-select';
import SmartTable from 'vuejs-smart-table';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App);

app.component('v-select', vSelect);
app.component('VueDatePicker', VueDatePicker);

app.mixin(globalMixin);

app.directive('expandable', {
  beforeMount: function (el) {
    el.addEventListener('click', function(event) {
      // If the target of the click event is a checkbox, stop the function
      if (event.target.type === 'checkbox') {
        return;
      }

      for (let child of el.querySelectorAll('.text-truncate')) {
        child.classList.toggle('text-expand');
      }
    });
  }
});

async function initializeClerk() {
  const clerkFrontendApi = process.env.VUE_APP_CLERK_PUBLISHABLE_KEY;
  const clerk = new Clerk(clerkFrontendApi);
  await clerk.load({
    // Set load options here...
  });
  app.config.globalProperties.$clerk = clerk; // Add Clerk to Vue prototype
}

initializeClerk().then(() => {
  app
    .use(router)
    .use(SmartTable)
    .use(store)
    .mount('#app');
});