<template>
  <div>
    <h3 v-if="this.$clerk.user">👋 Hi, {{ this.$clerk.user.firstName }}!</h3>

    <p>Welcome to the Language Buró App. This page is currently under construction.</p>

    <div class="row">
      <div class="col-6">
        <!-- <ChartRevenueCompaniesAcquired /> -->
      </div>

      <div class="col-6">
        <!-- <ChartTotalCompaniesAcquired /> -->
      </div>
    </div>

    <!-- <iframe src="https://giphy.com/embed/fVeAI9dyD5ssIFyOyM" width="480" height="360" frameBorder="0" class="giphy-embed" allowFullScreen></iframe> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import ChartRevenueCompaniesAcquired from '@/components/ChartRevenueCompaniesAcquired.vue';
// import ChartTotalCompaniesAcquired from '@/components/ChartTotalCompaniesAcquired.vue';

export default {
  name: 'AppDashboard',
  components: {
    // ChartRevenueCompaniesAcquired,
    // ChartTotalCompaniesAcquired,
  },

  data() { },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  async mounted() {
    document.title = 'Dashboard';
  },

  async created() {
    this.mixinCheckUserAuthentication();
  },
}
</script>