<!-- CompanyDetailsHeader.vue -->

<template>

  <div v-if="!isFetching('fetchingCompany')">
    <div class="d-flex mb-3 border-bottom align-items-end">
      <div class="col-4 d-flex align-items-end">
        <div class="p-2 ps-0">
          <div class="fs-3 mb-0 fw-semibold"><i class="bi bi-building-fill"></i> {{ record.fields['Name'] }}</div>
        </div>
      </div>

      <template v-if="!isPublicMode">
        <!-- Stats: Revenue -->
        <div v-if="mixinUserInRoles(['Admin'])" class="col-2 text-end ms-auto">
          <div class="p-2">
            <p class="mb-0 text-muted">Total Opps Won</p>
            <div class="fs-5 mb-0 fw-semibold">€{{ mixinFormatNumber(record.fields['6 - Opps Won in €']) }}</div>
          </div>
        </div>

        <!-- Stats: Profit -->
        <div v-if="mixinUserInRoles(['Admin'])" class="col-2 text-end">
          <div class="p-2">
            <p class="mb-0 text-muted">Total Profit</p>
            <div class="fs-5 mb-0 fw-semibold">€{{ mixinFormatNumber(record.fields['8 - Profit in €']) }}</div>
          </div>
        </div>

        <!-- Stats: Profit Margin -->
        <div v-if="mixinUserInRoles(['Admin'])" class="col-2 text-end">
          <div class="p-2">
            <p class="mb-0 text-muted">Profit Margin</p>
            <div class="fs-5 mb-0 fw-semibold" :class="mixinGetPercentageClass(record.fields['9 - Margin'])">{{ mixinFormatMargin(record.fields['9 - Margin']) }}</div>
          </div>
        </div>

        <!-- Stats: % Opps Won -->
        <div v-if="mixinUserInRoles(['Admin'])" class="col-2 text-end">
          <div class="p-2">
            <p class="mb-0 text-muted">Opps Won</p>
            <div class="fs-5 mb-0 fw-semibold">{{ mixinFormatMargin(record.fields['4 - % Opps Won']) }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CompanyDetailsHeader',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
    record: {
      type: Object,
      required: true,
    },

    isPublicMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return { };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  watch: { },

  methods: { },

  created() { },
};
</script>