<!-- UnitDetails.vue -->

<template>

  <!-- Breadcrumb -->
  <!-- <nav v-if="isDataFetched" style="--bs-breadcrumb-divider: '&rsaquo;';" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item small"><router-link to="/settings/units">Units</router-link></li>
      <li class="breadcrumb-item small active" aria-current="page">{{ record.fields['Name'] }}</li>
    </ol>
  </nav> -->

  <UnitDetailsHeader v-if="isDataFetched" :record="record" @showAlert="showAlert" @showError="showError" />

  <!-- Secondary actions -->
  <div class="col d-flex justify-content-end align-items-center">
    <a v-if="isDataFetched && !record.fields['Jobs']" @click="openDeleteUnitModal()" :disabled="isDeleting('deletingUnit')" class="btn btn-sm btn-danger mb-3"><i class="bi bi-trash me-1"></i> Delete Unit</a>
  </div>

  <!-- Tabs -->
  <div class="tab-content">
    <!-- Unit Details -->
    <div id="unitDetails" class="tab-pane show active">
      <div v-if="!isFetching('fetchingUnit') && isDataFetched">
        <div class="row">
          <div class="col-12">
            <!-- Section: One -->
            <div class="p-0 bg-white shadow-sm rounded">
              <!-- Section header  -->
              <div class="p-4 pb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <span class="fs-5 fw-semibold mb-3">Unit Details</span>
                  </div>
                  <div class="col text-end">
                    <div v-if="!sectionEditMode.sectionOne">
                      <button class="btn btn-sm btn-primary" @click.prevent="toggleSectionEditMode('sectionOne')"><i class="bi bi-pencil-fill me-1"></i> Edit</button>
                    </div>
                    <div v-else>
                      <button class="btn btn-sm btn-primary" @click.prevent="saveChangesSection('sectionOne')" :disabled="isUpdating('updatingUnit')">

                        <div v-if="isUpdating('updatingUnit')">
                          <div class="spinner-border spinner-border-sm" role="status"></div> Loading...
                        </div>

                        <div v-else>
                          <i class="bi bi-check"></i> Save
                        </div>
                      </button>

                      <button class="btn btn-sm btn-secondary ms-2" @click="cancelSectionEditMode('sectionOne')"><i class="bi bi-x"></i> Cancel</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="p-4 pt-0">
                <!-- Name -->
                <div class="d-flex pt-3 pb-3 align-items-center">
                  <div class="col-3 text-muted small">
                    Name
                  </div>

                  <div class="col-5">
                    <div v-if="!sectionEditMode.sectionOne">
                      {{ record.fields['Name'] }}
                    </div>

                    <div v-else>
                      <input type="text" class="form-control" v-model="record.fields['Name']">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="d-flex justify-content-center p-5">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete Unit -->
  <DeleteUnitModal ref="deleteUnitModal" :unitId="recordId" @showAlert="showAlert" @showError="showError" />

</template>

<script>
import { mapGetters } from 'vuex';
import UnitDetailsHeader from '@/components/UnitDetailsHeader.vue';
import DeleteUnitModal from '@/components/DeleteUnitModal.vue';

export default {
  name: 'UnitDetails',
  emits: [
    'showAlert',
    'showError',
  ],
  components: {
    UnitDetailsHeader,
    DeleteUnitModal,
  },

  data() {
    return {
      isDataFetched: false,

      sectionEditMode: {
        sectionOne: false,
      },

      recordId: this.$route.params.id,

      record: { },
      tempRecord: null,

      tableName: 'Units',
      stateVariable: 'unit',
      creatingStateVariable: 'creatingUnit',
      fetchingStateVariable: 'fetchingUnit',
      updatingStateVariable: 'updatingUnit',
      deletingStateVariable: 'deletingUnit',
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  watch: { },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    async openDeleteUnitModal() {
      this.$refs.deleteUnitModal.openModal();
    },

    toggleSectionEditMode(section) {
      this.sectionEditMode[section] = !this.sectionEditMode[section];

      if (this.sectionEditMode[section]) {
        this.tempRecord = JSON.parse(JSON.stringify(this.record));
      }
    },

    cancelSectionEditMode(section) {
      this.sectionEditMode[section] = false;
      this.record = JSON.parse(JSON.stringify(this.tempRecord));
    },

    saveChangesSection() {
      this.updateRecord();
    },

    createUpdatedFields() {
      return {
        'Name': this.record.fields['Name'],
      };
    },

    validateFields() {
      let errors = [];

      if (!this.record.fields['Name']) { errors.push('"Name" is required.'); }

      return errors;
    },

    async fetchRecord() {
      try {
        this.record = await this.$store.dispatch('fetchRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          stateVariable: this.stateVariable,
          fetchingStateVariable: this.fetchingStateVariable,
        });

        this.isDataFetched = true;

      } catch (error) {
        this.$emit('showError', error.message);
        console.error(error);
      }
    },

    async updateRecord() {
      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        return;
      }

      try {
        await this.$store.dispatch('updateRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          updatedFields: this.createUpdatedFields(),
          updatingStateVariable: this.updatingStateVariable,
        });

        Object.keys(this.sectionEditMode).forEach((key) => {
          this.sectionEditMode[key] = false;
        });

        this.$emit('showAlert', 'success', 'Successfully updated record');
        await this.fetchRecord();

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error updating record: ${error.message}`);
        console.error(error);
      }
    },
  },

  async mounted() {
    await this.fetchRecord();
    const name = this.record.fields['Name'];
    document.title = `Units › ${name}`;
  },

  created() {
    this.mixinCheckUserAuthentication();
  },
};
</script>