<!-- VendorDetailsServiceModal.vue -->

<template>

  <div class="modal" id="editRecordModal" tabindex="-1" aria-labelledby="editRecordModalLabel" aria-hidden="true" ref="editRecordModal">
    <div class="modal-dialog">

      <div class="modal-content shadow shadow-lg">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">Edit Service</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div v-if="!isFetching('fetchingVendorService') && isDataFetched">
            <!-- Service -->
            <div class="row align-items-center pt-3">
              <div class="col-4">
                Service
              </div>

              <div class="col-8">
                <v-select v-if="!isFetching('fetchingServices')" v-model="selectedService" :options="allServicesOptions" placeholder="Select..." :clearable="false">
                  <template v-slot:option="option">
                    <div class="text-wrap">{{ option.label }}</div>
                  </template>

                  <template v-slot:open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                    </span>
                  </template>
                </v-select>

                <div v-else class="d-inline-block">
                  <div class="spinner-border spinner-border-sm text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Language Pair -->
            <div class="row align-items-center pt-3">
              <div class="col-4">
                Language Pair
              </div>

              <div class="col-8">
                <v-select v-if="!isFetching('fetchingLanguagePairs')" v-model="selectedLanguagePair" :options="allLanguagePairsOptions" placeholder="Select..." :clearable="false">
                  <template v-slot:option="option">
                    <div class="text-wrap">{{ option.label }}</div>
                  </template>

                  <template v-slot:open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                    </span>
                  </template>
                </v-select>

                <div v-else class="d-inline-block">
                  <div class="spinner-border spinner-border-sm text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Unit -->
            <div class="row align-items-center pt-3">
              <div class="col-4">
                Unit
              </div>

              <div class="col-8">
                <v-select v-if="!isFetching('fetchingUnits')" v-model="selectedUnit" :options="allUnitsOptions" placeholder="Select..." :clearable="false">
                  <template v-slot:open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                    </span>
                  </template>
                </v-select>

                <div v-else class="d-inline-block">
                  <div class="spinner-border spinner-border-sm text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Unit Price -->
            <div class="row align-items-center pt-3">
              <div class="col-4">
                Unit Price
                <div class="form-text">
                  In {{ record.fields['Currency (from "Vendors")'] ? record.fields['Currency (from "Vendors")'].join(', ') : '' }}
                </div>
              </div>

              <div class="col-8">
                <input class="form-control" type="number" v-model="record.fields['Unit Price']">
              </div>
            </div>

            <!-- Specialities -->
            <div class="row align-items-center pt-3">
              <div class="col-4">
                Specialities
              </div>

              <div class="col-8">
                <v-select v-if="!isFetching('fetchingSpecialities')" v-model="selectedSpecialities" :options="allSpecialitiesOptions" placeholder="Select..." multiple>
                  <template v-slot:option="option">
                    <div class="text-wrap">{{ option.label }}</div>
                  </template>

                  <template v-slot:open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                    </span>
                  </template>
                </v-select>

                <div v-else class="d-inline-block">
                  <div class="spinner-border spinner-border-sm text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="d-flex justify-content-center p-5">
              <div class="spinner-border text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span v-if="isUpdating('updatingVendorService')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-primary" @click="updateRecord" :disabled="isUpdating('updatingVendorService') || isSubmitting">Update</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';

export default {
  name: 'UpdateVendorServiceModal',
  emits: [
    'showAlert',
    'showError',
    'fetchVendorServices',
  ],
  props: {
    vendorServiceId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isDataFetched: false,
      isSubmitting: false,

      record: { },
      modalInstance: null
    };
  },

  computed: {
    ...mapGetters([
      'allLanguagePairsOptions',
      'allServicesOptions',
      'allUnitsOptions',
      'allSpecialitiesOptions',

      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),

    selectedService: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Service', optionsArrayName: 'allServicesOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Service', value: value, optionsArrayName: 'allServicesOptions' });
      }
    },

    selectedLanguagePair: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Language Pair', optionsArrayName: 'allLanguagePairsOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Language Pair', value: value, optionsArrayName: 'allLanguagePairsOptions' });
      }
    },

    selectedUnit: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Unit', optionsArrayName: 'allUnitsOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Unit', value: value, optionsArrayName: 'allUnitsOptions' });
      }
    },

    selectedSpecialities: {
      get() {
        return this.mixinGetSelectedArrayField({ fieldName: 'Specialities', optionsArrayName: 'allSpecialitiesOptions' });
      },
      set(value) {
        this.mixinSetSelectedArrayField({ fieldName: 'Specialities', value: value });
      }
    },
  },

  watch: {
    async vendorServiceId(newVendorServiceId) {
      if (newVendorServiceId) {

        this.isDataFetched = false;

        try {
          this.record = await this.$store.dispatch('fetchRecord', {
            tableName: 'Vendor Services',
            recordId: newVendorServiceId,
            stateVariable: 'vendorService',
            fetchingStateVariable: 'fetchingVendorService',
          });

          // console.log(this.record); // debug
          this.isDataFetched = true;

        } catch (error) {
          this.$emit('showAlert', 'danger', `Error fetching vendor service: ${error.message}`);
          console.error(error);
        }
      }
    },
  },

  methods: {
    openModal() {
      if (!this.modalInstance) {
        const modalElement = this.$refs.editRecordModal;
        this.modalInstance = new bootstrap.Modal(modalElement);
      }
      this.modalInstance.show();
    },

    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },

    createUpdatedFields() {
      return {
        'Service': this.record.fields['Service'],
        'Language Pair': this.record.fields['Language Pair'],
        'Unit': this.record.fields['Unit'],
        'Unit Price': this.record.fields['Unit Price'],
        'Specialities': this.record.fields['Specialities'],
      };
    },

    validateFields() {
      let errors = [];

      if (!this.record.fields['Unit Price']) { errors.push('"Unit Price" is required.'); }
      if (this.record.fields['Specialities'].length === 0) { errors.push('At least one speciality is required.'); }

      return errors;
    },

    async updateRecord() {
      this.isSubmitting = true;

      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        this.isSubmitting = false;
        return; // Exit the function early if there are validation errors
      }

      try {
        await this.$store.dispatch('updateRecord', {
          tableName: 'Vendor Services',
          recordId: this.vendorServiceId,
          updatedFields: this.createUpdatedFields(),
          updatingStateVariable: 'updatingVendorService',
        });

        this.$emit('showAlert', 'success', 'Successfully updated record');
        this.closeModal();
        this.$emit('fetchVendorServices');

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error updating record: ${error.message}`);
        console.error(error);

      } finally {
        this.isSubmitting = false;

      }

    },
  },

  async mounted() {

  },

  created() { },
};
</script>