// services/airtable.js

import axios from 'axios';

const personalAccessToken = process.env.VUE_APP_AIRTABLE_PERSONAL_ACCESS_TOKEN;
const baseId = process.env.VUE_APP_AIRTABLE_BASE_ID;

const airtableInstance = axios.create({
  baseURL: `https://api.airtable.com/v0/${baseId}`,
  headers: {
    'Authorization': `Bearer ${personalAccessToken}`,
    'Content-Type': 'application/json',
  },
});

// Fetches records based on a table and a filter
export const fetchAllRecordsFromTable = async (tableName, filter = null) => {
  const records = [];
  const pageSize = 100;

  // console.log('tablename from fetchAllRecordsFromTable:', tableName);
  // console.log('filter from fetchAllRecordsFromTable:', filter);
  const fetchPage = async (offset) => {
    const response = await airtableInstance.get(`/${tableName}`, {
      params: {
        filterByFormula: filter,
        pageSize: pageSize,
        offset: offset,
      },
    });

    records.push(...response.data.records);

    if (response.data.offset) {
      await fetchPage(response.data.offset);
    }
  };

  await fetchPage(null);
  return records;
};

// DONE Error handling
// Creates records
export async function createRecords(tableName, newRecords) {
  try {
    const formattedData = {
      records: newRecords.map(record => ({ fields: record })),
    };

    const response = await airtableInstance.post(`/${tableName}`, formattedData);
    return response.data;

  } catch (error) {
    console.error(`Error creating records in table ${tableName}:`, error);
    throw error;
  }
}

// DONE Error handling
// Fetches records from a table
export async function fetchRecords(tableName, filter = null) {
  try {
    const response = await fetchAllRecordsFromTable(tableName, filter);
    return response; // don't change this to response.data, because fetchAllRecordsFromTable trims the response from Airtable before

  } catch (error) {
    console.error(`Error fetching records from table ${tableName}:`, error);
    throw error;

  }
}

// DONE Error handling
// Fetches related records
export async function fetchRelatedRecords(parentTableName, childTableName, recordId, relatedFieldName) {
  try {
    // Step 1: Fetch the parent record and extract linked child record IDs
    const parentFilter = `RECORD_ID()='${recordId}'`;
    const parentRecord = await fetchAllRecordsFromTable(parentTableName, parentFilter);

    const relatedIds = parentRecord[0]?.fields[relatedFieldName] || [];

    // Step 2: Fetch the child records using the extracted IDs
    let childRecords = [];

    if (relatedIds.length > 0) {
      const batchSize = 50; // Adjust this value based on your needs
      for (let i = 0; i < relatedIds.length; i += batchSize) {
        const batchIds = relatedIds.slice(i, i + batchSize);
        const childFilter = `OR(${batchIds.map(id => `RECORD_ID()='${id}'`).join(',')})`;
        const batchRecords = await fetchAllRecordsFromTable(childTableName, childFilter);
        childRecords.push(...batchRecords);
      }
    }

    return childRecords;

  } catch (error) {
    console.error(`Error fetching related records from parent table ${parentTableName} to child table ${childTableName} with record ID: ${recordId}`, error);
    throw error;

  }
}

// DONE Error handling
// Creates a record
export async function createRecord(tableName, newRecord) {
  try {
    const formattedData = {
      records: [
        {
          fields: {
            ...newRecord,
          },
        },
      ],
    };

    const response = await airtableInstance.post(`/${tableName}`, formattedData);
    return response.data;

  } catch (error) {
    console.error(`Error creating record in table ${tableName}:`, error);
    throw error;

  }
}

// DONE Error handling
// Fetches a record
export async function fetchRecord(tableName, recordId) {
  try {
    const response = await airtableInstance.get(`/${tableName}/${recordId}`);
    return response.data;

  } catch (error) {
    console.error(`Error fetching record in table ${tableName}:`, error);
    throw error;

  }
}

// DONE Error handling
// Updates a record
export async function updateRecord(tableName, recordId, updatedFields) {
  try {
    const formattedData = {
      records: [
        {
          id: recordId,
          fields: updatedFields,
        },
      ],
    };

    const response = await airtableInstance.patch(`/${tableName}`, formattedData);
    return response.data;

  } catch (error) {
    console.error(`Error updating record in table ${tableName}:`, error);
    throw error;

  }
}

// DONE Error handling
// Deletes a record
export async function deleteRecord(tableName, recordId) {
  try {
    const response = await airtableInstance.delete(`/${tableName}/${recordId}`);
    return response.data;

  } catch (error) {
    console.error(`Error deleting record in table ${tableName}:`, error);
    throw error;

  }
}