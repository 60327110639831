<!-- ContactDetails.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])">
    <!-- Breadcrumb -->
    <!-- <nav v-if="isDataFetched" style="--bs-breadcrumb-divider: '&rsaquo;';" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item small"><router-link to="/contacts">Contacts</router-link></li>
        <li class="breadcrumb-item small active" aria-current="page">{{ record.fields['Name'] }}</li>
      </ol>
    </nav> -->

    <ContactDetailsHeader v-if="isDataFetched" :record="record" @showAlert="showAlert" @showError="showError" />

    <!-- Navigation -->
    <div class="row mb-3">
      <!-- Tabs -->
      <div class="col">
        <ul class="nav nav-pills" id="tabNav" v-show="tabsVisible">
          <li class="nav-item small">
            <a class="nav-link active" data-bs-toggle="tab" href="#contactDetails">Contact Details</a>
          </li>

          <li class="nav-item small">
            <a class="nav-link" data-bs-toggle="tab" href="#companies">Companies</a>
          </li>

          <li class="nav-item small">
            <a class="nav-link" data-bs-toggle="tab" href="#opportunities">Opportunities</a>
          </li>

          <li class="nav-item small">
            <a class="nav-link" data-bs-toggle="tab" href="#jobs">Jobs</a>
          </li>

          <!-- <li class="nav-item small">
            <a class="nav-link" data-bs-toggle="tab" href="#activity">Email Activity</a>
          </li> -->
        </ul>
      </div>

      <!-- Secondary actions -->
      <div class="col d-flex justify-content-end align-items-center">
        <a v-if="isDataFetched && !record.fields['Opps']" @click="openDeleteContactModal()" :disabled="isDeleting('deletingContact')" class="btn btn-sm btn-danger"><i class="bi bi-trash me-1"></i> Delete Contact</a>
      </div>
    </div>

    <!-- Tabs -->
    <div class="tab-content">
      <!-- Contact Details -->
      <div id="contactDetails" class="tab-pane show active">
        <div v-if="!isFetching('fetchingContact') && isDataFetched">
          <div class="row">
            <div class="col-9">
              <div class="p-0 bg-white shadow-sm rounded mb-3">
                <!-- Section header  -->
                <div class="p-4 pb-2">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="fs-5 fw-semibold mb-3">Contact Details</span>
                    </div>
                    <div class="col text-end">
                      <div v-if="!sectionEditMode.sectionOne">
                        <button class="btn btn-sm btn-primary" @click.prevent="toggleSectionEditMode('sectionOne')"><i class="bi bi-pencil-fill me-1"></i> Edit</button>
                      </div>
                      <div v-else>
                        <button class="btn btn-sm btn-primary" @click.prevent="saveChangesSection('sectionOne')" :disabled="isUpdating('updatingContact') || isFetching('fetchingCompanies')">

                          <div v-if="isUpdating('updatingContact')">
                            <div class="spinner-border spinner-border-sm" role="status"></div> Loading...
                          </div>

                          <div v-else>
                            <i class="bi bi-check"></i> Save
                          </div>
                        </button>

                        <button class="btn btn-sm btn-secondary ms-2" @click="cancelSectionEditMode('sectionOne')"><i class="bi bi-x"></i> Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Fields -->
                <div class="p-4 pt-0">
                  <!-- Name -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Name
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Name'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['Name']">
                      </div>
                    </div>
                  </div>

                  <!-- Title -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Title
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Title'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['Title']">
                      </div>
                    </div>
                  </div>

                  <!-- Email -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Email
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Email'] }}
                      </div>

                      <div v-else>
                        <input type="email" class="form-control" v-model="record.fields['Email']">
                      </div>
                    </div>
                  </div>

                  <!-- Did After Sales? -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Did After Sales?
                    </div>

                    <div class="col-2">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Did After Sales?'] }}
                      </div>

                      <div v-else>
                        <v-select v-model="record.fields['Did After Sales?']" :options="['Yes', 'No']" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>

                  <!-- Contact Stage -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Contact Stage
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Contact Stage'] }}
                      </div>

                      <div v-else>
                        <v-select v-model="selectedContactStage" :options="allContactStagesOptions" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>

                  <!-- Companies -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Companies
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Companies Lookup'] ? record.fields['Companies Lookup'].join(', ') : '' }}
                      </div>

                      <div v-else>
                        <div v-if="!isFetching('fetchingCompanies')">
                          <v-select v-model="selectedCompanies" :options="allCompaniesOptions" placeholder="Select..." multiple>
                            <template v-slot:open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                              </span>
                            </template>
                          </v-select>

                          <div class="form-text">
                            Deselecting a company will remove the contact from that company. If you select another company, the contact will be added to that company.
                          </div>
                        </div>

                        <div v-else class="d-inline-block">
                          <div class="spinner-border spinner-border-sm text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Notes -->
                  <div class="d-flex pt-3 pb-3 align-items-center">
                    <div class="col-3 text-muted small">
                      Notes
                    </div>

                    <div class="col-9" style="white-space: pre-line;">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Notes'] }}
                      </div>
                      <div v-else>
                        <textarea class="form-control" rows="3" v-model="record.fields['Notes']"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-3 pt-4">
              <div class="section mb-3 border-bottom pb-3">
                <div class="text-muted small">Last Order</div>
                <div class="mt-1">
                  <div v-if="record.fields['Last Order']">
                    {{ mixinFormatDate(record.fields['Last Order']) }}
                    ({{ calculateDaysPassed(record.fields['Last Order']) }} days ago)
                  </div>
                  <div v-else>
                    No orders found.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="d-flex justify-content-center p-5">
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Companies Tab -->
      <ContactDetailsCompaniesTab ref="contactDetailsCompaniesTab" :recordId="recordId" @showAlert="showAlert" @showError="showError" />

      <!-- Opportunities Tab -->
      <ContactDetailsOpportunitiesTab ref="contactDetailsOpportunitiesTab" :recordId="recordId" @showAlert="showAlert" @showError="showError" />

      <!-- Jobs Tab -->
      <ContactDetailsJobsTab ref="contactDetailsJobsTab" :recordId="recordId" @showAlert="showAlert" @showError="showError" />

      <!-- Activity Tab -->
      <!-- <ContactDetailsActivityTab v-if="isDataFetched" ref="contactDetailsActivityTab" :contactEmail="contactEmail" @showAlert="showAlert" @showError="showError" /> -->
    </div>

    <!-- Delete Contact -->
    <DeleteContactModal ref="deleteContactModal" :recordId="recordId" @showAlert="showAlert" @showError="showError" />
  </div>

  <div v-else>
    <p class="">You are not authorized to see this page.</p>
  </div>
</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';
import ContactDetailsHeader from '@/components/ContactDetailsHeader.vue';
import ContactDetailsCompaniesTab from '@/components/ContactDetailsCompaniesTab.vue';
import ContactDetailsOpportunitiesTab from '@/components/ContactDetailsOpportunitiesTab.vue';
import ContactDetailsJobsTab from '@/components/ContactDetailsJobsTab.vue';
// import ContactDetailsActivityTab from '@/components/ContactDetailsActivityTab.vue';
import DeleteContactModal from '@/components/DeleteContactModal.vue';

export default {
  name: 'ContactDetails',
  emits: [
    'showAlert',
    'showError',
  ],
  components: {
    ContactDetailsHeader,
    ContactDetailsCompaniesTab,
    ContactDetailsOpportunitiesTab,
    ContactDetailsJobsTab,
    // ContactDetailsActivityTab,
    DeleteContactModal,
  },

  data() {
    return {
      isDataFetched: false,

      sectionEditMode: {
        sectionOne: false,
        sectionTwo: false,
        sectionThree: false,
      },

      recordId: this.$route.params.id,

      record: { },
      tempRecord: null,

      tableName: 'Contacts',
      stateVariable: 'contact',
      creatingStateVariable: 'creatingContact',
      fetchingStateVariable: 'fetchingContact',
      updatingStateVariable: 'updatingContact',
      deletingStateVariable: 'deletingContact',

      tabsVisible: false,
    };
  },

  computed: {
    ...mapGetters([
      'allCompaniesOptions',
      'allContactStagesOptions',

      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),

    selectedContactStage: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Contact Stage', optionsArrayName: 'allContactStagesOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Contact Stage', value: value, optionsArrayName: 'allContactStagesOptions' });
      }
    },

    selectedCompanies: {
      get() {
        return this.mixinGetSelectedArrayField({ fieldName: 'Companies', optionsArrayName: 'allCompaniesOptions' });
      },
      set(value) {
        this.mixinSetSelectedArrayField({ fieldName: 'Companies', value: value });
      }
    },

    contactEmail() {
      return this.record.fields ? this.record.fields['Email'] : '';
    },
  },

  watch: { },

  methods: {
    calculateDaysPassed(date) {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date(date);
      const secondDate = new Date();
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      return diffDays;
    },

    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    async openDeleteContactModal() {
      this.$refs.deleteContactModal.openModal();
    },

    toggleSectionEditMode(section) {
      this.sectionEditMode[section] = !this.sectionEditMode[section];

      if (this.sectionEditMode[section]) {
        this.tempRecord = JSON.parse(JSON.stringify(this.record));
      }
    },

    cancelSectionEditMode(section) {
      this.sectionEditMode[section] = false;
      this.record = JSON.parse(JSON.stringify(this.tempRecord));
    },

    saveChangesSection() {
      this.updateRecord();
    },

    createUpdatedFields() {
      return {
        'Name': this.record.fields['Name'],
        'Title': this.record.fields['Title'],
        'Email': this.record.fields['Email'],
        'Did After Sales?': this.record.fields['Did After Sales?'],
        'Contact Stage': this.record.fields['Contact Stage'],
        'Companies': this.record.fields['Companies'],
        'Notes': this.record.fields['Notes'],
      };
    },

    validateFields() {
      let errors = [];

      if (!this.record.fields['Name']) { errors.push('"Name" is required.'); }
      if (!this.record.fields['Email']) { errors.push('"Email" is required.'); }
      if (this.record.fields['Companies'].length === 0) { errors.push('At least one company is required.'); }

      return errors;
    },

    async fetchRecord() {
      try {
        this.record = await this.$store.dispatch('fetchRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          stateVariable: this.stateVariable,
          fetchingStateVariable: this.fetchingStateVariable,
        });

        this.isDataFetched = true;

      } catch (error) {
        this.$emit('showError', error.message);
        console.error(error);
      }
    },

    async updateRecord() {
      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        return;
      }

      try {
        await this.$store.dispatch('updateRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          updatedFields: this.createUpdatedFields(),
          updatingStateVariable: this.updatingStateVariable,
        });

        Object.keys(this.sectionEditMode).forEach((key) => {
          this.sectionEditMode[key] = false;
        });

        this.$emit('showAlert', 'success', 'Successfully updated record');
        await this.fetchRecord();

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error updating record: ${error.message}`);
        console.error(error);
      }
    },
  },

  async mounted() {
    this.$nextTick(() => {
      let firstTab = new bootstrap.Tab(document.querySelector('#tabNav .nav-item:first-child .nav-link'));
      firstTab.show();
      this.tabsVisible = true;
    });

    await this.fetchRecord();

    const name = this.record.fields['Name'];
    const email = this.record.fields['Email'];
    const companies = this.record.fields['Companies Lookup'] ? this.record.fields['Companies Lookup'].join(', ') : '';
    document.title = `${name} (${email}, ${companies})`;
  },

  created() {
    this.mixinCheckUserAuthentication();
  },
};
</script>