<!-- OrdersHeader.vue -->

<template>

  <div v-if="!isFetching('fetchingOrders')">
    <div class="d-flex mb-3 align-items-end">
      <div class="col-4 d-flex align-items-end">
        <div class="p-2 ps-0">
          <div class="d-flex align-items-center">
            <div class="fs-5 fw-semibold me-2">
              Orders
            </div>

            <!-- View Picker -->
            <div class="dropdown me-2">
              <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="monthDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ selectedMonth.label }}
              </button>
              <div class="dropdown-menu dropdown-menu-scroll" aria-labelledby="monthDropdownMenuButton">
                <h6 class="dropdown-header">Select a View</h6>
                <a
                  class="dropdown-item small"
                  href="#"
                  v-for="month in allMonths"
                  :key="month.value"
                  @click.prevent="selectMonth(month.value)"
                >
                  <span>{{ month.label }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Stats: Revenue -->
      <div class="col-2 text-end ms-auto" v-if="mixinUserInRoles(['Admin'])">
        <div class="p-2">
          <p class="mb-0 text-muted">Revenue</p>
          <div :class="mixinGetValueColor(totalRevenue, totalLastYearRevenue)" class="fs-5 mb-0 fw-semibold">
            €{{ mixinFormatNumber(totalRevenue) }}
            <span class="text-muted">({{ totalRevenue >= totalLastYearRevenue ? '+' : '-' }}{{ revenueDifferencePercentage }}%)</span>
          </div>
          <p v-if="mixinUserInRoles(['Admin'])" class="mb-0 small text-muted">Previous Year: €{{ mixinFormatNumber(totalLastYearRevenue) }}</p>
        </div>
      </div>

      <!-- Stats: Vendor Costs -->
      <div class="col-2 text-end" v-if="mixinUserInRoles(['Admin'])">
        <div class="p-2">
          <p class="mb-0 text-muted">Vendor Costs</p>
          <div class="fs-5 mb-0 fw-semibold">
            €{{ mixinFormatNumber(totalVendorCosts) }}
          </div>
          <p class="mb-0 small text-muted">Previous Year: €{{ mixinFormatNumber(totalLastYearVendorCosts) }}</p>
        </div>
      </div>

      <!-- Stats: Profit -->
      <div class="col-2 text-end" v-if="mixinUserInRoles(['Admin'])">
        <div class="p-2">
          <p class="mb-0 text-muted">Profit</p>
          <div :class="mixinGetValueColor(totalProfit, totalLastYearProfit)" class="fs-5 mb-0 fw-semibold">
            €{{ mixinFormatNumber(totalProfit) }}
            <span class="text-muted">({{ totalProfit >= totalLastYearProfit ? '+' : '-' }}{{ profitDifferencePercentage }}%)</span>
          </div>
          <p class="mb-0 small text-muted">Previous Year: €{{ mixinFormatNumber(totalLastYearProfit) }}</p>
        </div>
      </div>

      <!-- Profit Margin -->
      <div class="col-2 text-end" v-if="mixinUserInRoles(['Admin'])">
        <div class="p-2">
          <p class="mb-0 text-muted">Profit Margin</p>
          <div :class="mixinGetValueColor(profitMargin, lastYearProfitMargin)" class="fs-5 mb-0 fw-semibold">
            {{ mixinFormatMargin(profitMargin) }}
            <span class="text-muted small">({{ profitMargin >= lastYearProfitMargin ? '+' : '-' }}{{ profitMarginDifferencePercentage }}%)</span>
          </div>
          <p class="mb-0 small text-muted">Previous Year: {{mixinFormatMargin(lastYearProfitMargin) }}</p>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="text-center text-muted p-5">
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OrdersHeader',
  emits: [
    'showAlert',
    'showError',
    'totalRevenueChanged',
    'selectMonth',
  ],
  props: {
    selectedMonth: {
      type: Object,
      required: true,
    },

    thisYearOrders: {
      type: Object,
      required: true,
    },

    lastYearOrders: {
      type: Object,
      required: true,
    },
  },

  data() { },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
      'allMonths',
    ]),

    totalRevenue() {
      return this.thisYearOrders.reduce((total, order) => {
        if (order.fields['Status'] !== 'Order: Cancelled') {
          total += order.fields['9 - Gross Revenue in €'];
        }
        return total;
      }, 0);
    },

    totalLastYearRevenue() {
      return this.lastYearOrders.reduce((total, order) => {
        if (order.fields['Status'] !== 'Order: Cancelled') {
          total += order.fields['9 - Gross Revenue in €'];
        }
        return total;
      }, 0);
    },

    revenueDifferencePercentage() {
      return this.calculatePercentageDifference(this.totalRevenue, this.totalLastYearRevenue);
    },

    totalVendorCosts() {
      return this.thisYearOrders.reduce((total, order) => {
        if (order.fields['Status'] !== 'Order: Cancelled') {
          total += order.fields['10 - Costs in €'];
        }
        return total;
      }, 0);
    },

    totalLastYearVendorCosts() {
      return this.lastYearOrders.reduce((total, order) => {
        if (order.fields['Status'] !== 'Order: Cancelled') {
          total += order.fields['10 - Costs in €'];
        }
        return total;
      }, 0);
    },

    vendorCostsDifferencePercentage() {
      return this.calculatePercentageDifference(this.totalVendorCosts, this.totalLastYearVendorCosts);
    },

    totalProfit() {
      return this.thisYearOrders.reduce((total, order) => {
        if (order.fields['Status'] !== 'Order: Cancelled') {
          total += order.fields['11 - Profit in €'];
        }
        return total;
      }, 0);
    },

    totalLastYearProfit() {
      return this.lastYearOrders.reduce((total, order) => {
        if (order.fields['Status'] !== 'Order: Cancelled') {
          total += order.fields['11 - Profit in €'];
        }
        return total;
      }, 0);
    },

    profitDifferencePercentage() {
      return this.calculatePercentageDifference(this.totalProfit, this.totalLastYearProfit);
    },

    profitMargin() {
      if (this.totalRevenue === 0) {
        return 0;
      }
      return this.totalProfit / this.totalRevenue;
    },

    lastYearProfitMargin() {
      if (this.totalLastYearRevenue === 0) {
        return 0;
      }
      return this.totalLastYearProfit / this.totalLastYearRevenue;
    },

    profitMarginDifferencePercentage() {
      return this.calculatePercentageDifference(this.profitMargin, this.lastYearProfitMargin);
    },
  },

  watch: {
    totalRevenue(newVal) {
      this.$emit('totalRevenueChanged', newVal);
    },
  },

  methods: {
    selectMonth(month) {
      this.$emit('selectMonth', month);
    },

    calculatePercentageDifference(currentYearTotal, lastYearTotal) {
      if (lastYearTotal === 0) {
        return 0;
      }
      const difference = currentYearTotal - lastYearTotal;
      return Math.round(Math.abs((difference / lastYearTotal) * 100));
    },
  },

  async created() { },
};
</script>