<!-- CreateOppModal.vue -->

<template>

  <div class="modal" id="createOppModal" tabindex="-1" aria-labelledby="createOppModalLabel" aria-hidden="true" ref="createOppModal">

    <div class="modal-dialog modal-lg">
      <div class="modal-content shadow shadow-lg">

        <div class="modal-header border-bottom">
          <h5 class="modal-title"><i class="bi bi-stars"></i> Create New Opp</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div v-if="createdOpportunity" class="alert alert-success mt-3">
          <i class="bi bi-check-circle-fill me-1"></i>
          Opp {{ createdOpportunity.fields['Quote No.'] }} created. <a class="alert-link" :href="'/opps/' + createdOpportunity.id" target="_blank">Click here to open it</a>.
        </div>

        <div class="modal-body" v-if="!createdOpportunity">
          <!-- Created By -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Created By
            </div>

            <div class="col-8">
              <div v-if="!isFetching('fetchingTeam')">
                <v-select v-model="newOpp.createdBy" :options="allTeamOptions" placeholder="Search...">
                  <template v-slot:open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                    </span>
                  </template>
                </v-select>
              </div>

              <div v-else>
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Currency -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Currency
            </div>

            <div class="col-8">
              <v-select v-model="newOpp.currency" :options="allCurrenciesOptions" placeholder="Search...">
                <template v-slot:open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                  </span>
                </template>
              </v-select>
            </div>
          </div>

          <!-- Title -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Opp Title
            </div>

            <div class="col-8">
              <input id="title" class="form-control" type="text" v-model="newOpp.title">
            </div>
          </div>

          <!-- Company -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Company
            </div>

            <div class="col-8">
              <div v-if="!isFetching('fetchingCompanies')">
                <v-select v-model="newOpp.company" :options="allCompaniesOptions" placeholder="Search...">
                  <template v-slot:option="option">
                    <div class="text-wrap">{{ option.label }}</div>
                    <div class="small text-muted">Preferred currency: {{ option.currency }}</div>
                    <!-- <div class="small text-muted">Preferred PM: {{ Array.isArray(option.pm) ? [].concat(...option.pm).join(', ') : '' }}</div> -->
                    <div class="small text-muted text-wrap">Contacts: {{ Array.isArray(option.contacts) ? [].concat(...option.contacts).join(', ') : '' }}</div>
                  </template>

                  <template v-slot:open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                    </span>
                  </template>
                </v-select>
              </div>

              <div v-else>
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>

              <div v-if="!isFetching('fetchingCompanies')" class="mt-2">
                <a class="small cursor-pointer" @click="showAddCompanyForm"><i class="bi bi-plus-lg me-1"></i>Create New Company</a>
              </div>

              <div v-if="showAddCompanyInput" class="mt-2">
                <input id="newCompanyInput" type="text" class="form-control" v-model="newCompanyInput" placeholder="Enter a new company name">

                <div class="mt-2 d-flex align-items-center">
                  <button type="button" class="btn btn-sm btn-primary" @click="createCompany" :disabled="isCreating('creatingCompany')">Create Company</button>
                  <button type="button" class="btn btn-sm btn-danger ms-2" @click="resetAddCompanyForm" :disabled="isCreating('creatingCompany')">Cancel</button>
                  <span v-if="isCreating('creatingCompany')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
                </div>
              </div>
            </div>
          </div>

          <!-- Contact -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Contact
            </div>

            <div class="col-8">
              <div v-if="!isFetching('fetchingContacts')">
                <v-select v-model="newOpp.contact" :options="allContactsOptions" placeholder="Search...">
                  <template v-slot:option="option">
                    <div class="text-wrap">{{ option.label }}</div>
                    <div class="small text-muted text-wrap text-break">Email: {{ option.email }}</div>
                    <div class="small text-muted text-wrap">Companies: {{ Array.isArray(option.companies) ? [].concat(...option.companies).join(', ') : '' }}</div>
                  </template>

                  <template v-slot:open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                    </span>
                  </template>
                </v-select>
              </div>

              <div v-else>
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>

              <div v-if="!isFetching('fetchingContacts')" class="mt-2">
                <a class="small cursor-pointer" @click="showAddContactForm"><i class="bi bi-plus-lg me-1"></i>Create New Contact</a>
              </div>

              <div v-if="showAddContactFields" class="mt-2">
                <input id="newContactName" type="text" class="form-control mb-2" v-model="newContactName" placeholder="Enter a new contact name">
                <input id="newContactTitle" type="text" class="form-control mb-2" v-model="newContactTitle" placeholder="Title">
                <input id="newContactEmail" type="text" class="form-control mb-2" v-model="newContactEmail" placeholder="Email">

                <div v-if="!isFetching('fetchingCompanies')">
                  <v-select v-model="newContactCompany" :options="allCompaniesOptions" placeholder="Select a company...">
                    <template v-slot:option="option">
                      <div class="text-wrap">{{ option.label }}</div>
                    </template>

                    <template v-slot:open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                      </span>
                    </template>
                  </v-select>
                </div>

                <div v-else>
                  <div class="spinner-border spinner-border-sm text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>

                <div class="mt-2 d-flex align-items-center">
                  <button type="button" class="btn btn-sm btn-primary" @click="createContact" :disabled="isCreating('creatingContact')">Create Contact</button>
                  <button type="button" class="btn btn-sm btn-danger ms-2" @click="resetAddContactForm" :disabled="isCreating('creatingContact')">Cancel</button>
                  <span v-if="isCreating('creatingContact')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer" v-if="!createdOpportunity">
          <span v-if="isCreating('creatingOpp')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-primary" @click="confirmCreateOpp" :disabled="isCreating('creatingOpp') || isFetching('fetchingCompanies') || isFetching('fetchingContacts') || isSubmitting">Create Opp</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CreateOppModal',
  emits: [
    'showAlert',
    'showError',
  ],

  data() {
    return {
      newOpp: {
        createdBy: '',
        currency: '',
        title: '',
        company: '',
        contact: '',
      },

      showAddCompanyInput: false,
      newCompanyInput: '',

      showAddContactFields: false,
      newContactName: '',
      newContactEmail: '',
      newContactTitle: '',
      newContactCompany: '',

      createdOpportunity: null,

      isSubmitting: false,
    };
  },

  computed: {
    ...mapGetters([
      'allTeamOptions',
      'allCompaniesOptions',
      'allContactsOptions',
      'allCurrenciesOptions',

      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'usdToEurExchangeRate',
      'gbpToEurExchangeRate',
    ]),
  },

  methods: {
      // Create new contact: Shows form
    showAddContactForm() {
      this.showAddContactFields = true;
    },

    // Create new contact: Cancels form
    resetAddContactForm() {
      this.showAddContactFields = false;
      this.newContactName = '';
      this.newContactTitle = '';
      this.newContactEmail = '';
      this.newContactCompany = '';
    },

    // Create new contact: Adds the contact
    async createContact() {
      // Prepares fields
      const newContactName = this.newContactName.trim();
      const newContactTitle = this.newContactTitle.trim();
      const newContactEmail = this.newContactEmail.trim();
      const newContactCompany = this.newContactCompany.value;

      // Performs validation for required fields
      if (
        !newContactName ||
        !newContactEmail ||
        !newContactCompany
      ) {
        this.$emit('showAlert', 'danger', 'Please fill in all required fields');
        return;
      }

      const newContact = {
        'Name': newContactName,
        'Title': newContactTitle,
        'Email': newContactEmail,
        'Companies': [newContactCompany],

        // mandatory fields
        'Did After Sales?': 'No',
        'Contact Stage': 'Potential Customer',
      };

      try {
        const createdContact = await this.$store.dispatch('createRecord', {
          tableName: 'Contacts',
          newRecord: newContact,
          creatingStateVariable: 'creatingContact'
        });

        if (createdContact) {
          this.$emit('showAlert', 'success', 'Contact created successfully');
          // console.log('Contact created successfully:', createdContact); // debug

          // Resets the new contact form
          this.resetAddContactForm();

          // Fetches contacts
          await this.$store.dispatch('fetchRecords', {
            tableName: 'Contacts',
            stateVariable: 'contacts',
            fetchingStateVariable: 'fetchingContacts',
          });

          // Sets the new contact as the selected option
          const createdRecord = createdContact.records[0];

          this.newOpp.contact = {
            label: createdRecord.fields['Name'],
            value: createdRecord.id
          };

        } else {
          this.$emit('showAlert', 'danger', 'Failed to create the contact');
          console.error('Failed to create the contact.');

        }
      } catch (error) {
        this.$emit('showAlert', 'danger', 'An error occurred while creating the contact');
        console.error('Error creating contact: ', error);
      }
    },

    // Create new company: Shows form
    showAddCompanyForm() {
      this.showAddCompanyInput = true;
    },

    // Create new company: Cancels form
    resetAddCompanyForm() {
      this.showAddCompanyInput = false;
      this.newCompanyInput = '';
    },

    // Create new company: Adds the company
    async createCompany() {
      // Prepares fields
      const newCompanyName = this.newCompanyInput.trim();

      // Performs validation for required fields
      if (newCompanyName === '') {
        this.$emit('showAlert', 'danger', 'Please enter a new company name.');
        return;
      }

      const newCompany = {
        'Name': newCompanyName,

        // mandatory fields
        'Country': ['recosaqYwiti9cIIk'],
        'Industry': ['reczQ7LVS0JUMsXZQ'],
        'Preferred Currency': 'Other',
        'Preferred PM': ['rect8tJzlNsWIQSoQ'],
        'Acquired By': ['rect8tJzlNsWIQSoQ'],
      };

      try {
        const createdCompany = await this.$store.dispatch('createRecord', {
          tableName: 'Companies',
          newRecord: newCompany,
          creatingStateVariable: 'creatingCompany'
        });

        if (createdCompany) {
          this.$emit('showAlert', 'success', 'Company created successfully');
          // console.log('Company created successfully:', createdCompany); // debug

          // Resets the new company form
          this.resetAddCompanyForm();

          // Fetches companies
          await this.$store.dispatch('fetchRecords', {
            tableName: 'Companies',
            stateVariable: 'companies',
            fetchingStateVariable: 'fetchingCompanies',
          });

          // Sets the new company as the selected option
          const createdRecord = createdCompany.records[0];

          this.newOpp.company = {
            label: createdRecord.fields['Name'],
            value: createdRecord.id
          };

        } else {
          this.$emit('showAlert', 'danger', 'Failed to create the company');
          console.log('Failed to create the company');
        }
      } catch (error) {
        this.$emit('showAlert', 'danger', 'An error occurred while creating the company');
        console.error('Error creating company: ', error);
      }
    },

    // Creates the opp
    async confirmCreateOpp() {
      this.isSubmitting = true;

      try {
        // validates fields
        if (
          !this.newOpp.createdBy ||
          !this.newOpp.currency ||
          !this.newOpp.company ||
          !this.newOpp.contact
        ) {
          throw new Error('Please fill in all required fields');
        }

        // trims opp title
        const newOppTitle = this.newOpp.title.trim();

        const formattedNewOpp = {
          'Created By': [this.newOpp.createdBy.value],
          'Currency': this.newOpp.currency.value,
          'Title': newOppTitle,
          'Company': [this.newOpp.company.value],
          'Contact': [this.newOpp.contact.value],
          'Forwarded By': ['rect8tJzlNsWIQSoQ'],
          'Status': 'Quote: Open',
          'PM': ['rect8tJzlNsWIQSoQ'],
        };

        // adds date
        const createdOn = new Date().toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        });

        formattedNewOpp['Created On'] = createdOn;

        // fetches 1 USD to EUR
        try {
          await this.$store.dispatch('fetchUSDtoEURExchangeRate');
          formattedNewOpp['Opp 1 USD to EUR'] = this.usdToEurExchangeRate || 0.9; // Failed API call: This refers to the case where the API call is made, but the response from the API is not successful.
          formattedNewOpp['Exchange Rate Worked?'] = 'Yes';
          // console.log('USD to EUR Exchange Rate:', this.usdToEurExchangeRate); // debug
        } catch (error) {
          console.error('Failed to fetch USD to EUR exchange rate:', error);
          formattedNewOpp['Opp 1 USD to EUR'] = 0.9; // Error during API call: This refers to the case where an exception is thrown during the process of making the API call. It could be due to network issues, server problems, or other unforeseen errors.
          formattedNewOpp['Exchange Rate Worked?'] = 'No';
          // Handle the error appropriately, such as showing an error message to the user
        }

        // fetches 1 GBP to EUR
        try {
          await this.$store.dispatch('fetchGBPtoEURExchangeRate');
          formattedNewOpp['Opp 1 GBP to EUR'] = this.gbpToEurExchangeRate || 1.15; // Failed API call: This refers to the case where the API call is made, but the response from the API is not successful.
          formattedNewOpp['Exchange Rate Worked?'] = 'Yes';
          // console.log('GBP to EUR Exchange Rate:', this.gbpToEurExchangeRate); // debug
        } catch (error) {
          console.error('Failed to fetch GBP to EUR exchange rate:', error);
          formattedNewOpp['Exchange Rate Worked?'] = 'No';
          formattedNewOpp['Opp 1 GBP to EUR'] = 1.15; // Error during API call: This refers to the case where an exception is thrown during the process of making the API call. It could be due to network issues, server problems, or other unforeseen errors.
          // Handle the error appropriately, such as showing an error message to the user
        }

        // console.log('New opp to be created:', formattedNewOpp); // debug

        // creates opp
        try {
          const createdOpportunity = await this.$store.dispatch('createRecord', {
            tableName: 'Opportunities',
            newRecord: formattedNewOpp,
            creatingStateVariable: 'creatingOpp',
          });

          // for use in the view and as a flag
          this.createdOpportunity = createdOpportunity.records[0];

          // console.log('Opportunity created successfully:', this.createdOpportunity); // debug

          // Resets the new opportunity form
          this.resetForm();

        } catch (error) {
          this.$emit('showAlert', 'danger', 'Error creating opportunity: ' + error.message);
          console.error('Error creating opportunity: ', error);

        } finally {
          // Perform any necessary cleanup or additional actions here
        }
      } catch (error) {
        this.$emit('showAlert', 'danger', error.message);
      } finally {
        this.isSubmitting = false;
      }
    },

    // Resets form
    resetForm() {
      this.newOpp = {
        createdBy: '',
        currency: '',
        title: '',
        company: '',
        contact: '',
      };

      this.newCompanyInput = '';

      this.newContactName = '';
      this.newContactEmail = '';
      this.newContactTitle = '';
      this.newContactCompany = '';

      this.showAddCompanyInput = false;
      this.showAddContactFields = false;
    },

    // Resets the createdOpportunity object
    resetCreatedOpportunity() {
      this.createdOpportunity = null;
    },
  },

  mounted() {
    this.$refs.createOppModal.addEventListener('hidden.bs.modal', () => {
      this.resetForm();
      this.resetCreatedOpportunity();
    });
  }
}
</script>