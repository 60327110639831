<!-- ContactDetailsCompaniesTab.vue -->

<template>

  <div id="companies" class="tab-pane">
    <div class="p-3 shadow-sm bg-white rounded">
      <div v-if="!isFetching('fetchingContactCompanies') && isDataFetched">
        <ReusableCompaniesTable :records="records" @showAlert="showAlert" @showError="showError" />
      </div>

      <div v-else class="d-flex justify-content-center p-5">
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import ReusableCompaniesTable from '@/components/ReusableCompaniesTable.vue';

export default {
  name: 'ContactDetailsCompaniesTab',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
    recordId: {
      type: String,
      required: true,
    },
  },
  components: {
    ReusableCompaniesTable,
  },

  data() {
    return {
      isDataFetched: false,
      records: [],
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',
    ]),
  },

  watch: { },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    async fetchRelatedRecords() {
      try {
        this.records = await this.$store.dispatch('fetchRelatedRecords', {
          parentTableName: 'Contacts',
          childTableName: 'Companies',
          recordId: this.recordId,
          relatedFieldName: 'Companies',
          stateVariable: 'contactCompanies',
          fetchingStateVariable: 'fetchingcontactCompanies',
        });

        this.isDataFetched = true;

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error fetching related records: ${error.message}`);
        console.error(error);

      }
    },
  },

  async mounted() {
    await this.fetchRelatedRecords();
  },

  created() { },
};
</script>