<!-- DeleteUnitModal.vue -->

<template>

  <div class="modal" id="deleteUnitModal" tabindex="-1" aria-labelledby="deleteUnitModalLabel" aria-hidden="true" ref="deleteUnitModal">
    <div class="modal-dialog">

      <div class="modal-content shadow shadow-lg">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">Delete Unit</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div v-if="!isSubmitting">
            Are you sure you want to delete this unit?
          </div>

          <div v-else>
            Deleting unit and fetching list of units...
          </div>
        </div>

        <div class="modal-footer">
          <span v-if="isDeleting('deletingUnit') || isSubmitting" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isDeleting('deletingUnit') || isSubmitting">Cancel</button>
          <button type="button" class="btn btn-danger" @click="deleteRecord" :disabled="isDeleting('deletingUnit') || isSubmitting">Delete</button>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';

export default {
  name: 'DeleteUnitModal',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
    unitId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isSubmitting: false,
      modalInstance: null,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  watch: { },

  methods: {
    openModal() {
      if (!this.modalInstance) {
        const modalElement = this.$refs.deleteUnitModal;
        this.modalInstance = new bootstrap.Modal(modalElement);
      }
      this.modalInstance.show();
    },

    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },

    async deleteRecord() {
      this.isSubmitting = true;

      try {
        await this.$store.dispatch('deleteRecord', {
          tableName: 'Units',
          recordId: this.unitId,
          deletingStateVariable: 'deletingUnit',
        });

        await this.$store.dispatch('fetchRecords', {
          tableName: 'Units',
          stateVariable: 'units',
          fetchingStateVariable: 'fetchingUnits',
        }),

        this.$emit('showAlert', 'success', 'Successfully deleted unit');
        this.closeModal();

        this.$router.push('/settings/units');

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error deleting unit: ' + error.message);
        console.error('Error deleting unit: ', error);

      } finally {
        this.isSubmitting = false;
      }
    },
  },

  async mounted() { },

  created() { },
};
</script>