<!-- DuplicateOppModal.vue -->

<template>

  <div class="modal" id="duplicateOppModal" tabindex="-1" aria-labelledby="duplicateOppModalLabel" aria-hidden="true" ref="duplicateOppModal">
    <div class="modal-dialog">

      <div class="modal-content shadow shadow-lg">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">Duplicate Opp</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div v-if="createdOpp" class="alert alert-success mt-3">
          <template v-if="isCreatingJobs">
            Opp duplicated. Duplicating jobs...
          </template>
          <template v-else>
            <i class="bi bi-check-circle-fill me-1"></i>
            Opp {{ createdOpp.fields['Quote No.'] }} created. <a class="alert-link" :href="'/opps/' + createdOpp.id" target="_blank">Click here to open it</a>.
          </template>
        </div>

        <div class="modal-body" v-if="!createdOpp">
          <p>This will create a new opportunity with the following information:</p>

          <div class="d-flex pt-2 pb-2 align-items-center border-bottom">
            <div class="col text-muted small">
              Created By
            </div>

            <div class="col">
              {{ opp.fields['Created By Lookup'] ? opp.fields['Created By Lookup'].join(', ') : '' }}
            </div>
          </div>

          <div class="d-flex pt-2 pb-2 align-items-center border-bottom">
            <div class="col text-muted small">
              Company
            </div>

            <div class="col">
              {{ opp.fields['Company Lookup'] ? opp.fields['Company Lookup'].join(', ') : '' }}
            </div>
          </div>

          <div class="d-flex pt-2 pb-2 align-items-center border-bottom">
            <div class="col text-muted small">
              Contact
            </div>

            <div class="col">
              {{ opp.fields['Contact Lookup'] ? opp.fields['Contact Lookup'].join(', ') : '' }}
            </div>
          </div>

          <div class="d-flex pt-2 pb-2 mb-4 align-items-center border-bottom">
            <div class="col text-muted small">
              Currency
            </div>

            <div class="col">
              {{ opp.fields['Currency'] }}
            </div>
          </div>

          <p>You will need to add everything else.</p>
        </div>

        <div class="modal-footer" v-if="!createdOpp">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="duplicateJobs" id="duplicateJobsCheckbox">
            <label class="form-check-label" for="duplicateJobsCheckbox">
              Also duplicate jobs
            </label>
          </div>
          <span v-if="isCreating('creatingOpp')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-primary" @click="createRecord" :disabled="isCreating('creatingOpp')|| isSubmitting">Duplicate Opp</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DuplicateOppModal',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
    opp: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      createdOpp: null,
      isSubmitting: false,
      modalInstance: null,
      duplicateJobs: false,
      isCreatingJobs: false,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  watch: { },

  methods: {
    formatData() {
      const formattedData = {
        'Created By': this.opp.fields['Created By'],
        'Company': this.opp.fields['Company'],
        'Contact': this.opp.fields['Contact'],
        'Currency': this.opp.fields['Currency'],
        'Opp 1 USD to EUR': this.opp.fields['Opp 1 USD to EUR'],
        'Opp 1 GBP to EUR': this.opp.fields['Opp 1 GBP to EUR'],

        // mandatory fields
        'Forwarded By': ['rect8tJzlNsWIQSoQ'],
        'Status': 'Quote: Open',
        'PM': ['rect8tJzlNsWIQSoQ'],
      };

      // adds date
      const createdOn = new Date().toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });

      formattedData['Created On'] = createdOn;

      return formattedData;
    },

    async createRecord() {
      this.isSubmitting = true;

      const formattedData = this.formatData();
      // console.log('New opp to be created: ', formattedData); // debug

      try {
        const createdOpp = await this.$store.dispatch('createRecord', {
          tableName: 'Opportunities',
          newRecord: formattedData,
          creatingStateVariable: 'creatingOpp',
        });

        // for use in the view and as a flag
        this.createdOpp = createdOpp.records[0];

        if (this.duplicateJobs) {
          this.isCreatingJobs = true;
          await this.duplicateRelatedJobs(this.opp.id, this.createdOpp.id);
          this.isCreatingJobs = false;
        }

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error creating opp: ' + error.message);
        console.error('Error creating opp: ', error);

      } finally {
        this.isSubmitting = false;
      }
    },

    async duplicateRelatedJobs(originalOppId, newOppId) {
      const jobs = await this.fetchRelatedJobs(originalOppId);

      // console.log('Jobs to be duplicated:', jobs); // debug

      const formattedJobs = jobs.map(job => this.formatJob(job, newOppId));

      const batchSize = 10;
      const jobChunks = this.chunkArray(formattedJobs, batchSize);

      try {
        for (const chunk of jobChunks) {
          await this.$store.dispatch('createRecords', {
            tableName: 'Jobs',
            newRecords: chunk,
            creatingStateVariable: 'creatingJobs',
          });
        }

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error duplicating job/s: ' + error.message);
        console.error('Error duplicating job/s: ', error);

      }
    },

    chunkArray(array, size) {
      const chunks = [];
      for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
      }
      return chunks;
    },

    formatJob(job, newOppId) {
      return {
        'Opp ID': [newOppId],
        'Service ID': job.fields['Service ID'],
        'Language Pair': job.fields['Language Pair'],
        'Unit ID': job.fields['Unit ID'],
        'Units': job.fields['Units'],
        'Unit Price': job.fields['Unit Price'],
        '1 USD to EUR': job.fields['1 USD to EUR'],
        '1 GBP to EUR': job.fields['1 GBP to EUR'],

        // mandatory fields
        'Vendors': ['recZxC6XPv36Pwzaw'],
        'Vendor Currency': 'Other',
        'Vendor Unit ID': ['rec6MUCC2wo369ZLX'],
      };
    },

    async fetchRelatedJobs(oppId) {
      try {
        const jobs = await this.$store.dispatch('fetchRelatedRecords', {
          parentTableName: 'Opportunities',
          childTableName: 'Jobs',
          recordId: oppId,
          relatedFieldName: 'Jobs ID',
          stateVariable: 'oppJobs',
          fetchingStateVariable: 'fetchingOppJobs',
        });

        return jobs;

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error fetching related jobs: ${error.message}`);
        console.error('Error fetching related jobs:', error);
        return [];

      }
    },
  },

  async mounted() { },

  created() { },
};
</script>