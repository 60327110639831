<!-- CreateContactModal.vue -->
<!-- Use this as a model -->

<template>

  <div class="modal" id="createContactModal" tabindex="-1" aria-labelledby="createContactModalLabel" aria-hidden="true" ref="createContactModal">
    <div class="modal-dialog">

      <div class="modal-content shadow shadow-lg">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">Create New Contact</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div v-if="createdRecord" class="alert alert-success mt-3">
          <i class="bi bi-check-circle-fill me-1"></i>
          Contact "{{ createdRecord.fields['Name'] }}" created. <a class="alert-link" :href="'/contacts/' + createdRecord.id" target="_blank">Open this contact profile</a>.
        </div>

        <div class="modal-body" v-if="!createdRecord">
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Name & Email
            </div>
            <div class="col-8">
              <input class="form-control" type="text" v-model="nameEmailInput" @input="parseNameEmail" placeholder="Name <email@example.com>">
            </div>
          </div>

          <!-- Full Name -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Full Name
            </div>

            <div class="col-8">
              <input class="form-control" type="text" v-model="newRecord.name">
            </div>
          </div>

          <!-- Title -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Title
            </div>

            <div class="col-8">
              <input class="form-control" type="text" v-model="newRecord.title">
            </div>
          </div>

          <!-- Email -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Email
            </div>

            <div class="col-8">
              <input class="form-control" type="email" v-model="newRecord.email">
            </div>
          </div>

          <!-- Companies -->
          <div class="d-flex align-items-center pt-3">
            <div class="col-4 text-muted">
              Companies
            </div>

            <div class="col-8">
              <div v-if="!isFetching('fetchingCompanies')">
                <v-select v-model="newRecord.companies" :options="allCompaniesOptions" placeholder="Select..." multiple>
                  <template v-slot:open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                    </span>
                  </template>
                </v-select>
              </div>

              <div v-else>
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer" v-if="!createdRecord">
          <span v-if="isCreating('creatingContact')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-primary" @click="createRecord" :disabled="isCreating('creatingContact') || isFetching('fetchingCompanies') || isSubmitting">Create Contact</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CreateContactModal',
  emits: [
    'showAlert',
    'showError',
  ],

  data() {
    return {
      newRecord: this.defaultNewRecord(),
      createdRecord: null,
      isSubmitting: false,
      nameEmailInput: '',
    };
  },

  computed: {
    ...mapGetters([
      'allCompaniesOptions',

      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  methods: {
    parseNameEmail(event) {
      const input = event.target.value;
      const match = input.match(/^(.*)\s*<(.+)>$/);
      if (match) {
        this.newRecord.name = match[1].trim();
        this.newRecord.email = match[2].trim();
      }
    },

    defaultNewRecord() {
      return {
        name: '',
        title: '',
        email: '',
        companies: [],
      };
    },

    resetForm() {
      this.newRecord = this.defaultNewRecord();
      this.nameEmailInput = '';
    },

    resetCreatedRecord() {
      this.createdRecord = null;
    },

    validateFields() {
      let errors = [];

      if (!this.newRecord.name) { errors.push('"Name" is required.'); }
      if (!this.newRecord.email) { errors.push('"Email" is required.'); }
      if (this.newRecord.companies.length === 0) { errors.push('At least one company is required.'); }

      return errors;
    },

    formatData() {
      const newContactName = this.newRecord.name.trim();
      const newContactTitle = this.newRecord.title.trim();
      const newContactEmail = this.newRecord.email.trim();

      const formattedData = {
        'Name': newContactName,
        'Title': newContactTitle,
        'Email': newContactEmail,
        'Companies': this.newRecord.companies.map(company => company.value),

        // mandatory fields
        'Did After Sales?': 'No',
        'Contact Stage': 'Potential Customer',
      };

      return formattedData;
    },

    async createRecord() {
      this.isSubmitting = true;

      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        this.isSubmitting = false;
        return;
      }

      const formattedData = this.formatData();

      try {
        const createdRecord = await this.$store.dispatch('createRecord', {
          tableName: 'Contacts',
          newRecord: formattedData,
          creatingStateVariable: 'creatingContact',
        });

        // for use in the view and as a flag
        this.createdRecord = createdRecord.records[0];
        this.resetForm();

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error creating record: ' + error.message);
        console.error('Error creating record: ', error);

      } finally {
        this.isSubmitting = false;
      }
    },
  },

  async mounted() {
    // Resets modal on close
    // we use this.resetForm() and not this.resetForm because we are calling 2 methods
    this.$refs.createContactModal.addEventListener('hidden.bs.modal', () => {
      this.resetForm();
      this.resetCreatedRecord();
    });
  },
};
</script>