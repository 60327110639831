<!-- DeleteContactModal.vue -->

<template>

  <div class="modal" id="deleteContactModal" tabindex="-1" aria-labelledby="deleteContactModalLabel" aria-hidden="true" ref="deleteContactModal">
    <div class="modal-dialog">

      <div class="modal-content shadow shadow-lg">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">Delete Contact</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div v-if="!isSubmitting">
            Are you sure you want to delete this contact?
          </div>

          <div v-else>
            Deleting contact and fetching list of contacts...
          </div>
        </div>

        <div class="modal-footer">
          <span v-if="isDeleting('deletingContact') || isSubmitting" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isDeleting('deletingContact') || isSubmitting">Cancel</button>
          <button type="button" class="btn btn-danger" @click="deleteRecord" :disabled="isDeleting('deletingContact') || isSubmitting">Delete</button>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';

export default {
  name: 'DeleteContactModal',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
    recordId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isSubmitting: false,
      modalInstance: null,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  watch: { },

  methods: {
    openModal() {
      if (!this.modalInstance) {
        const modalElement = this.$refs.deleteContactModal;
        this.modalInstance = new bootstrap.Modal(modalElement);
      }
      this.modalInstance.show();
    },

    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },

    async deleteRecord() {
      this.isSubmitting = true;

      try {
        await this.$store.dispatch('deleteRecord', {
          tableName: 'Contacts',
          recordId: this.recordId,
          deletingStateVariable: 'deletingContact',
        });

        await this.$store.dispatch('fetchRecords', {
          tableName: 'Contacts',
          stateVariable: 'contacts',
          fetchingStateVariable: 'fetchingContacts',
        }),

        this.$emit('showAlert', 'success', 'Successfully deleted contact');
        this.closeModal();

        this.$router.push('/contacts');

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error deleting contact: ' + error.message);
        console.error('Error deleting contact: ', error);

      } finally {
        this.isSubmitting = false;
      }
    },
  },

  async mounted() { },

  created() { },
};
</script>