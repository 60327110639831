<!-- VendorDetails.vue -->

<template>

  <div v-if="mixinUserInRoles(['Admin', 'PM', 'VM'], isPublicMode)">
    <!-- Breadcrumb -->
    <!-- <nav v-if="isDataFetched" style="--bs-breadcrumb-divider: '&rsaquo;';" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item small"><router-link to="/vendors">Vendors</router-link></li>
        <li class="breadcrumb-item small active" aria-current="page">{{ record.fields['Full Name'] }}</li>
      </ol>
    </nav> -->

    <VendorDetailsHeader v-if="isDataFetched" :record="record" :isPublicMode="isPublicMode" @showAlert="showAlert" @showError="showError" />

    <!-- Navigation -->
    <div class="row mb-3">
      <!-- Tabs -->
      <div class="col">
        <ul class="nav nav-pills" id="tabNav" v-show="tabsVisible">
          <li class="nav-item small">
            <a class="nav-link active" data-bs-toggle="tab" href="#vendorDetails">Vendor Details</a>
          </li>

          <li v-if="!isPublicMode" class="nav-item small">
            <a class="nav-link" data-bs-toggle="tab" href="#services">Services</a>
          </li>

          <li v-if="!isPublicMode" class="nav-item small">
            <a class="nav-link" data-bs-toggle="tab" href="#opportunities">Opportunities</a>
          </li>

          <li v-if="!isPublicMode" class="nav-item small">
            <a class="nav-link" data-bs-toggle="tab" href="#jobs">Jobs</a>
          </li>
        </ul>
      </div>

      <!-- Secondary actions -->
      <div class="col d-flex justify-content-end align-items-center">
        <template v-if="!isPublicMode">
          <!-- Delete Vendor -->
          <a v-if="isDataFetched && !record.fields['Jobs']" @click="openDeleteVendorModal()" :disabled="isDeleting('deletingVendor')" class="btn btn-sm btn-danger"><i class="bi bi-trash me-1"></i> Delete Vendor</a>

          <!-- Copy Vendor Public Link -->
          <a class="btn btn-sm btn-outline-secondary ms-2" @click="copyToClipboard(record.fields['Vendor Public Link'])">
            <i class="bi me-1" :class="copied ? 'bi-check' : 'bi-clipboard'"></i>{{ copied ? "Copied" : "Copy Vendor Public Link" }}
          </a>
        </template>
      </div>
    </div>

    <!-- Tabs -->
    <div class="tab-content">
      <!-- Vendor Details -->
      <div id="vendorDetails" class="tab-pane show active">
        <div v-if="!isFetching('fetchingVendor') && isDataFetched">
          <div class="row">
            <div class="col-12">
              <!-- Section: One -->
              <div class="p-0 bg-white shadow-sm rounded mb-3">
                <!-- Section header  -->
                <div class="p-4 pb-2">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="fs-5 fw-semibold mb-3">Vendor Details</span>
                    </div>
                    <div class="col text-end" v-if="mixinUserInRoles(['Admin', 'PM', 'VM'], isPublicMode)">
                      <div v-if="!sectionEditMode.sectionOne">
                        <button class="btn btn-sm btn-primary" @click.prevent="toggleSectionEditMode('sectionOne')"><i class="bi bi-pencil-fill me-1"></i> Edit</button>
                      </div>
                      <div v-else>
                        <button class="btn btn-sm btn-primary" @click.prevent="saveChangesSection('sectionOne')" :disabled="isUpdating('updatingVendor') || isFetching('fetchingTeam') || isFetching('fetchingLanguages') || isFetching('fetchingCountries')">
                          <div v-if="isUpdating('updatingVendor') || isFetching('fetchingTeam') || isFetching('fetchingLanguages') || isFetching('fetchingCountries')">
                            <div class="spinner-border spinner-border-sm" role="status"></div> Loading...
                          </div>

                          <div v-else>
                            <i class="bi bi-check"></i> Save
                          </div>
                        </button>

                        <button class="btn btn-sm btn-secondary ms-2" @click="cancelSectionEditMode('sectionOne')"><i class="bi bi-x"></i> Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-4 pt-0">
                  <!-- Created On -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Created On
                    </div>

                    <div class="col-9 align-items-center">
                      {{ record.fields['Created On'] }}
                    </div>
                  </div>

                  <!-- Added By / Created By -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Created By
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Added By Lookup'] ? record.fields['Added By Lookup'].join(', ') : '' }}
                      </div>

                      <div v-else>
                        <v-select v-if="!isFetching('fetchingTeam')" v-model="selectedAddedBy" :options="allTeamOptions" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>

                        <div v-else class="d-inline-block">
                          <div class="spinner-border spinner-border-sm text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Blacklisted? -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Blacklisted?
                    </div>

                    <div class="col-2">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Blacklisted?'] }}
                      </div>

                      <div v-else>
                        <v-select v-model="record.fields['Blacklisted?']" :options="['Yes', 'No']" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>

                  <!-- Type -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Type
                    </div>

                    <div class="col-2">
                      <div v-if="!sectionEditMode.sectionOne">
                        <span :class="['badge custom-badge', mixinGetVendorTypeBadgeClass(record.fields['Type'])]">{{ record.fields['Type'] }}</span>
                      </div>

                      <div v-else>
                        <v-select v-model="record.fields['Type']" :options="['Agency', 'Individual']" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>

                  <!-- Full Name -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Full Name
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Full Name'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['Full Name']">
                      </div>
                    </div>
                  </div>

                  <!-- Email -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Email
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Email'] }}
                      </div>

                      <div v-else>
                        <input type="email" class="form-control" v-model="record.fields['Email']">
                      </div>
                    </div>
                  </div>

                  <!-- Phone -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Phone
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Phone'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['Phone']">
                      </div>
                    </div>
                  </div>

                  <!-- Skype ID -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Skype ID
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Skype ID'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['Skype ID']">
                      </div>
                    </div>
                  </div>

                  <!-- Native Languages -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Native Languages
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Native Languages Lookup'] ? record.fields['Native Languages Lookup'].join(', ') : '' }}
                      </div>

                      <div v-else>
                        <v-select v-model="selectedNativeLanguages" :options="allLanguagesOptions" placeholder="Select..." multiple>
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>

                  <!-- CV -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      CV
                    </div>

                    <div class="col-9">
                      <div v-if="!sectionEditMode.sectionOne">
                        <ul class="mb-0 ps-0">
                          <li v-for="(file, index) in record.fields['CV']" :key="index">
                            <a :href="file.url" target="_blank">
                              {{ file.filename }}
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div v-else>
                        <p>After uploading or deleting files, save your changes by clicking in "Save"</p>

                        <button @click="openFilePicker" class="btn btn-sm btn-primary"><i class="bi bi-upload me-1"></i> Upload Files</button>

                        <ul v-if="record.fields['CV']" class="mt-4 mb-0 ps-0">
                          <li v-for="(file, index) in record.fields['CV']" :key="index">
                            <a :href="file.url" target="_blank">
                              {{ file.filename }}
                            </a>
                            <a href="#" @click.prevent="deleteFile(index)" class="btn btn-sm btn-outline-secondary ms-2"><i class="bi bi-trash-fill"></i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <!-- Country -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Country
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Country Lookup'] ? record.fields['Country Lookup'].join(', ') : '' }}
                      </div>

                      <div v-else>
                        <v-select v-if="!isFetching('fetchingCountries')" v-model="selectedCountry" :options="allCountriesOptions" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>

                        <div v-else class="d-inline-block">
                          <div class="spinner-border spinner-border-sm text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Currency -->
                  <div class="d-flex pt-3 pb-3 align-items-center" :class="{'border-bottom': !isPublicMode}">
                    <div class="col-3 text-muted small">
                      Currency
                    </div>

                    <div class="col-2">
                      <div v-if="!sectionEditMode.sectionOne">
                        <span :class="['badge custom-badge', mixinGetCurrencyBadgeClass(record.fields['Currency'])]">{{ record.fields['Currency'] }}</span>
                      </div>

                      <div v-else>
                        <v-select v-model="record.fields['Currency']" :options="allCurrenciesOptions" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>

                  <!-- Notes / Onboarding Notes -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Onboarding Notes
                    </div>

                    <div class="col-9" style="white-space: pre-line;">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Notes'] }}
                      </div>

                      <div v-else>
                        <textarea class="form-control" rows="3" v-model="record.fields['Notes']"></textarea>
                      </div>
                    </div>
                  </div>

                  <!-- PM Notes -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center">
                    <div class="col-3 text-muted small">
                      PM Notes
                    </div>

                    <div class="col-9" style="white-space: pre-line;">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['PM Notes'] }}
                      </div>

                      <div v-else>
                        <textarea class="form-control" rows="3" v-model="record.fields['PM Notes']"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Section: Two -->
              <div class="p-0 bg-white shadow-sm rounded mb-3">
                <!-- Section header  -->
                <div class="p-4 pb-2">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="fs-5 fw-semibold mb-3">Payments</span>
                    </div>
                    <div class="col text-end" v-if="mixinUserInRoles(['Admin', 'PM', 'VM'], isPublicMode)">
                      <div v-if="!sectionEditMode.sectionTwo">
                        <button class="btn btn-sm btn-primary" @click.prevent="toggleSectionEditMode('sectionTwo')"><i class="bi bi-pencil-fill me-1"></i> Edit</button>
                      </div>
                      <div v-else>
                        <button class="btn btn-sm btn-primary" @click.prevent="saveChangesSection('sectionTwo')" :disabled="isUpdating('updatingVendor') || isFetching('fetchingTeam') || isFetching('fetchingLanguages') || isFetching('fetchingCountries')">
                          <div v-if="isUpdating('updatingVendor') || isFetching('fetchingTeam') || isFetching('fetchingLanguages') || isFetching('fetchingCountries')">
                            <div class="spinner-border spinner-border-sm" role="status"></div> Loading...
                          </div>

                          <div v-else>
                            <i class="bi bi-check"></i> Save
                          </div>
                        </button>

                        <button class="btn btn-sm btn-secondary ms-2" @click="cancelSectionEditMode('sectionTwo')"><i class="bi bi-x"></i> Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-4 pt-0">
                  <!-- Self Invoicing? -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Allows Self Invoicing?
                    </div>

                    <div class="col-2">
                      <div v-if="!sectionEditMode.sectionTwo">
                        {{ record.fields['Self Invoicing?'] }}
                      </div>

                      <div v-else>
                        <v-select v-model="record.fields['Self Invoicing?']" :options="['Yes', 'No']" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>

                  <!-- Billing Details -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Billing Address
                    </div>

                    <div class="col-5" style="white-space: pre-line;">
                      <div v-if="!sectionEditMode.sectionTwo">
                        {{ record.fields['Address'] }}
                      </div>

                      <div v-else>
                        <textarea class="form-control" rows="3" v-model="record.fields['Address']"></textarea>
                      </div>
                    </div>
                  </div>

                  <!-- Tax ID -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Tax ID
                    </div>

                    <div class="col-2">
                      <div v-if="!sectionEditMode.sectionTwo">
                        {{ record.fields['Tax ID'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['Tax ID']">
                      </div>
                    </div>
                  </div>

                  <!-- Tax Note -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Tax Note
                    </div>

                    <div class="col-9">
                      <div v-if="!sectionEditMode.sectionTwo">
                        {{ record.fields['Tax Note'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['Tax Note']">
                      </div>
                    </div>
                  </div>

                  <!-- Bank Account Name -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Bank Account Holder Name
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionTwo">
                        {{ record.fields['Bank Account Name'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['Bank Account Name']">
                      </div>
                    </div>
                  </div>

                  <!-- IBAN -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      IBAN
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionTwo">
                        {{ record.fields['IBAN'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['IBAN']">
                      </div>
                    </div>
                  </div>

                  <!-- SWIFT/BIC -->
                  <div class="d-flex pt-3 pb-3 align-items-center">
                    <div class="col-3 text-muted small">
                      SWIFT/BIC
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionTwo">
                        {{ record.fields['SWIFT/BIC'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['SWIFT/BIC']">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Section: Three -->
              <div class="p-0 bg-white shadow-sm rounded">

                <!-- Section header  -->
                <div class="p-4 pb-2">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="fs-5 fw-semibold mb-3">Social</span>
                    </div>
                    <div class="col text-end" v-if="mixinUserInRoles(['Admin', 'PM', 'VM'], isPublicMode)">
                      <div v-if="!sectionEditMode.sectionThree">
                        <button class="btn btn-sm btn-primary" @click.prevent="toggleSectionEditMode('sectionThree')"><i class="bi bi-pencil-fill me-1"></i> Edit</button>
                      </div>
                      <div v-else>
                        <button class="btn btn-sm btn-primary" @click.prevent="saveChangesSection('sectionThree')" :disabled="isUpdating('updatingVendor') || isFetching('fetchingTeam') || isFetching('fetchingLanguages') || isFetching('fetchingCountries')">

                          <div v-if="isUpdating('updatingVendor') || isFetching('fetchingTeam') || isFetching('fetchingLanguages') || isFetching('fetchingCountries')">
                            <div class="spinner-border spinner-border-sm" role="status"></div> Loading...
                          </div>

                          <div v-else>
                            <i class="bi bi-check"></i> Save
                          </div>
                        </button>

                        <button class="btn btn-sm btn-secondary ms-2" @click="cancelSectionEditMode('sectionThree')"><i class="bi bi-x"></i> Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-4 pt-0">
                  <!-- Proz URL -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Proz URL
                    </div>

                    <div class="col-9">
                      <div v-if="!sectionEditMode.sectionThree">
                        {{ record.fields['Proz URL'] }}
                      </div>

                      <div v-else>
                        <input type="url" class="form-control" v-model="record.fields['Proz URL']">
                      </div>
                    </div>
                  </div>

                  <!-- LinkedIn URL -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      LinkedIn URL
                    </div>

                    <div class="col-9">
                      <div v-if="!sectionEditMode.sectionThree">
                        {{ record.fields['LinkedIn URL'] }}
                      </div>

                      <div v-else>
                        <input type="url" class="form-control" v-model="record.fields['LinkedIn URL']">
                      </div>
                    </div>
                  </div>

                  <!-- Website URL -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Website URL
                    </div>

                    <div class="col-9">
                      <div v-if="!sectionEditMode.sectionThree">
                        {{ record.fields['Website URL'] }}
                      </div>

                      <div v-else>
                        <input type="url" class="form-control" v-model="record.fields['Website URL']">
                      </div>
                    </div>
                  </div>

                  <!-- Smartcat URL -->
                  <div class="d-flex pt-3 pb-3 align-items-center">
                    <div class="col-3 text-muted small">
                      Smartcat URL
                    </div>

                    <div class="col-9">
                      <div v-if="!sectionEditMode.sectionThree">
                        {{ record.fields['Smartcat URL'] }}
                      </div>

                      <div v-else>
                        <input type="url" class="form-control" v-model="record.fields['Smartcat URL']">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="d-flex justify-content-center p-5">
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Services Tab -->
      <VendorDetailsServicesTab ref="vendorDetailsServicesTab" :recordId="recordId" @showAlert="showAlert" @showError="showError" />

      <!-- Opportunities Tab -->
      <VendorDetailsOpportunitiesTab v-if="!isPublicMode" ref="vendorDetailsOpportunitiesTab" :recordId="recordId" @showAlert="showAlert" @showError="showError" />

      <!-- Jobs Tab -->
      <VendorDetailsJobsTab v-if="!isPublicMode" ref="vendorDetailsJobsTab" :recordId="recordId" @showAlert="showAlert" @showError="showError" />
    </div>

    <!-- Delete Vendor -->
    <DeleteVendorModal ref="deleteVendorModal" :vendorId="recordId" @showAlert="showAlert" @showError="showError" />
  </div>

  <div v-else>
    <p class="">You are not authorized to see this page.</p>
  </div>

</template>

<script>
/* global filestack */
/* global bootstrap */
import { mapGetters } from 'vuex';
import VendorDetailsHeader from '@/components/VendorDetailsHeader.vue';
import VendorDetailsServicesTab from '@/components/VendorDetailsServicesTab.vue';
import VendorDetailsOpportunitiesTab from '@/components/VendorDetailsOpportunitiesTab.vue';
import VendorDetailsJobsTab from '@/components/VendorDetailsJobsTab.vue';
import DeleteVendorModal from '@/components/DeleteVendorModal.vue';

export default {
  name: 'VendorDetails',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
    isPublicMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VendorDetailsHeader,
    VendorDetailsServicesTab,
    VendorDetailsOpportunitiesTab,
    VendorDetailsJobsTab,
    DeleteVendorModal,
  },

  data() {
    return {
      isDataFetched: false,

      sectionEditMode: {
        sectionOne: false,
        sectionTwo: false,
        sectionThree: false,
      },

      recordId: this.$route.params.id,

      record: { },
      tempRecord: null,

      files: [],

      tableName: 'Vendors',
      stateVariable: 'vendor',
      creatingStateVariable: 'creatingVendor',
      fetchingStateVariable: 'fetchingVendor',
      updatingStateVariable: 'updatingVendor',
      deletingStateVariable: 'deletingVendor',

      tabsVisible: false,
      copied: false,
    };
  },

  computed: {
    ...mapGetters([
      'allTeamOptions',
      'allLanguagesOptions',
      'allCountriesOptions',
      'allCurrenciesOptions',

      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),

    selectedAddedBy: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Added By', optionsArrayName: 'allTeamOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Added By', value: value, optionsArrayName: 'allTeamOptions' });
      }
    },

    selectedNativeLanguages: {
      get() {
        return this.mixinGetSelectedArrayField({ fieldName: 'Native Languages', optionsArrayName: 'allLanguagesOptions' });
      },
      set(value) {
        this.mixinSetSelectedArrayField({ fieldName: 'Native Languages', value: value });
      }
    },

    selectedCountry: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Country', optionsArrayName: 'allCountriesOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Country', value: value, optionsArrayName: 'allCountriesOptions' });
      }
    },
  },

  watch: { },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 3000); // Reset after 3 seconds
      }, err => {
        console.error('Could not copy text: ', err);
      });
    },

    async openDeleteVendorModal() {
      this.$refs.deleteVendorModal.openModal();
    },

    toggleSectionEditMode(section) {
      this.sectionEditMode[section] = !this.sectionEditMode[section];

      if (this.sectionEditMode[section]) {
        this.tempRecord = JSON.parse(JSON.stringify(this.record));
      }
    },

    cancelSectionEditMode(section) {
      this.sectionEditMode[section] = false;
      this.record = JSON.parse(JSON.stringify(this.tempRecord));
    },

    saveChangesSection() {
      this.updateRecord();
    },

    openFilePicker() {
      const options = {
        onUploadDone: (result) => {
          this.handleFileUpload(result.filesUploaded);
        },
      };

      const picker = this.client.picker(options);
      picker.open();
    },

    handleFileUpload(filesUploaded) {
      const attachments = filesUploaded.map(file => ({
        url: file.url,
        filename: file.filename
      }));

      this.record.fields['CV'] = [...(this.record.fields['CV'] || []), ...attachments];
      // console.log('files object to be modified as: ', this.record.fields['CV']); // debug
    },

    deleteFile(index) {
      if (window.confirm('Are you sure you want to delete this file?')) {
        this.record.fields['CV'].splice(index, 1);
      }
    },

    createUpdatedFields() {
      return {
        'Added By': this.record.fields['Added By'],
        'Blacklisted?': this.record.fields['Blacklisted?'],
        'Type': this.record.fields['Type'],
        'Full Name': this.record.fields['Full Name'],
        'Email': this.record.fields['Email'],
        'Phone': this.record.fields['Phone'],
        'Skype ID': this.record.fields['Skype ID'],
        'Native Languages': this.record.fields['Native Languages'],
        'CV': this.record.fields['CV'],
        'Country': this.record.fields['Country'],
        'Currency': this.record.fields['Currency'].value,
        'Notes': this.record.fields['Notes'],
        'PM Notes': this.record.fields['PM Notes'],

        'Self Invoicing?': this.record.fields['Self Invoicing?'],
        'Address': this.record.fields['Address'],
        'Tax ID': this.record.fields['Tax ID'],
        'Tax Note': this.record.fields['Tax Note'],
        'Bank Account Name': this.record.fields['Bank Account Name'],
        'IBAN': this.record.fields['IBAN'],
        'SWIFT/BIC': this.record.fields['SWIFT/BIC'],

        "Proz URL": this.record.fields['Proz URL'],
        "LinkedIn URL": this.record.fields['LinkedIn URL'],
        "Website URL": this.record.fields['Website URL'],
        "Smartcat URL": this.record.fields['Smartcat URL'],
      };
    },

    validateFields() {
      let errors = [];

      if (!this.record.fields['Full Name']) { errors.push('"Full Name" is required.'); }
      if (!this.record.fields['Email']) { errors.push('"Email" is required.'); }
      if (this.record.fields['Native Languages'].length === 0) { errors.push('At least one native language is required.'); }

      return errors;
    },

    async fetchRecord() {
      try {
        this.record = await this.$store.dispatch('fetchRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          stateVariable: this.stateVariable,
          fetchingStateVariable: this.fetchingStateVariable,
        });

        this.isDataFetched = true;

      } catch (error) {
        this.$emit('showError', error.message);
        console.error(error);
      }
    },

    async updateRecord() {
      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        return;
      }

      try {
        await this.$store.dispatch('updateRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          updatedFields: this.createUpdatedFields(),
          updatingStateVariable: this.updatingStateVariable,
        });

        Object.keys(this.sectionEditMode).forEach((key) => {
          this.sectionEditMode[key] = false;
        });

        this.$emit('showAlert', 'success', 'Successfully updated record');
        await this.fetchRecord();

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error updating record: ${error.message}`);
        console.error(error);
      }
    },
  },

  async mounted() {
    await this.fetchRecord();
    this.client = filestack.init('AajO2uLRCz1UPCKnfyHAsz');

    const name = this.record.fields['Full Name'];
    const email = this.record.fields['Email'];

    document.title = `${name} (${email})`;

    this.$nextTick(() => {
      let firstTab = new bootstrap.Tab(document.querySelector('#tabNav .nav-item:first-child .nav-link'));
      firstTab.show();
      this.tabsVisible = true;
    });
  },

  created() {
    if (!this.isPublicMode) {
      this.mixinCheckUserAuthentication();
    }
  },
};
</script>