<!-- Companies.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'Finance'])">
    <div v-if="findDuplicates.length > 0" class="alert alert-warning p-2 small">
      <i class="bi bi-exclamation-triangle-fill"></i> <strong>There are duplicate companies:</strong> {{ findDuplicates.join(', ') }}
    </div>

    <CompaniesHeader @showAlert="showAlert" @showError="showError" />

    <div v-if="!isFetching('fetchingCompanies')">
      <div class="p-3 shadow-sm bg-white rounded">
        <ReusableCompaniesTable :records="getProperty('companies')" @showAlert="showAlert" @showError="showError" />
      </div>
    </div>

    <div v-else class="text-center text-muted p-5">
      <div class="spinner-border text-secondary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div v-else>
    <p class="">You are not authorized to see this page.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CompaniesHeader from '@/components/CompaniesHeader.vue';
import ReusableCompaniesTable from '@/components/ReusableCompaniesTable.vue';

export default {
  name: 'AppCompanies',
  emits: [
    'showAlert',
    'showError',
  ],
  components: {
    CompaniesHeader,
    ReusableCompaniesTable,
  },

  data() {
    return {
      hasDuplicates: false,
      duplicateNames: [],
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),

    findDuplicates() {
      const nameCount = {};
      const duplicates = [];
      const originalNames = {};

      this.getProperty('companies').forEach(row => {
          const originalName = row.fields['Name'];
          const name = originalName.toLowerCase(); // convert to lowercase for comparison
          originalNames[name] = originalName; // store the original name
          if (nameCount[name]) {
            nameCount[name]++;
          } else {
            nameCount[name] = 1;
          }
      });

      Object.keys(nameCount).forEach(name => {
          if (nameCount[name] > 1) {
            duplicates.push(originalNames[name]); // push the original name, not the lowercase version
          }
      });

      return duplicates;
    },
  },

  watch: { },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },
  },

  async mounted() {
    document.title = `Companies`;
  },

  async created() {
    this.mixinCheckUserAuthentication();
  },
};
</script>