<!-- UnitDetailsHeader.vue -->

<template>

  <div v-if="!isFetching('fetchingUnit')">
    <div class="d-flex mb-3 border-bottom align-items-end">
      <div class="col-4 d-flex align-items-end">
        <div class="p-2 ps-0">
          <div class="fs-3 mb-0 fw-semibold">{{ record.fields['Name'] }}</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UnitDetailsHeader',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
    record: {
      type: Object,
      required: true,
    },
  },

  data() {
    return { };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  watch: { },

  methods: { },

  created() { },
};
</script>