<!-- CompanyDetailsJobsTab.vue -->

<template>
  <div id="jobs" class="tab-pane">
    <div class="p-3 shadow-sm bg-white rounded">
      <div v-if="!isFetching('fetchingCompanyJobs') && isDataFetched">
        <ReusableJobsTable
          :records="records"
          @showAlert="showAlert"
          @showError="showError"
        />
      </div>

      <div v-else class="d-flex justify-content-center p-5">
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReusableJobsTable from "@/components/ReusableJobsTable.vue";

export default {
  name: "CompanyDetailsJobsTab",
  emits: ["showAlert", "showError"],
  props: {
    recordId: {
      type: String,
      required: true,
    },
  },
  components: {
    ReusableJobsTable,
  },

  data() {
    return {
      isDataFetched: false,
      records: [],
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  watch: { },

  methods: {
    showAlert(type, message) {
      this.$emit("showAlert", type, message);
    },

    showError(errorMessage) {
      this.$emit("showError", errorMessage);
    },

    async fetchRelatedRecords() {
      try {
        this.records = await this.$store.dispatch("fetchRelatedRecords", {
          parentTableName: "Companies",
          childTableName: "Jobs",
          recordId: this.recordId,
          relatedFieldName: "Jobs",
          stateVariable: "companyJobs",
          fetchingStateVariable: "fetchingCompanyJobs",
        });

        this.isDataFetched = true;
      } catch (error) {
        this.$emit(
          "showAlert",
          "danger",
          `Error fetching related records: ${error.message}`
        );
        console.error(error);
      }
    },
  },

  async mounted() {
    await this.fetchRelatedRecords();
  },

  created() {},
};
</script>
