<!-- UnitsHeader.vue -->

<template>

<div v-if="!isFetching('fetchingUnits')">
  <div class="d-flex mb-3 border-bottom align-items-end">
    <div class="col-4 d-flex align-items-end">
      <div class="p-2 ps-0">
        <div class="fs-3 mb-0 fw-semibold">Units</div>
      </div>
    </div>

    <div class="col-2 text-end ms-auto">
      <div class="p-2">
        <button type="button" class="btn btn-sm btn-primary" @click="openCreateUnitModal"><i class="bi bi-plus-lg"></i> Add Unit</button>
      </div>
    </div>
  </div>
</div>

  <!-- Create Unit -->
  <CreateUnitModal ref="createUnitModal" @showAlert="showAlert" @showError="showError" />

</template>

<script>
import { mapGetters } from 'vuex';
import CreateUnitModal from '@/components/CreateUnitModal.vue';

export default {
  name: 'UnitsHeader',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
  },
  components: {
    CreateUnitModal,
  },

  data() {
    return { };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),
  },

  watch: { },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    async openCreateUnitModal() {
      this.$refs.createUnitModal.openModal();
    },
  },

  created() { },
};
</script>