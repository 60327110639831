<!-- UpdateJobModal.vue -->

<template>

  <div class="modal" id="updateJobModal" tabindex="-1" aria-labelledby="updateJobModalLabel" aria-hidden="true" ref="updateJobModal">
    <div class="modal-dialog modal-lg">

      <div class="modal-content shadow shadow-lg">
        <div class="modal-header border-bottom align-items-end">
          <div v-if="isDataFetched">
            <h5 class="modal-title" style="line-height: initial;">{{ selectedLanguagePair.replace('>', '›') }}</h5>
          </div>

          <div v-else>
            <h5 class="modal-title"  style="line-height: initial;">Loading...</h5>
          </div>

          <div v-if="!isFetching('fetchingJob') && isDataFetched" class="d-flex">
            <div class="col text-nowrap text-end">
              <div class="p-2 pb-0">
                <p class="mb-1 small text-muted">Revenue</p>
                <h5 class="mb-0">{{ getCurrencySymbol[oppCurrency] }}{{ mixinFormatNumber(calculateTotal) }}</h5>
              </div>
            </div>

            <div v-if="mixinUserInRoles(['Admin', 'PM'])" class="col text-nowrap text-end">
              <div class="p-2 pb-0">
                <p class="mb-1 small text-muted">Vendor Costs</p>
                <h5 class="mb-0">{{ typeof record.fields['Vendor Currency'] === 'object' ? getCurrencySymbol[record.fields['Vendor Currency'].label] : getCurrencySymbol[record.fields['Vendor Currency']] }}{{ mixinFormatNumber(calculateVendorTotal) }}</h5>
              </div>
            </div>

            <div v-if="mixinUserInRoles(['Admin', 'PM'])" class="col text-nowrap text-end">
              <div class="p-2 pb-0">
                <p class="mb-1 small text-muted">Profit in Euro</p>
                <h5 class="mb-0">€{{ mixinFormatNumber(calculateProfit) }}</h5>
              </div>
            </div>

            <div v-if="mixinUserInRoles(['Admin', 'PM'])" class="col text-nowrap text-end">
              <div class="p-2 pb-0">
                <p class="mb-1 small text-muted">Profit Margin</p>
                <h5 class="mb-0" :class="mixinGetPercentageClass(calculateProfitMargin)">{{ mixinFormatMargin(calculateProfitMargin) }}</h5>
              </div>
            </div>

            <button type="button" class="btn-close ms-4" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>

        <div class="modal-body">
          <div v-if="!isFetching('fetchingJob') && isDataFetched">
            <div v-if="calculateProfitMargin < 0.7" class="alert alert-warning p-2 small">
              <i class="bi bi-exclamation-triangle-fill"></i>  <strong>Profit margin for this job is lower than 70%.</strong> Consider negotiating the vendor rate and make sure the vendor works with MateCat Weighted Words, not total words.
            </div>

            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item small" role="presentation">
                <a class="nav-link active" data-bs-toggle="tab" href="#mainInfo" aria-selected="true" role="tab">Main Info</a>
              </li>
              <li class="nav-item small" role="presentation">
                <a class="nav-link" data-bs-toggle="tab" href="#ourRates" aria-selected="false" role="tab">Our Rates</a>
              </li>
              <li class="nav-item small" role="presentation">
                <a class="nav-link" data-bs-toggle="tab" href="#mateCatRates" aria-selected="false" role="tab">MateCat Rates</a>
              </li>
              <li v-if="mixinUserInRoles(['Admin', 'PM'])" class="nav-item small" role="presentation">
                <a class="nav-link" data-bs-toggle="tab" href="#vendorRates" aria-selected="false" role="tab">Vendor Rates</a>
              </li>
              <li v-if="mixinUserInRoles(['Admin', 'PM'])" class="nav-item small" role="presentation">
                <a class="nav-link" data-bs-toggle="tab" href="#invoicing" aria-selected="false" role="tab">Invoicing</a>
              </li>
            </ul>

            <div class="tab-content mt-3">
              <!-- Main Info -->
              <div class="tab-pane show active" id="mainInfo" role="tabpanel" aria-labelledby="main-info-tab">
                <!-- Service -->
                <div class="d-flex align-items-center">
                  <div class="col-4 text-muted small">
                    Service
                  </div>

                  <div class="col-8">
                    <v-select v-if="!isFetching('fetchingServices')" v-model="selectedService" :options="allServicesOptions" placeholder="Select..." :clearable="false">
                      <template v-slot:option="option">
                        <div class="text-wrap">{{ option.label }}</div>
                      </template>

                      <template v-slot:open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                        </span>
                      </template>
                    </v-select>

                    <div v-else class="d-inline-block">
                      <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Language Pair -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Language Pair
                  </div>

                  <div class="col-8">
                    <v-select v-if="!isFetching('fetchingLanguagePairs')" v-model="selectedLanguagePair" :options="allLanguagePairsOptions" placeholder="Select..." :clearable="false">
                      <template v-slot:option="option">
                        <div class="text-wrap">{{ option.label.replace('>', '›') }}</div>
                      </template>

                      <template v-slot:selected-option="option">
                        <div>{{ option.label.replace('>', '›') }}</div>
                      </template>

                      <template v-slot:open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                        </span>
                      </template>
                    </v-select>

                    <div v-else class="d-inline-block">
                      <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Title -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Title
                  </div>

                  <div class="col-8">
                    <input type="text" class="form-control" v-model="record.fields['Title']">
                  </div>
                </div>

                <!-- Notes -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Private Notes
                  </div>

                  <div class="col-8">
                    <textarea class="form-control" rows="3" v-model="record.fields['Notes']"></textarea>
                  </div>
                </div>
              </div>

              <!-- Our Rates -->
              <div class="tab-pane" id="ourRates" role="tabpanel" aria-labelledby="our-rates-tab">
                <!-- Unit -->
                <div class="d-flex align-items-center">
                  <div class="col-4 text-muted small">
                    Unit
                  </div>

                  <div class="col-8">
                    <v-select v-if="!isFetching('fetchingUnits')" v-model="selectedUnit" :options="allUnitsOptions" placeholder="Select..." :clearable="false">
                      <template v-slot:option="option">
                        <div class="text-wrap">{{ option.label }}</div>
                      </template>

                      <template v-slot:open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                        </span>
                      </template>
                    </v-select>

                    <div v-else class="d-inline-block">
                      <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Units -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Units
                  </div>

                  <div class="col-8">
                    <input class="form-control" type="number" v-model="record.fields['Units']">
                  </div>
                </div>

                <!-- Unit Price -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Unit Price
                  </div>

                  <div class="col-8">
                    <div class="input-group">
                      <span class="input-group-text">{{ getCurrencySymbol[oppCurrency] }}</span>
                      <input class="form-control" type="number" v-model="record.fields['Unit Price']">
                    </div>
                  </div>
                </div>

                <!-- Sub Total -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Sub Total
                  </div>

                  <div class="col-8">
                    {{ getCurrencySymbol[oppCurrency] }}{{ mixinFormatNumber(calculateSubTotal) }}
                  </div>
                </div>

                <!-- Discount -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Discount, in %
                  </div>

                  <div class="col-8">
                    <input class="form-control" type="number" v-model="record.fields['Disc %']">
                    <div v-if="record.fields['Disc %']" class="form-text">The value of this discount is {{ getCurrencySymbol[oppCurrency] }}{{ mixinFormatNumber(discountValue) }}</div>
                  </div>
                </div>

                <!-- Total -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Total
                  </div>

                  <div class="col-8">
                    {{ getCurrencySymbol[oppCurrency] }}{{ mixinFormatNumber(calculateTotal) }}
                  </div>
                </div>
              </div>

              <!-- MateCat Rates -->
              <div class="tab-pane" id="mateCatRates" role="tabpanel" aria-labelledby="matecat-rates-tab">
                <p class="small">Add the MateCat word counts and price for this job, if known. <strong>Only used in the quoting stage</strong>. PMs: You don't need to update this value.</p>

                <!-- MateCat Weighted -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    MateCat Weighted Words
                  </div>

                  <div class="col-8">
                    <input class="form-control" type="number" v-model="record.fields['MateCat Weighted']">
                  </div>
                </div>

                <!-- MateCat Price -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    MateCat Price
                  </div>

                  <div class="col-8">
                    <div class="input-group">
                      <span class="input-group-text">€</span>
                      <input class="form-control" type="number" v-model="record.fields['MateCat Price']">
                    </div>
                  </div>
                </div>
              </div>

              <!-- Vendor Rates -->
              <div class="tab-pane" id="vendorRates" role="tabpanel" aria-labelledby="vendor-rates-tab">
                <!-- MateCat Link -->
                <div class="d-flex align-items-center">
                  <div class="col-4 text-muted small">
                    MateCat Link
                  </div>

                  <div class="col-8">
                    <input type="text" class="form-control" v-model="record.fields['MateCat Link']">
                  </div>
                </div>

                <!-- Translated.com Offer -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Translated Offer
                  </div>

                  <div class="col-8">
                    <input type="text" class="form-control" v-model="record.fields['Translated.com Offer']">
                  </div>
                </div>

                <!-- Vendor -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Vendor
                  </div>

                  <div class="col-8">
                    <v-select v-if="!isFetching('fetchingVendors')" v-model="selectedVendor" :options="allVendorsOptions" placeholder="Select..." :clearable="false">
                      <template v-slot:option="option">
                        <div class="text-wrap">{{ option.label }}</div>
                        <div class="small text-muted">Blacklisted? {{ option.blacklisted }}</div>
                      </template>

                      <template v-slot:open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                        </span>
                      </template>
                    </v-select>

                    <div v-else class="d-inline-block">
                      <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>

                    <div v-if="isVendorBlacklisted" class="alert alert-warning mt-2 mb-0 p-2 small">
                      Warning: The selected vendor is blacklisted.
                    </div>
                  </div>
                </div>

                <!-- Vendor Currency -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Vendor Currency
                  </div>

                  <div class="col-8">
                    <v-select v-model="record.fields['Vendor Currency']" :options="allCurrenciesOptions" placeholder="Select..." :clearable="false">
                      <template v-slot:open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>

                <!-- Unit -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Unit
                  </div>

                  <div class="col-8">
                    <v-select v-if="!isFetching('fetchingUnits')" v-model="selectedVendorUnit" :options="allUnitsOptions" placeholder="Select..." :clearable="false">
                      <template v-slot:option="option">
                        <div class="text-wrap">{{ option.label }}</div>
                      </template>

                      <template v-slot:open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                        </span>
                      </template>
                    </v-select>

                    <div v-else class="d-inline-block">
                      <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Units -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Units
                  </div>

                  <div class="col-8">
                    <input class="form-control" type="number" v-model="record.fields['Vendor Units']">
                  </div>
                </div>

                <!-- Unit Price -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Unit Price
                  </div>

                  <div class="col-8">
                    <input class="form-control" type="number" v-model="record.fields['Vendor Unit Price']">
                  </div>
                </div>

                <!-- Sub Total -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Sub Total
                  </div>

                  <div class="col-8">
                    {{ typeof record.fields['Vendor Currency'] === 'object' ? getCurrencySymbol[record.fields['Vendor Currency'].label] : getCurrencySymbol[record.fields['Vendor Currency']] }}{{ mixinFormatNumber(calculateVendorSubTotal) }}
                  </div>
                </div>

                <!-- Discount -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Discount, in %
                  </div>

                  <div class="col-8">
                    <input class="form-control" type="number" v-model="record.fields['2 - Vendor Disc in %']">
                    <div v-if="record.fields['2 - Vendor Disc in %']" class="form-text">The value of this discount is {{ record.fields['Vendor Currency'] ? getCurrencySymbol[record.fields['Vendor Currency'].label] : '' }}{{ mixinFormatNumber(vendorDiscountValue) }}</div>
                  </div>
                </div>

                <!-- Total -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Total
                  </div>

                  <div class="col-8">
                    {{ typeof record.fields['Vendor Currency'] === 'object' ? getCurrencySymbol[record.fields['Vendor Currency'].label] : getCurrencySymbol[record.fields['Vendor Currency']] }}{{ mixinFormatNumber(calculateVendorTotal) }}
                  </div>
                </div>
              </div>

              <!-- Invoicing -->
              <div class="tab-pane" id="invoicing" role="tabpanel" aria-labelledby="vendor-rates-tab">
                <p class="small">This section is used for checking jobs from vendors not enrolled in our self-invoicing system. We compare the values appearing in the invoices they sent, with the values that we have in our app.</p>

                <!-- Invoice Filename -->
                <div class="d-flex align-items-center">
                  <div class="col-4 text-muted small">
                    Invoice Filename
                  </div>

                  <div class="col-8">
                    <input type="text" class="form-control" v-model="record.fields['Invoice']">
                  </div>
                </div>

                <!-- Job Checked? -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Job Checked?
                  </div>

                  <div class="col-8">
                    <v-select v-model="record.fields['Checked?']" :options="['Yes', 'N/A']" placeholder="Select..." :clearable="false">
                      <template v-slot:open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>

                <!-- Invoicing Notes -->
                <div class="d-flex align-items-center pt-3">
                  <div class="col-4 text-muted small">
                    Invoicing Notes
                  </div>

                  <div class="col-8">
                    <textarea class="form-control" rows="3" v-model="record.fields['Invoicing Notes']"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="d-flex justify-content-center p-5">
              <div class="spinner-border text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span v-if="isUpdating('updatingJob')" class="ms-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
          <button type="button" class="btn btn-primary" @click="updateRecord" :disabled="isFetching('fetchingVendors') || isFetching('fetchingLanguagePairs') || isFetching('fetchingUnits') || isFetching('fetchingServices') || isUpdating('updatingJob') || isSubmitting">Save</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';

export default {
  name: 'UpdateJobModal',
  emits: [
    'showAlert',
    'showError',
    'fetchJobs',
    'fetchOpp',
  ],
  props: {
    jobId: {
      type: String,
      required: true,
    },

    opp1UsdToEur: {
      type: Number,
      required: true,
    },

    opp1GbpToEur: {
      type: Number,
      required: true,
    },

    oppCurrency: {
      type: [String, Object],
      required: true,
    },
  },

  data() {
    return {
      isDataFetched: false,
      isSubmitting: false,

      record: { },
      modalInstance: null
    };
  },

  computed: {
    ...mapGetters([
      'allLanguagePairsOptions',
      'allServicesOptions',
      'allUnitsOptions',
      'allUnitsOptions',
      'allVendorsOptions',
      'allCurrenciesOptions',

      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',

      'getCurrencySymbol',
    ]),

    selectedService: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Service ID', optionsArrayName: 'allServicesOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Service ID', value: value, optionsArrayName: 'allServicesOptions' });
      }
    },

    selectedLanguagePair: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Language Pair', optionsArrayName: 'allLanguagePairsOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Language Pair', value: value, optionsArrayName: 'allLanguagePairsOptions' });
      }
    },

    selectedUnit: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Unit ID', optionsArrayName: 'allUnitsOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Unit ID', value: value, optionsArrayName: 'allUnitsOptions' });
      }
    },

    selectedVendorUnit: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Vendor Unit ID', optionsArrayName: 'allUnitsOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Vendor Unit ID', value: value, optionsArrayName: 'allUnitsOptions' });
      }
    },

    selectedVendor: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Vendors', optionsArrayName: 'allVendorsOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Vendors', value: value, optionsArrayName: 'allVendorsOptions' });
      }
    },

    isVendorBlacklisted() {
      const vendor = this.allVendorsOptions.find(vendor => vendor.label === this.selectedVendor);
      return vendor ? vendor.blacklisted === 'Yes' : false;
    },

    calculateSubTotal() {
      let units = this.record.fields['Units'] || 0;
      let unitPrice = this.record.fields['Unit Price'] || 0;
      return units * unitPrice;
    },

    calculateVendorSubTotal() {
      let vendorUnits = this.record.fields['Vendor Units'] || 0;
      let vendorUnitPrice = this.record.fields['Vendor Unit Price'] || 0;
      return vendorUnits * vendorUnitPrice;
    },

    discountValue() {
      let subTotal = this.calculateSubTotal;
      let discountPercent = this.record.fields['Disc %'];
      return subTotal * (discountPercent / 100);
    },

    vendorDiscountValue() {
      let subTotal = this.calculateVendorSubTotal;
      let discountPercent = this.record.fields['2 - Vendor Disc in %'];
      return subTotal * (discountPercent / 100);
    },

    calculateTotal() {
      let subTotal = this.calculateSubTotal;
      let discount = this.record.fields['Disc %'] || 0;
      return subTotal - (subTotal * (discount / 100));
    },

    calculateVendorTotal() {
      let subTotal = this.calculateVendorSubTotal;
      let discount = this.record.fields['2 - Vendor Disc in %'] || 0;
      return subTotal - (subTotal * (discount / 100));
    },

    calculateProfit() {
      let totalRevenueInEuro = 0;
      if (this.calculateTotal) {
        if (this.oppCurrency === 'EUR') {
          totalRevenueInEuro = this.calculateTotal;
        } else if (this.oppCurrency === 'USD') {
          totalRevenueInEuro = this.calculateTotal * this.opp1UsdToEur;
        } else if (this.oppCurrency === 'GBP') {
          totalRevenueInEuro = this.calculateTotal * this.opp1GbpToEur;
        }
      }

      let totalVendorCostInEuro = 0;
      if (this.calculateVendorTotal && this.record.fields['Vendor Currency']) {
        let vendorCurrency = typeof this.record.fields['Vendor Currency'] === 'object' ? this.record.fields['Vendor Currency'].label : this.record.fields['Vendor Currency'];
        if (vendorCurrency === 'EUR') {
          totalVendorCostInEuro = this.calculateVendorTotal;
        } else if (vendorCurrency === 'USD') {
          totalVendorCostInEuro = this.calculateVendorTotal * this.opp1UsdToEur;
        } else if (vendorCurrency === 'GBP') {
          totalVendorCostInEuro = this.calculateVendorTotal * this.opp1GbpToEur;
        }
      }

      let profit = totalRevenueInEuro - totalVendorCostInEuro;
      return profit;
    },

    calculateProfitMargin() {
      let profit = this.calculateProfit;
      let totalRevenueInEuro;

      if (this.oppCurrency === 'EUR') {
        totalRevenueInEuro = this.calculateTotal;
      } else if (this.oppCurrency === 'USD') {
        totalRevenueInEuro = this.calculateTotal * this.opp1UsdToEur;
      } else if (this.oppCurrency === 'GBP') {
        totalRevenueInEuro = this.calculateTotal * this.opp1GbpToEur;
      }

      let profitMargin = profit / totalRevenueInEuro;
      return profitMargin;
    },
  },

  watch: {
    async jobId(newJobId) {
      if (newJobId) {

        this.isDataFetched = false;

        try {
          this.record = await this.$store.dispatch('fetchRecord', {
            tableName: 'Jobs',
            recordId: newJobId,
            stateVariable: 'job',
            fetchingStateVariable: 'fetchingJob',
          });

          // console.log(this.record); // debug
          this.isDataFetched = true;

        } catch (error) {
          this.$emit('showAlert', 'danger', `Error fetching job: ${error.message}`);
          console.error(error);
        }
      }
    },
  },

  methods: {
    openModal() {
      if (!this.modalInstance) {
        const modalElement = this.$refs.updateJobModal;
        this.modalInstance = new bootstrap.Modal(modalElement);
      }
      this.modalInstance.show();
    },

    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },

    createUpdatedFields() {
      return {
        'Service ID': this.record.fields['Service ID'],
        'Language Pair': this.record.fields['Language Pair'],
        'MateCat Link': this.record.fields['MateCat Link'],
        'Translated.com Offer': this.record.fields['Translated.com Offer'],
        'Title': this.record.fields['Title'],
        'Notes': this.record.fields['Notes'],

        'Unit ID': this.record.fields['Unit ID'],
        'Units': this.record.fields['Units'],
        'Unit Price': this.record.fields['Unit Price'],
        'Disc %': this.record.fields['Disc %'] || null,

        // these 2 have a default of 0 from Airtable, to enforce arrays of the same length in the rollups used in Quotes.vue tablerows
        'MateCat Price': this.record.fields['MateCat Price'],
        'MateCat Weighted': this.record.fields['MateCat Weighted'],

        'Vendors': this.record.fields['Vendors'],
        'Vendor Currency': this.record.fields['Vendor Currency'].value,
        'Vendor Unit ID': this.record.fields['Vendor Unit ID'],
        'Vendor Units': this.record.fields['Vendor Units'] || null,
        'Vendor Unit Price': this.record.fields['Vendor Unit Price'] || null,
        '2 - Vendor Disc in %': this.record.fields['2 - Vendor Disc in %'] || null,

        'Invoice': this.record.fields['Invoice'],
        'Checked?': this.record.fields['Checked?'],
        'Invoicing Notes': this.record.fields['Invoicing Notes'],
      };
    },

    validateFields() {
      let errors = [];

      if (this.record.fields['Units'] === null || this.record.fields['Units'] === undefined) {
        errors.push('"Units" is required.');
      }

      // Check if 'Unit Price' is null, undefined or an empty string (when we clear the input it becomes an empty string)
      if (this.record.fields['Unit Price'] === null || this.record.fields['Unit Price'] === undefined || this.record.fields['Unit Price'] === "") {
        errors.push('"Unit Price" is required.');
      }

      return errors;
    },

    async updateRecord() {
      this.isSubmitting = true;

      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        this.isSubmitting = false;
        return;
      }

      try {
        await this.$store.dispatch('updateRecord', {
          tableName: 'Jobs',
          recordId: this.jobId,
          updatedFields: this.createUpdatedFields(),
          updatingStateVariable: 'updatingJob',
        });

        this.$emit('showAlert', 'success', 'Successfully updated record');
        this.closeModal();
        this.$emit('fetchJobs');
        this.$emit('fetchOpp');

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error updating record: ${error.message}`);
        console.error(error);

      } finally {
        this.isSubmitting = false;

      }
    },
  },

  async mounted() { },

  created() { },
};
</script>